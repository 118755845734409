<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
  <div class="main_content_iner overly_inner">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
            <div class="page_title_left d-flex align-items-center">
              <h3 class="mb-0">Simulações</h3>
            </div>
            <div class="page_title_right">
              <ol class="breadcrumb page_bradcam mb-0">
                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                <li class="breadcrumb-item active">Simulações</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="white_card card_height_100 mb_30">
            <div class="white_card_header">
              <div class="box_header m-0">
                <form>
                  <div class="row mb-3">
                    <div class="col-md-8">
                      <label class="form-label" for="cliente">Cliente</label>
                      <select id="cliente" class="form-select" (change)="onClienteChange($event)" [(ngModel)]="cliente.id" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let cliente of clientes" [value]="cliente.id">{{ cliente.nome }} - {{getTipoDisplay(cliente.tipo)}}</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label" for="moeda">Moeda</label>
                      <select id="moeda" class="form-select" [(ngModel)]="cliente.moeda" [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let moeda of moedasClienteSelecionado" [value]="moeda.nome">{{ moeda.nome }}</option>
                      </select>
                    </div>
                    <div class="col-md-1" style="align-content: end; white-space: nowrap;">
                      <button type="button" (click)="onSubmit()" class="btn btn-primary mb-2 btnEnviar" style="margin-bottom: 0 !important;">Nova Simulação</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="white_card_body">
              <div class="QA_section">
                <div class="QA_table mb_30">
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Data</th>
                          <th scope="col">Moeda</th>
                          <th scope="col">Cotacao</th>
                          <th scope="col">Dolar</th>
                          <th scope="col">Spread</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let cotacao of cotacoes">
                          <td>{{cotacao.data_registro?global.formateDateAndTime(cotacao.data_registro):'-'}}</td>
                          <td>{{ cotacao.moeda }}</td>
                          <td>{{formaterCurrency(cotacao.preco | currency:'BRL':true:'1.2-4')}}</td>
                          <td>{{formaterCurrency(cotacao.cotacao_dolar | currency:'BRL':true:'1.2-4')}}</td>
                          <td>{{formaterSpread(((cotacao.spread - 1)*100), 2)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="card">
                <div class="card-body pag-custom">
                  <div class="col" *ngIf="total_pages.length > 0">
                    <div class="navigations-rows-shows">
                      <h2>Mostrando {{mostrando}} até {{mostrando2}} de {{paginas}} registros </h2>
                    </div>
                  </div>
                  <div class="col">
                    <nav aria-label="Page navigation">
                      <ul class="pagination" id="pagination_"></ul>
                    </nav>
                  </div>
                  <div class="col"> </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>