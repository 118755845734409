import { Component, OnInit } from '@angular/core';
declare var $: any;
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatDialog } from '@angular/material';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { Global } from 'src/app/global';

@Component({
  selector: 'app-config-manager',
  templateUrl: './config-manager.component.html',
  styleUrls: ['./config-manager.component.css']
})
export class ConfigManagerComponent implements OnInit {
  _global: Global;

  // Para paginação
  total_pages: number[] = [];
  paginas: number = 0;
  mostrando: number = 0;
  mostrando2: number = 0;
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;

  // Para usar na tela
  listItem: any = [];
  loading: boolean = false;
  nomeChave: string = "";
  formSubmitted = false;
  ultimosParametros: any = {};
  modalChave: any = {};
  atualizandoChave: boolean = false;
  chaveOriginal: string = '';

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,
  ) { 
    this._global = global;
  }

  ngOnInit(): void {
    this.getChaves();
  }

  getChaves(page: number = 1, limit: number = 10) {
    this.loading = true;
    let url = `storage?page=${page}&limit=${limit}`;
    if (this.nomeChave) {
      url += `&chave=${this.nomeChave}`
    }

    this.apiService.Get(url).then((res: any) => {
      this.listItem = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit, this.ultimaTotalPages, this.ultimaPagina, this.getChaves.bind(this));
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  postChave(obj: any) {
    this.loading = true;
    this.apiService.Post(`storage`, obj).then((res: any) => {
      this.fecharModalAddChave();
      this.getChaves();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  putChave(chaveOriginal: string, dadosAtualizados: any) {
    this.loading = true;
    this.apiService.Put(`storage/${chaveOriginal}`, dadosAtualizados).then((res: any) => {
      this.fecharModalAddChave();
      this.getChaves();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  pesquisar(page: number = 1) {
      this.resetPagination;
      this.getChaves(page)
  }

  initPagination(totalPages: number, page: number, limit: number, lastTotalPage: number, lastPage: number, fetchDataFunction: (id?: any, page?: number) => void, id?: any) {
    if (lastTotalPage !== totalPages) {
      lastTotalPage = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((lastPage !== page)) {
              lastPage = page;
              fetchDataFunction(page, limit);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  modalAddChave = (dados: any = {}) => {
    this.loading = false;
    this.modalChave = {...dados}; // cópia dos dados
    this.chaveOriginal = dados.chave; // guarda chave original
    this.atualizandoChave = !!dados.chave; 

    $("#modalAddChave").toggleClass("show");
    $("#modalAddChave").css("display", "block");
  }

  fecharModalAddChave = () => {
    $("#modalAddChave").toggleClass("show");
    $("#modalAddChave").css("display", "none");
  }

  salvarChave = () => {
    if (!this.modalChave.chave || !this.modalChave.valor || !this.modalChave.tipo) {
      var objAlert = {
        title: `Erro`,
        msg: `Campos chave, valor e tipo são obrigatórios!`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    } 

    if (this.atualizandoChave) {
      this.putChave(this.chaveOriginal, this.modalChave);
    } else {
      this.postChave(this.modalChave);
    }
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }
}

