<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">

    <div class="main_content_iner overly_inner ">
        <div class="container-fluid p-0 ">
            <div class="row">
                <div class="col-12">
                    <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                        <div class="page_title_left d-flex align-items-center">
                            <h3 class="mb-0">Ordens Noturnas</h3>
                        </div>
                        <div class="page_title_right">
                            <ol class="breadcrumb page_bradcam mb-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                                <li class="breadcrumb-item active">Ordens Noturnas</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="white_card_body">
                        <div class="white_card mb_30 card_height_100">
                            <div class="white_card_header ">
                                <div class="box_header m-0">
                                    <div class="main-title">
                                        <h3 class="m-0">Ordens Noturnas</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="white_card_body pt-0">
                                <div class="QA_section">
                                    <div class="row form-pesquisar">
                                        <div class="col-2 ">
                                            <label class="form-label" for="initialDate" style="white-space: nowrap;">Data inicial</label>
                                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-2">
                                            <label class="form-label" for="finalDate" style="white-space: nowrap;">Data final</label>
                                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-2 space-480p">
                                            <label class="form-label" for="inputState">Status</label>
                                            <select id="inputState" class="form-select" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                                <option selected="" value="0">Todos</option>
                                                <option value="pendente">Pendente</option>
                                                <option value="aprovado">Aprovado</option>
                                                <option value="rejeitado">Rejeitado</option>
                                                <option value="saldo_insuficiente">Saldo insuficiente</option>
                                                <option value="erro">Erro</option>
                                            </select>
                                        </div>
                                        <div class="col-2 space space-480p">
                                            <label class="form-label" for="inputNomeMoeda">Moedas</label>
                                            <select id="inputNomeMoeda" class="form-select" [(ngModel)]="nomeMoeda" [ngModelOptions]="{standalone: true}">
                                                <option selected="" value="0">Todas</option>
                                                <option *ngFor="let moeda of listItem.moedas_menu" [value]="moeda">{{moeda}}</option>
                                            </select>
                                        </div>
                                        <div *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" class="col-2 space space-480p">
                                            <label class="form-label" for="nomeCliente" style="white-space: nowrap;">Nome do cliente</label>
                                            <input class="form-control" id="nomeCliente" type="text" placeholder="Nome do cliente" [(ngModel)]="nomeCliente">
                                        </div>
                                        <div class="col-2 space space-480p">
                                            <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary ">Buscar</button>
                                        </div>
                                    </div>
                                    <div class="QA_table mb_30 transaction-table order-history-table">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Data</th>
                                                        <th scope="col" class="align-to-r">Val</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Moeda</th>
                                                        <th scope="col" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">PreCoin</th>
                                                        <th scope="col">Cliente</th>
                                                        <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Venda (R$)</th>
                                                        <th scope="col">Quantidade</th>
                                                        <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Receber (R$)</th>
                                                        <th scope="col">Hash</th>
                                                        <th scope="col" style="text-align: right">Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody class="overflow-hidden" *ngIf="!loading">
                                                    <tr *ngFor="let item of listItem.rows">
                                                        <td>{{item.data_registro?global_.formateDateAndTime(item.data_registro):'-'}}</td>
                                                        <td class="align-to-r">{{item.validade}}</td>
                                                        <td><span class="badge" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='aprovado','bg-warning': item.status=='pendente', 'bg-info': item.status=='saldo_insuficiente', 'bg-rose': item.status=='rejeitado'}">{{item.status}}</span></td>
                                                        <td>{{item.cotacao? item.cotacao.moeda : ''}}</td>
                                                        <td *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">{{global_.formaterCurrency(item.cotacao.cotacao | currency:'BRL':true:'1.4-4')}}</td>
                                                        <td>{{item.cotacao? item.cotacao.cliente_nome : ''}}</td>
                                                        <td *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="item.cotacao.cotacao_venda !== 0; else zeroValue"> {{global_.formaterCurrency(item.cotacao.cotacao_venda | currency:'BRL':true:'1.2-4') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                        <td>{{formatarNumeroQtd(item.cotacao.quantidade)}}</td>
                                                        <td *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="item.cotacao.receber !== 0; else zeroValue"> {{ global_.formaterCurrency(item.cotacao.receber | currency:'BRL':true:'1.2-2') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                        <!-- <td class=""><i class="fas fa-pencil-alt"></i></td> -->
                                                        <td class="overflow-hidden">{{item.hash}}</td>
                                                        <td *ngIf="!item.hash" style="text-align: right; align-items: center; "><button type="button" title="Editar" class="btn btn-primary" (click)="detalharOrdem(item)"><i class="ti-pencil-alt2"></i></button></td>
                                                        <td *ngIf="item.hash" style="text-align: right; align-items: center; "><button type="button" title="Editar" class="btn btn-primary" (click)="detalharOrdem(item)"><i class="ti-pencil-alt2"></i></button>&nbsp;<button type="button" title="Link" class="btn btn-primary" (click)="openLink(item.hash)"><i class="ti-link"></i></button></td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="loading">
                                                    <div class="loading-table">
                                                        <img
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    </div>
                                                </tbody>
                                                <tfoot *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin') && listItem.totais.total_qtd !== 0 && !loading">
                                                    <th scope="row">Totais</th>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{global_.formaterCurrency(listItem.totais.valor_medio_venda | currency:'BRL':true:'1.2-4')}}</td>
                                                    <td>{{ formatarNumeroQtd(listItem.totais.total_qtd) }}</td>
                                                    <td>{{global_.formaterCurrency(listItem.totais.total_receber | currency:'BRL':true:'1.2-2')}}</td>
                                                </tfoot>
                                                <tfoot *ngIf="currentUser && (currentUser.tipo === 'clienteGestor' || (currentUser.tipo === 'cliente'))&& listItem.totais.total_qtd !== 0 && !loading">
                                                    <th scope="row">Totais</th>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{global_.formaterCurrency(listItem.totais.valor_medio_venda | currency:'BRL':true:'1.2-4')}}</td>
                                                    <td>{{ formatarNumeroQtd(listItem.totais.total_qtd) }}</td>
                                                    <td>{{global_.formaterCurrency(listItem.totais.total_receber | currency:'BRL':true:'1.2-2')}}</td>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="card">
                                            <div class="card-body pag-custom">
                                                <div class="col" *ngIf="total_pages.length > 0">
                                                    <div class="navigations-rows-shows">
                                                        <h2>Mostrando {{mostrando}} até {{mostrando2}} de {{paginas}} registros </h2>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <nav aria-label="Page navigation">
                                                        <ul class="pagination" id="pagination_"></ul>
                                                    </nav>
                                                </div>
                                                <div class="col">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal centerModal fade" id="modalEditarOrdem" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-sm">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Editar Ordem</h5> <button type="button" class="btn-close" (click)="fecharModalEditarOrdem()"> <span aria-hidden="true"></span> </button>
                </div>
                <div class="modal-body">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_body">
                            <div class="QA_section">
                                <div class="QA_table mb-0">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Moeda</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col">PreCoin</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Venda (R$)</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" scope="col">Receber (R$)</th>
                                                    <!-- <th scope="col">Hash</th> -->
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!loading">
                                                <tr>
                                                    <td>{{ordemInfo.cotacao? ordemInfo.cotacao.moeda : ''}}</td>
                                                    <td *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">{{global_.formaterCurrency(ordemInfo.cotacao.cotacao | currency:'BRL':true:'1.4-4')}}</td>
                                                    <td>{{ordemInfo.cotacao? ordemInfo.cotacao.cliente_nome : ''}}</td>
                                                    <td *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="ordemInfo.cotacao.cotacao_venda !== 0; else zeroValue"> {{global_.formaterCurrency(ordemInfo.cotacao.cotacao_venda | currency:'BRL':true:'1.2-4') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                    <td>{{formatarNumeroQtd(ordemInfo.cotacao.quantidade)}}</td>
                                                    <td *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="ordemInfo.cotacao.receber !== 0; else zeroValue"> {{ global_.formaterCurrency(ordemInfo.cotacao.receber | currency:'BRL':true:'1.2-2') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                    <!-- <td><input type="text" class="form-control" id="ordemHashInput" placeholder="Insira o hash" [(ngModel)]="ordemInfo.hash" [ngModelOptions]="{standalone: true}"></td> -->
                                                </tr>
                                                <!-- <tr><td><input type="text" class="form-control" id="ordemHashInput" placeholder="Insira o hash" [(ngModel)]="ordemInfo.hash" [ngModelOptions]="{standalone: true}"></td></tr> -->
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div><input type="text" class="form-control" id="ordemHashInput" placeholder="Insira o hash aqui" [(ngModel)]="ordemInfo.hash" [ngModelOptions]="{standalone: true}"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="fecharModalEditarOrdem()">Fechar</button>
                    <span>
                        <img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="putModalEditarOrdem()">Editar</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>