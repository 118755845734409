import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/login.component';
import { HomeComponent } from './screens/home/home.component';
import { AppConfig } from './service/app.config';
import { AuthGuard } from './_guards/auth.guard';
import { Global } from './global';
import { ApiService } from './service/apiServices';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { appRoutes } from './routes';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { AutofocusDirective } from './directives/autofocus.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';

//telas
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
import { ClientsComponent } from './screens/clients/clients.component';
import { ProfileComponent } from './screens/profile/profile.component';
import { OrdersComponent } from './screens/orders/orders.component';
import { OrderHistoryComponent } from './screens/order-history/order-history.component';
import { UsersComponent } from './screens/users/users.component';
import { ContractsComponent } from './screens/contracts/contracts.component';
import { DataBloqueadaComponent } from './screens/data-bloqueada/data-bloqueada.component';
import { ClientsDetailsComponent } from './screens/client-details/client-details.component';
import { BanksComponent } from './screens/banks/banks.component';
import { ConfigManagerComponent } from './screens/config-manager/config-manager.component';
import { GroupComponent } from './screens/group/group.component';
import { NightModeComponent } from './screens/night-mode/night-mode.component';
import { NightOrdersComponent } from './screens/night-orders/night-orders.component';
import { OperationDayComponent } from './screens/operation-day/operation-day.component';
import { GlobalManagerComponent } from './screens/global-manager/global-manager.component';
import { SimulationsComponent } from './screens/simulations/simulations.component';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}
registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    NavBarComponent,
    AutofocusDirective,
    ModalAlertComponent,
    ClientsComponent,
    ProfileComponent,
    OrdersComponent,
    OrderHistoryComponent,
    UsersComponent,
    ContractsComponent,
    DataBloqueadaComponent,
    ClientsDetailsComponent,
    BanksComponent,
    ConfigManagerComponent,
    GroupComponent,
    NightModeComponent,
    NightOrdersComponent,
    OperationDayComponent,
    GlobalManagerComponent,
    SimulationsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    NoopAnimationsModule,
    MatDialogModule,
    CurrencyMaskModule,
    MatExpansionModule,
    RouterModule.forRoot(appRoutes, { useHash: true })
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },
    AuthGuard,
    Global,
    ApiService,
  ],
  entryComponents: [
    ModalAlertComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
