<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
    <div class="main_content_iner overly_inner ">
        <div class="container-fluid p-0 ">
            <div class="row">
                <div class="col-12">
                    <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                        <div class="page_title_left d-flex align-items-center">
                            <h3 class="mb-0">Usuários</h3>
                        </div>
                        <div class="page_title_right">
                            <ol class="breadcrumb page_bradcam mb-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                                <li class="breadcrumb-item active">Usuários</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_body">
                            <div class="QA_section">
                                <div class="inline-flex-itens">
                                    <div class="row align-items-center inline-inputs-center ">
                                        <div class="col-auto">
                                            <div class="input-group mb-2">
                                                <input class="form-control digits" id="initialDate" type="text" name="pesquisa" placeholder="Nome ou email do usuário" [(ngModel)]="identificador" [ngModelOptions]="{standalone: true}">
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" (click)="pesquisar()" class="btn btn-primary mb-2">Buscar</button>
                                        </div>
                                    </div>
                                    <div class="desktop-view">
                                        <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAdicionarUsuario()" title="Adicionar usuário">
                                            Adicionar usuário
                                        </button>
                                    </div>
                                </div>
                                <div class="mobile-view">
                                    <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAdicionarUsuario()" title="Adicionar usuário">
                                        <i class="ti-plus"></i>
                                    </button>
                                </div>
                                <div class="QA_table mb_30">
                                    <table class="table lms_table_active3 ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" style="text-align: right;">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="!loading">
                                            <tr *ngFor="let item of usersList.rows">
                                                <td>{{item.nome}}</td>
                                                <td>{{item.email}}</td>
                                                <td>
                                                    <span class="badge" [ngClass]="{'bg-danger': item.usuario_ativo==1, 'bg-success': item.usuario_ativo==2 }">{{item.usuario_ativo
                                                        == 1? 'Inativo' : 'Ativo'}}</span>
                                                </td>
                                                <td style="text-align: right;">
                                                    <button type="button" class="btn btn-primary" (click)="verDetalhesuser(item)" title="Visualizar Usuário">
                                                        <i class="ti-pencil-alt2"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button *ngIf="client.id != item.id" type="button" class="btn btn-danger" (click)="deletarDado(item)" title="Deletar Usuário">
                                                        <i class="ti-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="loading">
                                            <div class="loading-table">
                                                <img
                                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                        </tbody>

                                    </table>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-body pag-custom">
                                            <div class="col" *ngIf="total_pages.length > 0">
                                                <div class="navigations-rows-shows">
                                                    <h2>Mostrando {{mostrando}} até
                                                        {{mostrando2}}
                                                        de {{paginas}} registros
                                                    </h2>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <nav aria-label="Page navigation">
                                                    <ul class="pagination" id="pagination_"></ul>
                                                </nav>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal centerModal fade" id="cadastrousere" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm ">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{user.id? 'Editar usuário': 'Adicionar usuário'}}
                </h5>
                <button type="button" class="btn-close" (click)="fecharmodalAdicionarUsuario()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_body">
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon1"><i class="ti-user"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Nome" aria-label="Nome" [(ngModel)]="user.nome" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon3">
                                    <i class="ti-email"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" [disabled]="user.id" placeholder="email" aria-label="chave" [(ngModel)]="user.email" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon3">

                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon2">
                                    <i class="ti-unlock"></i>
                                </span>
                            </div>
                            <input [type]="seepassword? 'text' : 'password'" class="form-control" placeholder="Senha" aria-label="Senha" [(ngModel)]="user.senha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                            <div class="input-group-text btn-eye" (click)="seepassword = !seepassword">
                                <span class="" id="basic-addon2">
                                    <i class="ti-eye"></i>
                                </span>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon2">
                                    <i class="ti-unlock"></i>
                                </span>
                            </div>
                            <input [type]="seepasswordCnf? 'text' : 'password'" class="form-control" placeholder="Confirmar Senha" aria-label="Senha" [(ngModel)]="confirmarSenha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                            <div class="input-group-text btn-eye" (click)="seepasswordCnf = !seepasswordCnf">
                                <span class="" id="basic-addon2">
                                    <i class="ti-eye"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="fecharmodalAdicionarUsuario()">Fechar</button>
                <span>
                    <img *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="saveUsuario()">{{user.id?
                        'Atualizar usuário': 'Salvar usuário'}}</button>
                </span>
            </div>
        </div>
    </div>
</div>