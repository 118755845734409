<div class="main_content_iner overly_inner">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Configurações</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Configurações</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_header ">
                        <div class="box_header m-0">
                            <div class="row align-items-center inline-inputs-center">
                                <div class="col-auto">
                                    <label class="form-label" for="nomeChave">Nome da chave</label>
                                    <input class="form-control" id="nomeChave" type="text" placeholder="Nome da chave" [(ngModel)]="nomeChave">
                                </div>
                                <div class="col-auto">
                                    <button type="button" (click)="pesquisar()" class="btn btn-primary mb-2 botaoBuscar">Buscar</button>
                                </div>
                            </div>
                            <div>
                                <!-- <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAddChave()" title="Adicionar">
                                    Adicionar
                                </button> -->
                            </div>
                        </div>
                    </div>
                    <div class="white_card_body">
                        <div class="QA_section">
                            <div class="QA_table mb_30">
                                <div class="table-responsive">
                                    <table class="table tabela-gerenciar-configuracoes">
                                        <thead>
                                            <tr>
                                                <th scope="col">Última atualização</th>
                                                <!-- <th scope="col">Chave</th> -->
                                                <th scope="col">Valor</th>
                                                <!-- <th scope="col">Tipo</th> -->
                                                <th scope="col">Descrição</th>
                                                <th scope="col" style="text-align: right;">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="!loading">
                                            <tr *ngFor="let item of listItem.rows">
                                                <td>{{item.data_atualizacao? _global.formateDateAndTime(item.data_atualizacao) : '-'}}</td>
                                                <!-- <td>{{item.chave}}</td> -->
                                                <td>{{item.valor}}</td>
                                                <!-- <td>{{item.tipo}}</td> -->
                                                <td>{{item.descricao}}</td>
                                                <td style="text-align: right;">
                                                    <button type="button" title="Editar moeda" (click)="modalAddChave(item)" class="btn btn-primary">
                                                        <i class="ti-pencil-alt2"></i>
                                                    </button>
                                                    <!-- <button type="button" title="Deletar moeda" (click)="modalAddChave(item)" class="btn btn-primary">
                                                        <i class="ti-trash"></i>
                                                    </button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="loading">
                                            <div class="loading-table">
                                                <img
                                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>
                            </div>
                            <div id="divMenuPaginacao" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="card">
                                    <div class="card-body pag-custom">
                                        <div class="col" *ngIf="total_pages.length > 0">
                                            <div class="navigations-rows-shows">
                                                <h2>Mostrando {{mostrando}} até
                                                    {{mostrando2}}
                                                    de {{paginas}} registros
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <nav aria-label="Page navigation">
                                                <ul class="pagination" id="pagination_"></ul>
                                            </nav>
                                        </div>
                                        <div class="col">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal centerModal fade" id="modalAddChave" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{modalChave.chave? 'Editar Chave': 'Adicionar Chave'}}
                </h5>
                <button type="button" class="btn-close" (click)="fecharModalAddChave()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100">
                    <div class="white_card_body">
                        <div>
                            <div class="col-auto">
                                <label class="form-label" for="valorChave">{{modalChave.descricao}}</label>
                                <input class="form-control" id="valorChave" type="text" placeholder="Valor" [(ngModel)]="modalChave.valor">
                            </div>
                            <!-- <div class="mb-3"> <input type="text" class="form-control" placeholder="Chave" [(ngModel)]="modalChave.chave" [ngModelOptions]="{standalone: true}"> </div> -->
                            <!-- <div class="mb-3"> <input type="text" class="form-control" placeholder="Valor" [(ngModel)]="modalChave.valor" [ngModelOptions]="{standalone: true}"> </div> -->
                            <!-- <div class="mb-3"> <input type="text" class="form-control" placeholder="Tipo" [(ngModel)]="modalChave.tipo" [ngModelOptions]="{standalone: true}"> </div> -->
                            <!-- <div class="mb-3"> <input type="text" class="form-control" placeholder="Descrição" [(ngModel)]="modalChave.descricao" [ngModelOptions]="{standalone: true}" readonly> </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="fecharModalAddChave()">Fechar</button>
                <span>
                    <img *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button type="button" class="btn btn-primary" (click)="salvarChave()">
                        {{ atualizandoChave ? 'Atualizar' : 'Adicionar' }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>