<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Bancos</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Bancos</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card card_height_100 mb_30">

                    <div class="white_card_body">
                        <div class="QA_section">
                            <div class="QA_table mb_30">
                                <div class="inline-flex-itens">
                                    <div class="row align-items-center">
                                    </div>
                                    <div>
                                        <button type="button" class="btn btn-primary mb-3 btn-add hide-mobile"
                                            (click)="modalAdicionarBanco()" title="Adicionar banco">
                                            Adicionar banco
                                        </button>
                                    </div>
                                    <div class="btn-add-m">
                                        <button type="button" class="btn btn-primary mb-3 btn-add"
                                            (click)="modalAdicionarBanco()" title="Adicionar banco">
                                            <i class="ti-plus"></i>
                                        </button>
                                    </div>
                                </div>
                               
                                <div style="overflow: auto;">
                                    <table class="table lms_table_active3 ">
                                        <thead>
                                            <tr>
                                                <th scope="col">Apelido</th>
                                                <th scope="col">Cód. do País</th>
                                                <th scope="col">Cód. natureza da transação</th>
                                                <th scope="col">Endereço externo</th>
                                                <th scope="col">Nº IBAN</th>
                                                <th scope="col">Nome da entidade externa</th>
                                                <th scope="col">Nº ABA intermediário</th>
                                                <th scope="col">Cidade intermediário</th>
                                                <th scope="col">Nome intermediário</th>
                                                <th scope="col">Código SWIFT intermediário</th>
                                                <th scope="col">Nº ABA receptor</th>
                                                <th scope="col">Cidade receptor</th>
                                                <th scope="col">Nome receptor</th>
                                                <th scope="col">Código SWIFT receptor</th>
                                                <th scope="col" style="text-align: right;">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="!loading">
                                            <tr *ngFor="let item of banksList.rows">
                                                <td>{{item.nickname}}</td>
                                                <td>{{item.country}}</td>
                                                <td>{{item.nature}}</td>
                                                <td>{{item.external_address}}</td>
                                                <td>{{item.external_iban}}</td>
                                                <td>{{item.external_name}}</td>
                                                <td>{{item.interm_bank_aba}}</td>
                                                <td>{{item.interm_bank_city}}</td>
                                                <td>{{item.interm_bank_name}}</td>
                                                <td>{{item.interm_bank_swift}}</td>
                                                <td>{{item.receiver_bank_aba}}</td>
                                                <td>{{item.receiver_bank_city}}</td>
                                                <td>{{item.receiver_bank_name}}</td>
                                                <td>{{item.receiver_bank_swift}}</td>
                                                <td style="
                                                text-align: right;
                                                display: inline-flex;
                                                align-items: center;
                                                ">
                                                    <img *ngIf="loadingBanco"
                                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    <button *ngIf="!loadingBanco" type="button" title="Editar Banco"
                                                        class="btn btn-primary " (click)="verDetalhesBanco(item)">
                                                        <i class="ti-pencil-alt2"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button type="button" class="btn btn-danger "
                                                        (click)="deletarDado(item)" title="Deletar contrato">
                                                        <i class="ti-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="loading">
                                            <div class="loading-table">
                                                <img
                                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            </div>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="card">
                                    <div class="card-body pag-custom">
                                        <div class="col" *ngIf="total_pages.length > 0">
                                            <div class="navigations-rows-shows">
                                                <h2>Mostrando {{mostrando}} até
                                                    {{mostrando2}}
                                                    de {{paginas}} registros
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <nav aria-label="Page navigation">
                                                <ul class="pagination" id="pagination_"></ul>
                                            </nav>
                                        </div>
                                        <div class="col">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal centerModal fade" id="cadastroBanco" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{bank.id? 'Editar Banco': 'Adicionar Banco'}}
                </h5>
                <button type="button" class="btn-close" (click)="fecharModalAdicionarBanco()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100">
                    <div class="white_card_body">
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Apelido" aria-label="nickname"
                                [(ngModel)]="bank.nickname" [ngModelOptions]="{standalone: true}"
                                aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Código do País" aria-label="Nome"
                                [(ngModel)]="bank.country" [ngModelOptions]="{standalone: true}" mask="0000000000"
                                aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Código natureza da transação"
                                mask="0000000000000000" aria-label="Nome" [(ngModel)]="bank.nature"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <hr />
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Endereço externo" aria-label="Nome"
                                [(ngModel)]="bank.external_address" [ngModelOptions]="{standalone: true}"
                                aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nº IBAN" aria-label="Nome"
                                [(ngModel)]="bank.external_iban" [ngModelOptions]="{standalone: true}"
                                aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nome da entidade externa"
                                aria-label="Nome" [(ngModel)]="bank.external_name" [ngModelOptions]="{standalone: true}"
                                aria-describedby="basic-addon1">
                        </div>
                        <hr />
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nº ABA do banco intermediário"
                                aria-label="Nome" [(ngModel)]="bank.interm_bank_aba"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Cidade do banco intermediário"
                                aria-label="Nome" [(ngModel)]="bank.interm_bank_city"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nome do banco intermediário"
                                aria-label="Nome" [(ngModel)]="bank.interm_bank_name"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Código SWIFT do banco intermediário"
                                aria-label="Nome" [(ngModel)]="bank.interm_bank_swift"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <hr />

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nº ABA do banco receptor"
                                aria-label="Nome" [(ngModel)]="bank.receiver_bank_aba"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Cidade do banco receptor"
                                aria-label="Nome" [(ngModel)]="bank.receiver_bank_city"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Nome do banco receptor"
                                aria-label="Nome" [(ngModel)]="bank.receiver_bank_name"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Código SWIFT do banco receptor"
                                aria-label="Nome" [(ngModel)]="bank.receiver_bank_swift"
                                [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loading" class="btn btn-secondary"
                    (click)="fecharModalAdicionarBanco()">Fechar</button>
                <span>
                    <img *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="saveBanco()">{{bank.id?
                        'Atualizar Banco': 'Salvar Banco'}}</button>
                </span>
            </div>
        </div>
    </div>
</div>