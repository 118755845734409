import { Routes } from "@angular/router";
import { ClientsComponent } from "./screens/clients/clients.component";
import { HomeComponent } from "./screens/home/home.component";
import { LoginComponent } from "./screens/login/login.component";
import { AuthGuard } from "./_guards/auth.guard";
import { OrdersComponent } from "./screens/orders/orders.component";
import { OrderHistoryComponent } from "./screens/order-history/order-history.component";
import { UsersComponent } from "./screens/users/users.component";
import { ContractsComponent } from "./screens/contracts/contracts.component";
import { DataBloqueadaComponent } from "./screens/data-bloqueada/data-bloqueada.component";
import { ClientsDetailsComponent } from "./screens/client-details/client-details.component";
import { SimulationsComponent } from "./screens/simulations/simulations.component";
import { BanksComponent } from "./screens/banks/banks.component";
import { GlobalManagerComponent } from "./screens/global-manager/global-manager.component";
import { OperationDayComponent } from "./screens/operation-day/operation-day.component";
import { GroupComponent } from "./screens/group/group.component";
import { ProfileComponent } from "./screens/profile/profile.component";
import { NightModeComponent } from "./screens/night-mode/night-mode.component";
import { NightOrdersComponent } from "./screens/night-orders/night-orders.component";

export const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'groups', component: GroupComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'client/:uuid', component: ClientsDetailsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'simulations', component: SimulationsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'orders', component: OrdersComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'order-history', component: OrderHistoryComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'contracts', component: ContractsComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'data', component: DataBloqueadaComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'banks', component: BanksComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'global-manager', component: GlobalManagerComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'operation-day', component: OperationDayComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'night-mode', component: NightModeComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'night-orders', component: NightOrdersComponent, canActivate: [AuthGuard], pathMatch: 'full' },
    { path: 'login', component: LoginComponent, pathMatch: 'full' },
    { path: "**", redirectTo: "home" },
];