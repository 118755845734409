import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
declare var $: any;
import * as moment from "moment";
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  currentUser: any = {};
  loading: any = false;
  loadingSave: any = false;
  total_pages: any = [];
  total_pages_aux: any = [];

  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');


  listItem: any = [];
  finalDate: any = moment().format('YYYY-MM-DD');
  initialDate: any = moment().format('YYYY-MM-DD');

  finalDate_: any = moment().format('DD/MM/YYYY');
  initialDate_: any = moment().format('DD/MM/YYYY');

  contrato: any = {};
  ordem_ids: any = [];
  status: any = "aprovado";
  nomeMoeda: any = "0";
  global_: any;
  ordens: any = [];
  allSelected: any = false;
  somaTotal: any = 0;
  somaIof: any = 0;
  somaPagar: any = 0;
  somaQuantidade: any = 0;

  nomeCliente: string = "";

  constructor(private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,) {
    this.global_ = global;
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    var that = this;
    setTimeout(() => {
      $('#initialDate').datepicker({ language: 'pt' });
      $('#finalDate').datepicker({ language: 'pt' });
    }, 1000);
    this.getOrders();
  }

  pesquisar() {
    if ($("#initialDate").val() && $("#finalDate").val()) {
      var dTi = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
      var dTF = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
      this.initialDate = dTi;
      this.finalDate = dTF;
      this.ordem_ids = [];
      this.getOrders(1);
      this.ordem_ids = [];
      this.ordens = [];
      this.allSelected = false;
    }
  }

  getOrders(page = 1) {
    this.loading = true;
    var url = `ordem?page=${page}&limit=1000&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&vinculado_contrato=false`;

    // this.initialDate = "2023-12-25";
    if (this.nomeCliente) {
      url += `&nome_cliente=${this.nomeCliente}`
    }

    if (this.status != "0") {
      url += `&status=${this.status}`;
    }

    if (this.nomeMoeda != "0") {
      url += `&nome_moeda=${this.nomeMoeda}`;
    }

    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.listItem = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }

      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = 1;
        this.mostrando2 = res.rows.length;

      }
      var primeiraChamada = true;
      try {
        $('#pagination_').twbsPagination('destroy');
        let that = this;
        $('#pagination_').twbsPagination({
          totalPages: res.total_pages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: function (event = null, page = 0) {
            if (!primeiraChamada) {
              that.getOrdersPage(page);
            } else {
              primeiraChamada = false;
            }
          }
        });
      } catch (error) {

      }
      this.loading = false;
      setTimeout(() => {
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })

        $(function () {
          $('[data-toggle="popover"]').popover()
        })
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getOrdersPage(page = 1) {
    this.loading = true;
    // this.initialDate = "2023-12-25";
    var url = `ordem?page=${page}&limit=25&data_inicio=${this.initialDate}&data_fim=${this.finalDate}`;
    if (this.status != "0") {
      url += `&status=${this.status}`;
    }
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.listItem = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }
      if (res.rows.length > 0) {
        if (page == 1) {
          this.paginas = res.total_rows;
          this.mostrando = 1;
          this.mostrando2 = res.rows.length;
        } else
          if (page > 1) {
            if (this.total_pages.length == page) {
              this.paginas = res.total_rows;
              this.mostrando = res.total_rows - res.rows.length + 1
              this.mostrando2 = res.total_rows;
            } else {
              this.paginas = res.total_rows;
              this.mostrando = (res.rows.length * page) - res.rows.length + 1;
              this.mostrando2 = (res.rows.length * page);
            }
          }
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  selectAllChecks = (allSelected: any, event: any) => {
    this.ordem_ids = [];
    this.ordens = [];
    if (allSelected) {
      this.listItem.rows.forEach((element: any) => {
        element.checked = true;
        this.ordem_ids.push(element.id);
        this.ordens.push(element);
      });
    } else {
      this.listItem.rows.forEach((element: any) => {
        element.checked = false;
      });
    }
  }

  changeStatus = (item: any, event: any) => {

    if (this.ordem_ids.length == 0) {
      this.ordem_ids.push(item.id);
      this.ordens.push(item);
    } else {
      var filter = this.ordem_ids.filter((x: any) => x == item.id);
      if (filter.length > 0) {
        for (let index = 0; index < this.ordem_ids.length; index++) {
          const element = this.ordem_ids[index];
          if (element == item.id) {
            this.ordem_ids.splice(index, 1);
          }
        }
        for (let index = 0; index < this.ordens.length; index++) {
          const element = this.ordens[index];
          if (element.id == item.id) {
            this.ordens.splice(index, 1);
          }
        }
      } else {
        this.ordem_ids.push(item.id);
        this.ordens.push(item);
      }
    }
  }

  deletarDadoCotacao = (item: any) => {
    for (let index = 0; index < this.ordens.length; index++) {
      const element = this.ordens[index];
      if (element.id == item.id) {
        this.ordens.splice(index, 1);
      }
    }
  }

  modalCriarContrato = () => {
    this.contrato = {};
    $("#cadastroContrato").toggleClass("show");
    $("#cadastroContrato").css("display", "block");

    let total = 0;
    for (const entry of this.ordens) {
      total += entry.cotacao.valor_total;
    }
    this.somaTotal = total;

    let iof = 0;
    for (const entry of this.ordens) {
      iof += entry.cotacao.iof;
    }
    this.somaIof = iof;


    let pagar = 0;
    for (const entry of this.ordens) {
      pagar += entry.cotacao.pagar;
    }
    this.somaPagar = pagar;


    let quantidade = 0;
    for (const entry of this.ordens) {
      quantidade += entry.cotacao.quantidade;
    }
    this.somaQuantidade = quantidade;
  }

  fecharModalCriarContrato = () => {
    this.contrato = {};
    $("#cadastroContrato").toggleClass("show");
    $("#cadastroContrato").css("display", "none");
  }

  saveContrato = () => {

    if (!this.contrato.nome) {
      var objAlert = {
        title: `Erro`,
        msg: 'Favor preencher nome',
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (this.ordens.length == 0) {
      var objAlert = {
        title: `Erro`,
        msg: 'Favor preencher uma ordem',
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    var obj = {
      "nome": this.contrato.nome,
      "ordem_ids": this.ordens.map((item: any) => { return item.id })
    }

    this.loadingSave = true;
    this.apiService.Post(`contrato`, obj).then((res: any) => {
      var objAlert = {
        title: `Sucesso`,
        msg: 'Sucesso ao criar',
        tipo: 1
      }
      this.showAlert(objAlert);
      this.ordens = [];
      this.ordem_ids = [];
      this.fecharModalCriarContrato();
      this.getOrders();
      this.loadingSave = false;
    }).catch((err) => {
      this.loadingSave = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        if (err.status == 401 || err.error.status == 401) {
          this.authService.logoutUser()
        } else {
          var objAlert = {
            title: `Erro`,
            msg: err.error.message,
            tipo: 2
          }
          this.showAlert(objAlert);
        }
      }
    });
  }
  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

  formatarNumeroQtd(numero: number, locale: string = 'pt-BR'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numero);
  }

}
