<nav class="sidebar dark_sidebar" [ngClass]="{'mini_sidebar': menuToogle == true , 'active_sidebar':  menuToogle2 == true}">
    <div class="logo d-flex justify-content-between">
        <a class="large_logo" [routerLink]="['/home']"><img class="logo-expanded" [src]="ambienteDev.logo" alt=""></a>
        <a class="small_logo" [routerLink]="['/home']"><img [src]="ambienteDev.icon" alt=""></a>
        <div class="sidebar_close_icon d-lg-none">
            <i class="ti-close"></i>
        </div>
    </div>
    <ul id="sidebar_menu">
        <li class="">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/home']">
                <div class="nav_icon_small">
                    <i class="fas fa-chart-line mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Dashboard </span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/users']">
                <div class="nav_icon_small">
                    <i class="fas fa-users mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Usuários</span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo !== 'subCliente'">
            <a class=" has-arrow" href="#" aria-expanded="false" [routerLink]="['/clients']">
                <div class="nav_icon_small">
                    <i class="fas fa-user-friends mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Clientes</span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/orders']">
                <div class="nav_icon_small">
                    <i class="fas fa-file-alt mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Ordens</span>
                </div>
            </a>
        </li>
        <li class="">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/order-history']">
                <div class="nav_icon_small">
                    <i class="fas fa-history mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Histórico de Ordens</span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/contracts']">
                <div class="nav_icon_small">
                    <i class="fas fa-file-contract mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Contratos</span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
            <a class="has-arrow" href="#" aria-expanded="false" [routerLink]="['/banks']">
                <div class="nav_icon_small">
                    <i class="fas fa-university mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Bancos</span>
                </div>
            </a>
        </li>
        <li class="" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
            <a class="has-arrow" href="javascript:void(0);" aria-expanded="isConfigExpanded" (click)="toggleConfigMenu()">
                <div class="nav_icon_small">
                    <i class="fas fa-cogs mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Configurações</span>
                </div>
            </a>
            <ul class="collapse list-unstyled" [ngClass]="{'show': isConfigExpanded}">
                <li>
                    <a [routerLink]="['/operation-day']">Dia Operação</a>
                </li>
                <li *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && !currentUser.modo_noturno">
                    <a [routerLink]="['/global-manager']">Global</a>
                </li>
                <li *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
                    <a [routerLink]="['/simulations']">Simulações</a>
                </li>
            </ul>
        </li>
        <li *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
            <a class="has-arrow" href="javascript:void(0);" aria-expanded="isNightModeExpanded" (click)="toggleNightModeMenu()">
                <div class="nav_icon_small">
                    <i class="fas fa-moon mr-2"></i>
                </div>
                <div class="nav_title">
                    <span>Modo Noturno</span>
                </div>
            </a>
            <ul class="collapse list-unstyled" [ngClass]="{'show': isNightModeExpanded}">
                <li>
                    <a [routerLink]="['/night-mode']">Configurações Noturnas</a>
                </li>
                <li>
                    <a [routerLink]="['/night-orders']">Ordens Noturnas</a>
                </li>
            </ul>
        </li>
    </ul>
</nav>