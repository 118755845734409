import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Router } from '@angular/router';
import { Global } from 'src/app/global';
declare var $: any;

@Component({
  selector: 'app-simulations',
  templateUrl: './simulations.component.html',
  styleUrls: ['./simulations.component.css']
})
export class SimulationsComponent implements OnInit {
  currentUser: any = {};
  loading = false;
  clientes: any;
  cliente: any = {};
  moedasClienteSelecionado: any[] = [];
  cotacoes: any;
  clienteId: string = '';
  moedaCotada: any;

  // Para paginação
  total_pages: number[] = [];
  paginas: number = 0;
  mostrando: number = 0;
  mostrando2: number = 0;
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    public global: Global
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.GetClientesPorMoedasUsdtUsdc();
  }

  GetClientesPorMoedasUsdtUsdc() {
    this.loading = true;
    let url = `clientes-usdt-usdc`;
    this.apiService.Get(url).then((res: any) => {
      this.loading = false;
      this.clientes = res;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }
  
  onClienteChange(event: Event) {
    this.resetPagination;
    const target = event.target as HTMLSelectElement;
    this.clienteId = target.value;
    this.GetClientesCotacoes();

    const selectedClient = this.clientes.find((c: any) => c.id === this.clienteId);
    if (selectedClient) {
      this.moedasClienteSelecionado = selectedClient.moedas.filter((moeda: any) => moeda.nome === 'USDT' || moeda.nome === 'USDC');
      if (this.moedasClienteSelecionado.length === 1) {
        this.cliente.moeda = this.moedasClienteSelecionado[0].nome;
      }
    } else {
      this.moedasClienteSelecionado = [];
    }
  }

  onlyNumberKey(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  formatarNumeroQtd(event: any) {
    let valor = event.target.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    this.cliente.quantidade = valor;
    event.target.value = valor;
  }

  getMoedaId(nomeMoeda: string): string | null {
    const moeda = this.moedasClienteSelecionado.find(m => m.nome === nomeMoeda);
    return moeda ? moeda.id : null;
  }

  onSubmit() {
    const nomeMoeda = this.cliente.moeda;
    const moedaId = this.getMoedaId(nomeMoeda);

    if (this.cliente.id == null) {
      this.global.exibirMensagemErro("Favor selecionar o cliente");
      return;
    }

    if (this.cliente.moeda == null) {
      this.global.exibirMensagemErro("Favor selecionar a moeda");
      return;
    }

    var obj = {
      "cliente_id": this.cliente.id,
      "moeda_id": moedaId,
      "quantidade": 10000,
      "validade": "D0",
    };
    this.loading = true;
    this.apiService.Post(`cotar-moeda`, obj).then((res: any) => {
      this.moedaCotada = res;
      this.loading = false;
      this.GetClientesCotacoes();
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  GetClientesCotacoes(page: number = 1, limit: number = 10) {
    this.loading = true;
    let url = `clientes-cotacoes/${this.clienteId}?page=${page}&limit=${limit}`;
    this.apiService.Get(url).then((res: any) => {
      this.loading = false;
      this.cotacoes = res.rows;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      } else {
        this.paginas = 0;
        this.mostrando = 0;
        this.mostrando2 = 0;
      }
      // $('#pagination_').twbsPagination('destroy');
      this.initPagination(res.total_pages, page, limit);
    }).catch((err) => {
      this.loading = false;
      if (err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }
  
  initPagination(totalPages: number, page: number, limit: number) {
    let that = this;
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
  
      $('#pagination_').twbsPagination('destroy');
      $('#pagination_').twbsPagination({
        totalPages: totalPages,
        visiblePages: 7,
        startPage: page,
        first: "Primeira",
        prev: "Anterior",
        next: "Próxima",
        last: "Última",
        currentPage: page,
        onPageClick: function (event: any, page: number) {
          if (that.ultimaPagina !== page) {
            that.ultimaPagina = page;
            that.GetClientesCotacoes(page, limit);
          }
        }
      });
    } else {
      this.ultimaPagina = page;
    }
  }
  

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getTipoDisplay(tipo: string): string {
    switch (tipo) {
      case 'clienteGestor':
        return 'gestor';
      case 'cliente':
        return 'cliente';
      case 'subCliente':
        return 'trader';
      default:
        return tipo;
    }
  }

  formaterCurrency(value: any) {
    return value;
  }

  formaterSpread(value: any, decimal: number) {
    const formattedValue = parseFloat(value).toFixed(decimal);
    return formattedValue + '%';
  }

}
