import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';
import { ApiService } from 'src/app/service/apiServices';
import { Global } from 'src/app/global';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-manager',
  templateUrl: './global-manager.component.html',
  styleUrls: ['./global-manager.component.css']
})
export class GlobalManagerComponent implements OnInit {
  currentUser: any = {};
  valorSpread: any;
  flgAtivarTopazio: boolean = false;

  constructor(
    private authService: AuthService,
    public apiService: ApiService,
    private router: Router,
    public global: Global
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.getFlgTopazio();
    this.getSpread();
  }

  getFlgTopazio() {
    this.apiService.Get("storage/ativar_topazio").then((res: any) => {
      this.flgAtivarTopazio = res.valor === "1";
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  getSpread() {
    this.apiService.Get("storage/spread_global").then((res: any) => {
      this.valorSpread = res.valor;
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  putSpread() {
    var valor = parseFloat(this.valorSpread).toFixed(2);
    var obj = {
      "chave" : "spread_global",
      "valor": valor
    };

    this.apiService.Put("storage/spread_global", obj).then((res: any) => {
      this.global.exibirMensagemSucesso("Atualizado com sucesso!");
      this.global.reloadCurrentRoute();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  toggleAtivarTopazio(event: any) {
    this.flgAtivarTopazio = event.target.checked;
    var obj = {
      "chave": "ativar_topazio",
      "valor": this.flgAtivarTopazio ? "1" : "0"
    };

    this.apiService.Put("storage/ativar_topazio", obj).then((res: any) => {
      // this.global.exibirMensagemSucesso("Atualizado com sucesso!");
      // this.global.reloadCurrentRoute();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }


  formatarNumero(event: any, decimais: number) {
    let valor = event.target.value;

    valor = valor.replace(/[^\d.]/g, '');

    if ((valor.length >= 2) && !valor.includes('.')) {
      valor = valor.slice(0, 1) + '.' + valor.slice(1);
    }

    event.target.value = valor

    if (valor.includes('.')) {
      const partes = valor.split('.');
      let inteiro = partes[0];
      let decimal = partes.length > 1 ? partes[1].substring(0, decimais) : '';
      event.target.value = `${inteiro}.${decimal}`
    }

  }
}
