import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
import * as moment from "moment";
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  global_: any;
  currentUser: any = {};
  loading: any = false;
  listItem: any = [];
  initialDate_: string = moment().format('DD/MM/YYYY');
  finalDate_: string = moment().format('DD/MM/YYYY');
  initialDate: string = moment().format('YYYY-MM-DD');
  finalDate: string = moment().format('YYYY-MM-DD');
  total_pages: any = [];
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;
  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');
  client: any = {};
  seepassword: any = false;
  seepasswordCnf: any = false;
  confirmarSenha: any = "";

  constructor(private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,) {
    this.global_ = global;
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('#initialDate').datepicker({ language: 'pt' });
      $('#finalDate').datepicker({ language: 'pt' });
    }, 1000);
    this.getProfile();
  }

  getProfile(page: number = 1, limit: number = 50) {
    this.loading = true;
    let url = ``;
    if (this.currentUser.tipo == 'clienteGestor' || this.currentUser.tipo == 'cliente' || this.currentUser.tipo == 'subCliente') {
      url = `cliente/${this.currentUser.id}`;
    } else {
      url = `usuario-admin/${this.currentUser.id}`;
    }

    this.apiService.Get(url).then((res: any) => {
      this.client = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit);
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  saveClient() {
    var obj = {
      "senha": this.client.senha,
    }

    if (!this.client.senha) {
      delete obj.senha;
    }
    this.putClient(obj);
  }

  putClient = (obj: any) => {
    this.loading = true;
    this.apiService.Put(`cliente/${this.client.id}`, obj).then((res: any) => {
      this.getProfile();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.global.showAlert(objAlert);
      }
    });
  }

  initPagination(totalPages: number, page: number, limit: number, id?: any) {
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((this.ultimaPagina !== page)) {
              this.ultimaPagina = page;
              that.getProfile(page, limit);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }

}
