import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import * as moment from "moment";
import { Global } from 'src/app/global';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css']
})
export class BanksComponent implements OnInit {


  loading: any = false;
  loadingBanco: any = false;
  banksList: any = [];
  total_pages: any = [];
  total_pages_aux: any = [];

  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');
  banke: any = {};
  bank: any = {};
  identificador: any = "";
  confirmarSenha: any;
  seepasswordCnf: any = false;
  seepassword: any = false;
  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.getBancos();
  }

  formaterCurrency(value: any) {
    var valor_ = value / 100;
    return value;
  }

  pesquisar() {
    this.getBancos(1);
  }

  getBancos(page = 1) {
    this.loading = true;
    var url = `banco-externo?page=${page}`;
    if (this.identificador) {
      url = url += `&identificador=${this.identificador}`;
    }
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.banksList = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }

      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = 1;
        this.mostrando2 = res.rows.length;

      }
      var primeiraChamada = true;
      try {
        $('#pagination_').twbsPagination('destroy');
        let that = this;
        $('#pagination_').twbsPagination({
          totalPages: res.total_pages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: function (event = null, page = 0) {
            if (!primeiraChamada) {
              that.getBancosPage(page);
            } else {
              primeiraChamada = false;
            }
          }
        });
      } catch (error) {

      }
      this.loading = false;
      setTimeout(() => {
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })

        $(function () {
          $('[data-toggle="popover"]').popover()
        })
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getBancosPage(page = 1) {
    this.loading = true;
    var url = `banco-externo?page=${page}`;
    if (this.identificador) {
      url = url += `&identificador=${this.identificador}`;
    }
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.banksList = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }
      if (res.rows.length > 0) {
        if (page == 1) {
          this.paginas = res.total_rows;
          this.mostrando = 1;
          this.mostrando2 = res.rows.length;
        } else
          if (page > 1) {
            if (this.total_pages.length == page) {
              this.paginas = res.total_rows;
              this.mostrando = res.total_rows - res.rows.length + 1
              this.mostrando2 = res.total_rows;
            } else {
              this.paginas = res.total_rows;
              this.mostrando = (res.rows.length * page) - res.rows.length + 1;
              this.mostrando2 = (res.rows.length * page);
            }
          }
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  verDetalhesBanco = (bank: any) => {
    this.getBanco(bank.id);
  }

  modalAdicionarBanco = (banke: any = {}) => {
    this.loading = false;
    this.bank = banke;
    $("#cadastroBanco").toggleClass("show");
    $("#cadastroBanco").css("display", "block");
  }

  fecharModalAdicionarBanco = () => {
    $("#cadastroBanco").toggleClass("show");
    $("#cadastroBanco").css("display", "none");
  }

  saveBanco = () => {
    if (this.bank.country) {
      this.bank.country = parseInt(this.bank.country);
    }
    if (this.bank.nature) {
      this.bank.nature = parseInt(this.bank.nature);
    }
    if (this.bank.id) {
      this.putBanco(this.bank);
    } else {
      this.postBanco(this.bank);
    }
  }

  postBanco = (obj: any) => {
    this.loading = true;
    this.apiService.Post(`banco-externo`, obj).then((res: any) => {
      this.fecharModalAdicionarBanco();
      this.getBancos();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getBanco = (id: any) => {
    this.loadingBanco = true;
    this.apiService.Get(`banco-externo/${id}`).then((res: any) => {
      this.modalAdicionarBanco(res);
      this.loadingBanco = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  putBanco = (obj: any) => {
    this.loading = true;
    this.apiService.Put(`banco-externo/${obj.id}`, obj).then((res: any) => {
      this.fecharModalAdicionarBanco();
      this.getBancos();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  deletarDado(d: any) {
    var x;
    var r = confirm("Deseja remover este banco?");
    if (r == true) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.apiService.Delete(`banco-externo/${d.id}`).then((res) => {
        this.loading = false;
        this.getBancos();
      }).catch((err) => {
        this.loading = false;
      })
    }
  }


  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

}
