import { Component, OnInit } from '@angular/core';
declare var $: any;
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatDialog } from '@angular/material';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { Global } from 'src/app/global';
import { Router } from '@angular/router';
import * as moment from "moment";

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {
  _global: Global;

  // Para paginação
  total_pages: number[] = [];
  paginas: number = 0;
  mostrando: number = 0;
  mostrando2: number = 0;
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;

  // Para pesquisa
  initialDate_: string = moment().format('DD/MM/YYYY');
  finalDate_: string = moment().format('DD/MM/YYYY');
  initialDate: string = moment().format('YYYY-MM-DD');
  finalDate: string = moment().format('YYYY-MM-DD');
  nomeGrupo: string = "";

  loading: boolean = false;
  listItem: any = [];
  addGrupo: any = {};

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,
    private router: Router,
  ) {
    this._global = global;
  }

  ngOnInit(): void {
    this.getGrupos();
  }


  getGrupos(page: number = 1, limit: number = 10) {
    this.loading = true;
    let url = `grupo?page=${page}&limit=${limit}`;
    if (this.nomeGrupo) {
      url += `&nome=${this.nomeGrupo}`
    }

    this.apiService.Get(url).then((res: any) => {
      this.listItem = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit, this.ultimaTotalPages, this.ultimaPagina, this.getGrupos.bind(this));
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  // postGrupo(obj: any) {
  //   this.loading = true;
  //   this.apiService.Post(`grupo`, obj).then((res: any) => {
  //     // this.fecharModalAddGrupo();
  //     this.getGrupos();
  //     this.loading = false;
  //   }).catch((err) => {
  //     this.loading = false;
  //     if (err.status == 401 || err.error.status == 401) {
  //       this.authService.logoutUser()
  //     } else {
  //       var objAlert = {
  //         title: `Erro`,
  //         msg: err.error.message,
  //         tipo: 2
  //       }
  //       this.showAlert(objAlert);
  //     }
  //   });
  // }

  pesquisar(page: number = 1) {
    this.resetPagination;
    this.getGrupos(page)
  }

  initPagination(totalPages: number, page: number, limit: number, lastTotalPage: number, lastPage: number, fetchDataFunction: (id?: any, page?: number) => void, id?: any) {
    if (lastTotalPage !== totalPages) {
      lastTotalPage = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((lastPage !== page)) {
              lastPage = page;
              fetchDataFunction(page, limit);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  modalAddGrupo(grupo = null) {
    if (grupo) {
      var obj = Object.assign({}, grupo);
      this.addGrupo = obj;
    } else {
      this.addGrupo = {};
    }
    $("#modalAddGrupo").toggleClass("show");
    $("#modalAddGrupo").css("display", "block");
  }

  fecharModalAddGrupo() {
    $("#modalAddGrupo").toggleClass("show");
    $("#modalAddGrupo").css("display", "none");
  }

  postGrupo = () => {
    if (this.addGrupo.nome == null) {
      this.global.exibirMensagemErro("favor preencher o nome do grupo");
      return;
    }

    var objeto = {
      "nome": this.addGrupo.nome,
      "descricao": this.addGrupo.descricao,
    }

    this.loading = true;
    this.apiService.Post(`grupo`, objeto).then((res: any) => {

      // var objAlert = {
      //   title: `Sucesso`,
      //   msg: "Grupo adicionado com sucesso!",
      //   tipo: 1
      // }
      // this.showAlert(objAlert);
      this.fecharModalAddGrupo();
      this.reloadCurrentRoute();

      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  putGrupo = (dadosAtualizados: any) => {
    this.loading = true;
    this.apiService.Put(`grupo/${dadosAtualizados.id}`, dadosAtualizados).then((res: any) => {

      var objAlert = {
        title: `Sucesso`,
        msg: "Grupo editado com sucesso!",
        tipo: 1
      }
      this.showAlert(objAlert);
      this.fecharModalEditarGrupo();
      this.reloadCurrentRoute();

      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        this.global.exibirMensagemErro(err.error.message);
      }
    });
  }

  modalEditarGrupo(grupo: any) {
    this.addGrupo = { ...grupo };
    $("#modalEditarGrupo").toggleClass("show");
    $("#modalEditarGrupo").css("display", "block");
  }

  fecharModalEditarGrupo() {
    $("#modalEditarGrupo").toggleClass("show");
    $("#modalEditarGrupo").css("display", "none");
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }

}
