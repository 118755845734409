<!-- <div class="row">
  <form>
    <div class="row mb-3">
      <div class="col-md-12">
        <form>
          <div class="row mb-3">
            <div class="col-md-3">
              <label class="form-label" for="moedaSelect">Nome da moeda</label>
              <select id="moedaSelect" class="form-select" [(ngModel)]="selectedMoeda" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let moeda of moedas.moedas_menu">{{ moeda }}</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</div> -->
<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
  <div class="main_content_iner overly_inner">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
            <div class="page_title_left d-flex align-items-center">
              <h3 class="mb-0">Configurações Noturnas</h3>
            </div>
            <div class="page_title_right">
              <ol class="breadcrumb page_bradcam mb-0">
                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                <li class="breadcrumb-item active">Configurações Noturnas</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="white_card card_height_100 mb_30">
            <div class="white_card_header">
              <div class="box_header m-0">
                <form>
                  <div class="row mb-3">
                    <div class="col-md-3" style="align-content: end;">
                      <div class="form-check">
                        <label class="form-label form-check-label" for="flgAtivarModoNoturno">Ativar modo noturno/diurno global</label>
                        <input 
                          class="form-check-input" 
                          type="checkbox" 
                          id="flgAtivarModoNoturno" 
                          (change)="toggleNightMode($event)" 
                          [(ngModel)]="flgAtivarModoNoturno" 
                          name="flgAtivarModoNoturno">
                      </div>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-6 descricoes">Ativa/Desativa manualmente este modo global. Ele é ativado automaticamente entre 16:36 e 09:04.</div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-2" style="align-content: end;">
                      <div class="form-check">
                        <label class="form-label form-check-label" for="flgAtivarCotacaoFixa">Ativar cotação dólar fixa</label>
                        <input class="form-check-input" type="checkbox" id="flgAtivarCotacaoFixa" (change)="toggleFlgAtivarCotacaoFixa($event)" [(ngModel)]="flgAtivarCotacaoFixa" name="flgAtivarCotacaoFixa"> </div>
                    </div>
                    <div class="col-md-2" style="align-content: end;">
                      <label class="form-label" for="valorCotacaoDolarFixaNoturno">Cotação Dólar Fixa</label>
                      <input class="form-control" id="valorCotacaoDolarFixaNoturno" name="valorCotacaoDolarFixaNoturno" type="text" placeholder="0.00" (input)="formatarNumero($event, 4)" [(ngModel)]="valorCotacaoDolarFixaNoturno">
                    </div>
                    <div class="col-md-2">
                      <label class="form-label" for="valorCotacaoDolarDinamicaNoturno">Cotação Dólar Dinâmica</label>
                      <input class="form-control" id="valorCotacaoDolarDinamicaNoturno" name="valorCotacaoDolarDinamicaNoturno" type="text" placeholder="0.00" (input)="formatarNumero($event, 4)" [(ngModel)]="valorCotacaoDolarDinamicaNoturno" disabled="true">
                    </div>
                    <div class="col-md-6 descricoes">Escolha qual cotação dólar utilizar (fixa ou dinâmica). Se for fixa, insira o valor que será utilizado.</div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-2">
                      <label class="form-label" for="valorSpreadNoturno">Spread</label>
                      <input class="form-control" id="valorSpreadNoturno" name="valorSpreadNoturno" type="text" placeholder="0.00" (input)="formatarNumero($event, 2)" [(ngModel)]="valorSpreadNoturno">
                    </div>
                    <div class="col-md-4"></div>
                    <div class="col-md-6 descricoes">Spread será somada com o valor do spread do cliente durante o cálculo.</div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-2">
                      <button type="button" (click)="onSubmit()" class="btn btn-primary mb-2 btnEnviar">Enviar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <!-- <div class="white_card_body">
            <div class="QA_section">
              <div class="QA_table mb_30">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Titulo</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">Titulo</th>
                        <th scope="col">Titulo</th>
                      </tr>
                    </thead>
                    <tbody style="border-bottom: 0;">
                      <tr>
                        <td>conteudo</td>
                        <td>conteudo</td>
                        <td>conteudo</td>
                        <td>conteudo</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <div class="row mb-3">
  <div class="row mb-3" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
      <div class="col-md-4" style="align-content: end;">
          <div class="form-check">
              <label class="form-label form-check-label" for="nightModeChecked">Ativar Modo Noturno</label>
              <input class="form-check-input" type="checkbox" id="nightModeChecked" (change)="toggleNightMode($event)">
          </div>
      </div>
  
      <div class="col-md-4">
          <label class="form-label" for="valorCotacaoNoturno">Cotação</label>
          <input class="form-control" id="valorCotacaoNoturno" name="valorCotacaoNoturno" type="text" placeholder="0,00" (input)="formatarMoedaBRL($event, 'valorCotacaoNoturno')" [(ngModel)]="valorCotacaoNoturno" [disabled]="!nightModeChecked">
      </div>
      <div class="col-md-4">
          <label class="form-label" for="valorDolarNoturno">Cotação Dólar</label>
          <input class="form-control" id="valorDolarNoturno" name="valorDolarNoturno" type="text" placeholder="0,00" (input)="formatarMoedaBRL($event, 'valorDolarNoturno')" [(ngModel)]="valorDolarNoturno" [disabled]="!nightModeChecked">
      </div>
  </div>
</div> -->

<!-- <form>
  <div class="col-md-6">
    <div class="form-check">
      <label class="form-label form-check-label" for="nightModeChecked">Ativar</label>
      <input class="form-check-input" type="checkbox" id="nightModeChecked" (change)="toggleNightMode($event)">
    </div>
  </div>
</form> -->

<!-- 
<div class="row mb-3">
  <div class="col-md-2">
    <label class="form-label" for="moedaSelect">Moeda</label>
    <select id="moedaSelect" class="form-select" [(ngModel)]="selectedMoeda" [ngModelOptions]="{standalone: true}" [disabled]="!nightModeChecked">
      <option *ngFor="let moeda of moedas.moedas_menu">{{ moeda }}</option>
    </select>
  </div>
  <div class="col-md-2">
    <label class="form-label" for="valorCotacao">Cotação</label>
    <input class="form-control" id="valorCotacao" name="valorCotacao" type="text" placeholder="0,00" (input)="formatarMoedaBRL($event, 'valorCotacao')" [(ngModel)]="valorCotacao" [disabled]="!nightModeChecked">
  </div>
  <div class="col-md-2">
    <label class="form-label" for="valorDolar">Cotação Dólar</label>
    <input class="form-control" id="valorDolar" name="valorDolar" type="text" placeholder="0,00" (input)="formatarMoedaBRL($event, 'valorDolar')" [(ngModel)]="valorDolar" [disabled]="!nightModeChecked">
  </div>
  <div class="col-md-2">
    <label class="form-label" for="moedaSpread">Spread %</label>
    <input type="text" class="form-control" id="moedaSpread" placeholder="spread" [(ngModel)]="spread" (input)="formatAsPercent($event)" [ngModelOptions]="{standalone: true}" [disabled]="!nightModeChecked">
  </div>
  <div class="col-auto">
    <button type="button" (click)="onSubmit()" class="btn btn-primary mb-2 botaoBuscar">Enviar</button>
  </div>
</div> -->