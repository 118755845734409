import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
declare var $: any;
import * as moment from "moment";
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-data-bloqueada',
  templateUrl: './data-bloqueada.component.html',
  styleUrls: ['./data-bloqueada.component.css']
})
export class DataBloqueadaComponent implements OnInit {
  loading: any = false;
  loadingSave: any = false;
  total_pages: any = [];
  total_pages_aux: any = [];

  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');


  listItem: any = [];

  contrato: any = {};
  ordem_ids: any = [];
  status: any = "aprovado";
  _global: any;
  ordens: any = [];
  allSelected: any = false;
  dataBloqueada: any = "";
  dataItem: any;

  constructor(private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,) {
    this._global = global;
  }

  ngOnInit(): void {

    this.getDataBloqueada();
  }

  pesquisar() {
    this.getDataBloqueada();
  }

  getDataBloqueada(page = 1) {
    this.loading = true;
    var url = `data-bloqueada`;
    this.apiService.Get(url).then((res: any) => {
      this.loading = false;
      if (res.rows) {
        this.total_pages = [];

        this.listItem = res;
        for (let index = 0; index < res.total_pages; index++) {
          const element = index;
          this.total_pages.push(index + 1);
        }

        if (res.rows.length > 0) {
          this.paginas = res.total_rows;
          this.mostrando = 1;
          this.mostrando2 = res.rows.length;

        }
        var primeiraChamada = true;
        try {
          $('#pagination_').twbsPagination('destroy');
          let that = this;
          $('#pagination_').twbsPagination({
            totalPages: res.total_pages,
            visiblePages: 7,
            first: "Primeira",
            prev: "Anterior",
            next: "Próxima",
            last: "Última",
            onPageClick: function (event = null, page = 0) {
              if (!primeiraChamada) {
                that.getDataBloqueadaPage(page);
              } else {
                primeiraChamada = false;
              }
            }
          });
        } catch (error) {

        }

        setTimeout(() => {
          $(function () {
            $('[data-toggle="tooltip"]').tooltip()
          })

          $(function () {
            $('[data-toggle="popover"]').popover()
          })
        }, 1000);

      } else {
        this.listItem = [];
      }
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getDataBloqueadaPage(page = 1) {
    this.loading = true;
    var url = `data-bloqueada`;
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.listItem = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }
      if (res.rows.length > 0) {
        if (page == 1) {
          this.paginas = res.total_rows;
          this.mostrando = 1;
          this.mostrando2 = res.rows.length;
        } else
          if (page > 1) {
            if (this.total_pages.length == page) {
              this.paginas = res.total_rows;
              this.mostrando = res.total_rows - res.rows.length + 1
              this.mostrando2 = res.total_rows;
            } else {
              this.paginas = res.total_rows;
              this.mostrando = (res.rows.length * page) - res.rows.length + 1;
              this.mostrando2 = (res.rows.length * page);
            }
          }
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  selectAllChecks = (allSelected: any, event: any) => {
    this.ordem_ids = [];
    this.ordens = [];
    if (allSelected) {
      this.listItem.rows.forEach((element: any) => {
        element.checked = true;
        this.ordem_ids.push(element.id);
        this.ordens.push(element);
      });
    } else {
      this.listItem.rows.forEach((element: any) => {
        element.checked = false;
      });
    }
  }

  changeStatus = (item: any, event: any) => {

    if (this.ordem_ids.length == 0) {
      this.ordem_ids.push(item.id);
      this.ordens.push(item);
    } else {
      var filter = this.ordem_ids.filter((x: any) => x == item.id);
      if (filter.length > 0) {
        for (let index = 0; index < this.ordem_ids.length; index++) {
          const element = this.ordem_ids[index];
          if (element == item.id) {
            this.ordem_ids.splice(index, 1);
          }
        }
        for (let index = 0; index < this.ordens.length; index++) {
          const element = this.ordens[index];
          if (element.id == item.id) {
            this.ordens.splice(index, 1);
          }
        }
      } else {
        this.ordem_ids.push(item.id);
        this.ordens.push(item);
      }
    }
  }


  deletarData = (item: any) => {
    var x;
    var r = confirm("Deseja remover este dado?");
    if (r == true) {
      this.deleteData(item)
    }
  }

  editarData = (item: any) => {
    this.dataItem = item;
    this.dataBloqueada = "";
    var that = this;
    setTimeout(() => {
      $('#dataBloqueada').datepicker({ language: 'pt' });
      this.dataBloqueada = this.global.formateDate(item.data);
      $('#dataBloqueada').datepicker('setDate', item.data);

    }, 1000);
    $("#cadastroCriarData").toggleClass("show");
    $("#cadastroCriarData").css("display", "block");
  }
  modalCriarData = () => {
    this.dataBloqueada = "";
    this.dataItem = null;
    var that = this;
    setTimeout(() => {
      $('#dataBloqueada').datepicker({ language: 'pt' });
    }, 1000);
    $("#cadastroCriarData").toggleClass("show");
    $("#cadastroCriarData").css("display", "block");
  }

  fecharmodalCriarData = () => {
    this.contrato = {};
    $("#cadastroCriarData").toggleClass("show");
    $("#cadastroCriarData").css("display", "none");
  }

  saveData = () => {

    if (!$("#dataBloqueada").val()) {
      var objAlert = {
        title: `Erro`,
        msg: 'Favor preencher data',
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    var dTi = moment(`${$("#dataBloqueada").val().split(" ")[0].split("/")[2]}-${$("#dataBloqueada").val().split(" ")[0].split("/")[1]}-${$("#dataBloqueada").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')

    var obj = {
      "data": dTi
    }

    this.loadingSave = true;
    if (this.dataItem) {
      this.putData(obj);
    } else {
      this.postData(obj);
    }
  }

  postData = (obj: any) => {

    this.apiService.Post(`data-bloqueada`, obj).then((res: any) => {
      this.fecharmodalCriarData();
      this.getDataBloqueada();
      this.loadingSave = false;
    }).catch((err) => {
      this.loadingSave = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        if (err.status == 401 || err.error.status == 401) {
          this.authService.logoutUser()
        } else {
          var objAlert = {
            title: `Erro`,
            msg: err.error.message,
            tipo: 2
          }
          this.showAlert(objAlert);
        }
      }
    });
  }

  putData = (obj: any) => {

    this.apiService.Put(`data-bloqueada/${this.dataItem.id}`, obj).then((res: any) => {
      this.dataItem = null;
      this.fecharmodalCriarData();
      this.getDataBloqueada();
      this.loadingSave = false;
    }).catch((err) => {
      this.loadingSave = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        if (err.status == 401 || err.error.status == 401) {
          this.authService.logoutUser()
        } else {
          var objAlert = {
            title: `Erro`,
            msg: err.error.message,
            tipo: 2
          }
          this.showAlert(objAlert);
        }
      }
    });
  }


  deleteData = (obj: any) => {
    this.loadingSave = true;
    this.apiService.Delete(`data-bloqueada/${obj.id}`).then((res: any) => {
      this.dataItem = null;
      this.fecharmodalCriarData();
      this.getDataBloqueada();
      this.loadingSave = false;
    }).catch((err) => {
      this.loadingSave = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        if (err.status == 401 || err.error.status == 401) {
          this.authService.logoutUser()
        } else {
          var objAlert = {
            title: `Erro`,
            msg: err.error.message,
            tipo: 2
          }
          this.showAlert(objAlert);
        }
      }
    });
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }
}
