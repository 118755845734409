<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Data bloqueada</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Data bloqueada</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card_body">
                    <div class="white_card mb_30 card_height_100">
                        <div class="white_card_header ">
                            <div class="box_header m-0">
                                <div class="main-title">
                                    <h3 class="m-0">Data bloqueada</h3>
                                </div>
                                <button type="button" class="btn btn-primary mb-3 btn-add hide-mobile" (click)="modalCriarData()"
                                    title="Cadastrar data">
                                    Cadastrar data
                                </button>
                            </div>
                        </div>
                  
                        <div class="white_card_body pt-0">
                            <div class="QA_section">
                                <div class="btn-add-m">
                                    <button type="button" class="btn btn-primary mb-3 btn-add"
                                        (click)="modalCriarData()" title="Cadastrar data">
                                        <i class="ti-plus"></i>
                                    </button>
                                </div>
                                <div class="QA_table mb_30 transaction-table">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Data</th>
                                                    <th scope="col" style="text-align: right;">Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!loading && listItem">
                                                <tr *ngFor="let item of listItem.rows">
                                                    <td>{{
                                                        item.data?
                                                        _global.formateDate(item.data)
                                                        :
                                                        '-'
                                                        }}
                                                    </td>
                                                    <td style="text-align: right;">
                                                        <button type="button" class="btn btn-warning "
                                                            (click)="editarData(item)" title="Editar data">
                                                            <i class="ti-pencil-alt2"></i>
                                                        </button>&nbsp;
                                                        <button type="button" class="btn btn-danger "
                                                            (click)="deletarData(item)" title="Deletar data">
                                                            <i class="ti-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="loading">
                                                <div class="loading-table">
                                                    <img
                                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-body pag-custom">
                                            <div class="col" *ngIf="total_pages.length > 0">
                                                <div class="navigations-rows-shows">
                                                    <h2>Mostrando {{mostrando}} até
                                                        {{mostrando2}}
                                                        de {{paginas}} registros
                                                    </h2>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <nav aria-label="Page navigation">
                                                    <ul class="pagination" id="pagination_"></ul>
                                                </nav>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal centerModal fade" id="cadastroCriarData" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    Cadastrar data
                </h5>
                <button type="button" class="btn-close" (click)="fecharmodalCriarData()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_body">
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon1"><i class="ti-calendar"></i></span>
                            </div>
                            <input class="form-control digits" id="dataBloqueada" type="text"
                                placeholder="Data bloqueada" [(ngModel)]="dataBloqueada"
                                [ngModelOptions]="{standalone: true}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loadingSave" class="btn btn-secondary"
                    (click)="fecharmodalCriarData()">Fechar</button>
                <span>
                    <img *ngIf="loadingSave"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loadingSave" type="button" class="btn btn-primary" (click)="saveData()">
                        Salvar data bloqueada
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>