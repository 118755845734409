import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  currentUser: any = {};
  client: any = {};
  locationJson: any = {};
  menuToogle: any = false;
  menuToogle2: any = false;
  ambienteDev: any = environment;
  public isNightModeExpanded: boolean = false;
  public isConfigExpanded: boolean = false;

  constructor(private router: Router, private authService: AuthService, public dialog: MatDialog) {
    this.authService.toogleMenu.subscribe((res: any) => {
      this.menuToogle = res;
    });
    this.authService.toogleMenu2.subscribe((res: any) => {
      this.menuToogle2 = res;
    });

    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
  }

  toggleNightModeMenu() {
    this.isNightModeExpanded = !this.isNightModeExpanded;
  }

  toggleConfigMenu() {
    this.isConfigExpanded = !this.isConfigExpanded;
  }

}
