<!-- src\app\screens\client-details\client-details.component.html -->
<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Detalhes do {{ getTipoDisplay(client.tipo) }} {{client.cliente_nome_}}
                            <img *ngIf="loading"
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item"><a [routerLink]="['/clients']">Clientes</a></li>
                            <li class="breadcrumb-item active">Cliente</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-12">
            <div class="white_card card_height_100 mb_30">
                <div class="white_card_header">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="main-title">
                                <h3 class="m-0">
                                    <!-- Dados do {{ getTipoDisplay(client.tipo) }} -->
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="white_card_body ">
                    <ul class="nav nav-pills nav-fill custom_bootstrap_nav">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{'active': abaSelected == 1}" style="cursor: pointer;" (click)="abaSelected = 1">Geral</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{'active': abaSelected == 2}" style="cursor: pointer;" (click)="abaSelected = 2">Moedas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="{'active': abaSelected == 3}" style="cursor: pointer;" (click)="abaSelected = 3">Carteira</a>
                        </li>
                        <li class="nav-item" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno && client.tipo !== 'subCliente'
                        ">
                            <a class="nav-link" [ngClass]="{'active': abaSelected == 4}" style="cursor: pointer;" (click)="abaSelected = 4">Subclientes</a>
                        </li>
                    </ul>
                </div>
                
                <div class="row" *ngIf="abaSelected == 1">
                    <div class="col-lg-12">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_body">
                                <div class="card-body" *ngIf="!loading">
                                    <form class="form-client">
                                        <div class="row mb-3 inline-inputs-center">
                                            <div class="col">
                                                <label class="form-label" for="nome">Nome</label>
                                                <input type="text" class="form-control" id="nome" [(ngModel)]="client.nome" [ngModelOptions]="{standalone: true}" placeholder="Nome">
                                            </div>
                                            <div class="col">
                                                <label class="form-label" for="chave">Chave</label>
                                                <input type="text" class="form-control" id="chave" [(ngModel)]="client.chave" [ngModelOptions]="{standalone: true}" placeholder="chave">
                                            </div>
                                            <div class="col">
                                                <label class="form-label" for="documento">Documento</label>
                                                <input type="text" class="form-control" id="documento" [(ngModel)]="client.documento" [ngModelOptions]="{standalone: true}" placeholder="Documento">
                                            </div>
                                        </div>
                                        <div class="row mb-3" *ngIf="client.operar_com_credito">
                                            <div class="col">
                                                <label class="form-label" for="cliente_credito">Crédito</label>
                                                <input type="text" class="form-control" id="cliente_credito" style="text-align: left !important;" [(ngModel)]="client.credito" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}" placeholder="">
                                            </div>
                                            <div class="col">
                                                <label class="form-label" for="cliente_credito_utilizado">Crédito
                                                    utilizado</label>
                                                <input type="text" class="form-control" id="cliente_credito_utilizado" style="text-align: left !important;" [(ngModel)]="client.credito_utilizado" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}" placeholder="">
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label" for="cliente_nome">Senha</label>
                                                <div class="input-group mb-3">
                                                    <input [type]="seepassword? 'text' : 'password'" class="form-control" placeholder="Senha" aria-label="Senha" [(ngModel)]="client.senha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                                                    <div class="input-group-text btn-eye" (click)="seepassword = !seepassword">
                                                        <span class="" id="basic-addon2">
                                                            <i class="ti-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <label class="form-label" for="cliente_nome">Confirmar Senha</label>
                                                <div class="input-group mb-3">
                                                    <input [type]="seepasswordCnf? 'text' : 'password'" class="form-control" placeholder="Confirmar Senha" aria-label="Senha" [(ngModel)]="confirmarSenha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                                                    <div class="input-group-text btn-eye" (click)="seepasswordCnf = !seepasswordCnf">
                                                        <span class="" id="basic-addon2">
                                                            <i class="ti-eye"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
                                            <div class="input-group mb-3">
                                                <div class="form-check">
                                                    <label class="form-label form-check-label" for="monoNoturno">Marque para que este cliente possa operar com configurações noturnas.</label>
                                                    <input class="form-check-input" type="checkbox" id="monoNoturno" (change)="toggleModoNoturno($event)" [(ngModel)]="client.modo_noturno" name="monoNoturno">
                                                </div>
                                            </div>
                                            <div class="input-group mb-3" *ngIf="client.tipo === 'subCliente'">
                                                <div class="form-check">
                                                    <label class="form-label form-check-label" for="operarComSaldo">Marque para que este cliente possa operar com saldo.</label>
                                                    <input class="form-check-input" type="checkbox" id="operarComSaldo" (change)="toggleOperarComSaldo($event)" [checked]="!client.operar_com_credito" name="operarComSaldo">
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div *ngIf="currentUser && currentUser.tipo === 'clienteGestor'">
                                            <div class="input-group mb-3">
                                                <div class="form-check">
                                                    <label class="form-label form-check-label" for="operarComSaldo">Marque para que este cliente possa operar com saldo.</label>
                                                    <input class="form-check-input" type="checkbox" id="operarComSaldo" (change)="toggleOperarComSaldo($event)" [checked]="!client.operar_com_credito" name="operarComSaldo">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="center-btn">
                                            <button type="submit" class="btn btn-primary" (click)="saveClient()">Alterar dados</button>
                                        </div>
                                    </form>
                                </div>
                                <img *ngIf="loading"
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="abaSelected == 2">
                    <div class="col-lg-12">
                        <div class="white_card card_height_100 mb_30">
                            <div class="inline-flex-itens">
                                <div class="row align-items-center">
                                </div>
                                <div *ngIf="currentUser && ((currentUser.tipo === 'usuarioAdmin' && client.tipo === 'clienteGestor') || (currentUser.tipo === 'clienteGestor' && client.tipo === 'cliente') || (currentUser.tipo === 'cliente' && client.tipo === 'subCliente'))" >
                                    <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAddMoeda()" title="Adicionar moeda">
                                        Adicionar moeda
                                    </button>
                                </div>
                            </div>
                            <div class="white_card_body">
                                <div class="QA_section">
                                    <div class="QA_table mb_30">
                                        <table class="table lms_table_active3 ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Moeda</th>
                                                    <th scope="col">Spread</th>
                                                    <th scope="col" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">Spread Noturno</th>
                                                    <th scope="col" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">Spread Intermediário</th>
                                                    <th scope="col">Mínimo</th>
                                                    <th scope="col">Máximo</th>
                                                    <th scope="col" style="text-align: right;">Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!loading">
                                                <tr *ngFor="let item of client.moedas">
                                                    <td>{{item.nome}}</td>
                                                    <td>{{item.spread}}%</td>
                                                    <td *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">{{item.spread_noturno}}%</td>
                                                    <td *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">{{item.spread_intermediario}}%</td>
                                                    <td>{{item.minimo | currency:'BRL':true:'1.2-2'}}</td>
                                                    <td>{{item.maximo| currency:'BRL':true:'1.2-2'}}</td>
                                                    <td style="text-align: right;">
                                                        <button type="button" title="Editar moeda" (click)="modalEditarMoeda(item)" class="btn btn-primary ">
                                                            <i class="ti-pencil-alt2"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" title="Deletar moeda" (click)="deletarMoeda(item)" class="btn btn-primary ">
                                                            <i class="ti-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="loading">
                                                <div class="loading-table">
                                                    <img
                                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </tbody>

                                        </table>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="card">
                                            <div class="card-body pag-custom">
                                                <div class="col" *ngIf="total_pages.length > 0">
                                                    <div class="navigations-rows-shows">
                                                        <h2>Mostrando {{mostrando}} até
                                                            {{mostrando2}}
                                                            de {{paginas}} registros
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <nav aria-label="Page navigation">
                                                        <ul class="pagination" id="pagination_"></ul>
                                                    </nav>
                                                </div>
                                                <div class="col">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="abaSelected == 3">
                    <div class="col-lg-12">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_header">
                                <div class="row">
                                    <div class="row align-items-center inline-inputs-center">
                                        <div class="col-auto">
                                            <label class="form-label" for="initialDate">Data inicial</label>
                                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label" for="finalDate">Data final</label>
                                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" (click)="pesquisarTransacoes()" style="margin-top: 30px;" class="btn btn-primary">Buscar
                                                transações</button>
                                        </div>
                                        <div class="col-auto" *ngIf="avisoIntervaloDatas" style="margin-top: 30px;">
                                            <span class="alert alert-warning" id="avisoIntervaloDatas">
                                                Por favor, altere o intervalo de datas para realizar uma nova pesquisa.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="inline-flex-itens">
                                        <label style="font-size: medium;"> Saldo: {{transacoes.carteira.saldo | currency:'BRL':true:'1.2-2'}}</label>
                                        <!-- <button type="button" class="btn btn-primary mb-3 btn-add btn-add-m"
                                            (click)="modalAddTransacaoManual()" title="Adicionar
                                            transação">
                                            <i class="ti-plus"></i>
                                        </button> -->
                                        <button type="button" class="btn btn-primary mb-3 btn-add hide-mobile" (click)="modalAddTransacaoManual()" title="Adicionar transação"> Adicionar
                                            transação</button>
                                    </div>
                                </div>
                            </div>
                            <div class="white_card_body">
                                <div class="QA_section">
                                    <div class="QA_table mb_30 transacoes-table">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Data</th>
                                                        <th scope="col">Valor</th>
                                                        <th scope="col">Descrição</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="!loading">
                                                    <tr *ngFor="let item of transacoes.rows" [ngClass]="{'table-danger': item.descricao?.includes('saque'), 'table-success': !item.descricao?.includes('saque')}">
                                                        <td [ngClass]="{'table-danger': item.descricao?.includes('saque'), 'table-success': !item.descricao?.includes('saque')}">
                                                            {{ item.data_registro ? global.formateDateAndTime(item.data_registro) : '-' }}
                                                        </td>
                                                        <td [ngClass]="{'table-danger': item.descricao?.includes('saque'), 'table-success': !item.descricao?.includes('saque')}">
                                                            {{ item.descricao?.includes('saque') ? '-' + global.formaterCurrency(item.pagamento | currency: 'BRL': true: '1.2-2') : global.formaterCurrency(item.pagamento | currency: 'BRL': true: '1.2-2') }}
                                                        </td>
                                                        <td [ngClass]="{'table-danger': item.descricao?.includes('saque'), 'table-success': !item.descricao?.includes('saque')}">
                                                            {{ item.descricao }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="loading">
                                                    <div class="loading-table">
                                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    </div>
                                                </tbody>
                                            </table>
                                            
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="card">
                                            <div class="card-body pag-custom">
                                                <div class="col" *ngIf="total_pages.length > 0">
                                                    <div class="navigations-rows-shows">
                                                        <h2>Mostrando {{mostrando}} até
                                                            {{mostrando2}}
                                                            de {{paginas}} registros
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div class="col">
                                                    <nav aria-label="Page navigation">
                                                        <ul class="pagination" id="pagination_"></ul>
                                                    </nav>
                                                </div>
                                                <div class="col">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="abaSelected == 4 && currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
                    <div class="col-lg-12">
                        <div class="white_card card_height_100 mb_30">
                            <div class="white_card_header">
                            </div>
                            <div class="white_card_body">
                                <div class="QA_section">
                                    <div class="QA_table mb_30 transacoes-table">
                                        <div class="table-responsive">
                                            <table class="table table-sub-clientes">
                                                <thead>
                                                    <tr>
                                                        <th class="col-2">Nome</th>
                                                        <th class="col-2">Chave</th>
                                                        <th class="col-4">Crédito</th>
                                                        <th class="col-4">Crédito Utilizado</th>
                                                        <th class="col-4">Nível</th>
                                                        <th class="col-4">Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="!loading">
                                                    <ng-container *ngFor="let cliente of client.sub_clientes; let i = index">
                                                        <tr class="linha-expandir" (click)="toggleExpansion(cliente, i, 'cliente')" [ngClass]="{'selected': isSelected(i, 'cliente')}">
                                                            <td>{{cliente.nome}}</td>
                                                            <td>{{cliente.chave}}</td>
                                                            <td>{{formaterCurrency(cliente.credito | currency:'BRL':true:'1.2-2')}}</td>
                                                            <td>{{formaterCurrency(cliente.credito_utilizado | currency:'BRL':true:'1.2-2')}}</td>
                                                            <td>{{ getTipoDisplay(cliente.tipo) }}</td>
                                                            <td><button type="button" title="Visualizar Cliente" class="btn btn-primary " [routerLink]="['/client', cliente.id]"> <i class="ti-eye"></i> </button></td>
                                                        </tr>
                                                        <tr class="linha-detalhes" [hidden]="!expandedSubClients['cliente'+cliente.id]">
                                                            <th class="linha-detalhes-titulos">Moeda</th>
                                                            <th class="linha-detalhes-titulos">Spread</th>
                                                            <th class="linha-detalhes-titulos">Spread Noturno</th>
                                                            <th class="linha-detalhes-titulos">Spread Intermediário</th>
                                                            <th class="linha-detalhes-titulos"></th>
                                                        </tr>
                                                        <ng-container *ngFor="let m of cliente.moedas; let last = last">
                                                            <tr class="linha-detalhes" [hidden]="!expandedSubClients['cliente'+cliente.id]" [ngClass]="{'ultima-linha': last}">
                                                                <td class="col-1">{{ m.nome }}</td>
                                                                <td class="col-1">{{ formaterSpread(m.spread, 2) }}</td>
                                                                <td class="col-1">{{ formaterSpread(m.spread_noturno, 2) }}</td>
                                                                <td class="col-1">{{ formaterSpread(m.spread_intermediario, 2) }}</td>
                                                                <td class="col-1"></td>
                                                            </tr>
                                                        </ng-container>
                                                        <ng-container *ngFor="let trader of cliente.sub_clientes; let j = index">
                                                            <tr class="linha-expandir" (click)="toggleExpansion(trader, j, 'subcliente')" [ngClass]="{'selected': isSelected(j, 'subcliente')}">
                                                                <td>{{trader.nome}}</td>
                                                                <td>{{trader.chave}}</td>
                                                                <td>{{formaterCurrency(trader.credito | currency:'BRL':true:'1.2-2')}}</td>
                                                                <td>{{formaterCurrency(trader.credito_utilizado | currency:'BRL':true:'1.2-2')}}</td>
                                                                <td>{{ getTipoDisplay(trader.tipo) }}</td>
                                                                <td><button type="button" title="Visualizar Cliente" class="btn btn-primary " [routerLink]="['/client', trader.id]"> <i class="ti-eye"></i> </button></td>
                                                            </tr>
                                                            <tr class="linha-detalhes" [hidden]="!expandedSubClients['subcliente'+trader.id]">
                                                                <th class="linha-detalhes-titulos">Moeda</th>
                                                                <th class="linha-detalhes-titulos">Spread</th>
                                                                <th class="linha-detalhes-titulos">Spread Noturno</th>
                                                                <th class="linha-detalhes-titulos">Spread Intermediário</th>
                                                                <th class="linha-detalhes-titulos"></th>
                                                            </tr>
                                                            <ng-container *ngFor="let m2 of trader.moedas; let last = last">
                                                                <tr class="linha-detalhes" [hidden]="!expandedSubClients['subcliente'+trader.id]" [ngClass]="{'ultima-linha': last}">
                                                                    <td class="col-1">{{ m2.nome }}</td>
                                                                    <td class="col-1">{{ formaterSpread(m2.spread, 2) }}</td>
                                                                    <td class="col-1">{{ formaterSpread(m2.spread_noturno, 2) }}</td>
                                                                    <td class="col-1">{{ formaterSpread(m2.spread_intermediario, 2) }}</td>
                                                                    <td class="col-1"></td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                                <tbody *ngIf="loading">
                                                    <div class="loading-table">
                                                        <img
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div class="card">
                                                <div class="card-body pag-custom">
                                                    <div class="col" *ngIf="total_pages.length > 0">
                                                        <div class="navigations-rows-shows">
                                                            <h2>Mostrando {{mostrando}} até
                                                                {{mostrando2}}
                                                                de {{paginas}} registros
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <nav aria-label="Page navigation">
                                                            <ul class="pagination" id="pagination_"></ul>
                                                        </nav>
                                                    </div>
                                                    <div class="col">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal centerModal fade" id="modalAddMoeda" tabindex="-1" role="dialog">
        <ng-container *ngTemplateOutlet="moedaModalTemplate; context: {
        modalTitle: 'Adicionar Moeda', 
        addMoeda: addMoeda, 
        saveAction: postMoeda, 
        closeAction: fecharModalAddMoeda,
        buttonText: 'Adicionar'
    }"></ng-container>
    </div>

    <ng-template #moedaModalTemplate let-modalTitle="modalTitle" let-addMoeda="addMoeda" let-saveAction="saveAction" let-closeAction="closeAction" let-buttonText="buttonText">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-sm">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="btn-close" (click)="closeAction()">
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="white_card card_height_100">
                        <div class="white_card_body">
                            <form>
                                <div class="row mb-3">
                                    <!-- ClienteGestor ou Cliente: Adicionando Moeda -->
                                    <div class="col-md-12" *ngIf="(isAddingMoeda && (currentUser && (currentUser.tipo === 'clienteGestor' || currentUser.tipo === 'cliente')))">
                                        <label class="form-label" for="moeda_nome">Nome da moeda</label>
                                        <select id="moeda_nome" class="form-select" [(ngModel)]="addMoeda.nome" (change)="onMoedaChange()" [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let moedaSelecionada of client.possiveis_moedas" [value]="moedaSelecionada.id">{{ moedaSelecionada.nome }}</option>
                                        </select>
                                    </div>

                                    <!-- ClienteGestor ou Cliente: Editando Moeda -->
                                    <div class="col-md-12" *ngIf="(!isAddingMoeda && (currentUser && (currentUser.tipo === 'clienteGestor' || currentUser.tipo === 'cliente')))">
                                        <label class="form-label" for="moeda_nome">Nome da moeda</label>
                                        <input type="text" class="form-control" id="moeda_nome" [value]="addMoeda.nome" readonly>
                                    </div>

                                    <!-- UsuarioAdmin: Adicionando ou Editando Moeda -->
                                    <div class="col-md-12" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
                                        <label class="form-label" for="moeda_nome">Nome da moeda</label>
                                        <input type="text" class="form-control" id="moeda_nome" placeholder="Nome da moeda" [(ngModel)]="addMoeda.nome" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6">
                                        <div class="form-check" style="margin-top: 35px;">
                                            <input class="form-check-input" type="checkbox" id="moeda_cotacao_por_volume" [(ngModel)]="addMoeda.cotacao_por_volume" [ngModelOptions]="{standalone: true}" [disabled]="fieldsDisabled" [checked]="addMoeda.cotacao_por_volume">
                                            <label class="form-label form-check-label" for="moeda_cotacao_por_volume">Cotação por volume</label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-check" style="margin-top: 35px;">
                                            <input class="form-check-input" type="checkbox" id="moeda_compra" [(ngModel)]="addMoeda.compra" [ngModelOptions]="{standalone: true}" [disabled]="fieldsDisabled" [checked]="addMoeda.compra">
                                            <label class="form-label form-check-label" for="moeda_compra">Cotação por quantidade</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label" for="moeda_minimo">Mínimo</label>
                                        <input type="text" class="form-control" id="moeda_minimo" placeholder="valor mínimo" [(ngModel)]="addMoeda.minimo" (input)="formatarMoedaBRL($event, 'minimo')" [ngModelOptions]="{standalone: true}" [disabled]="fieldsDisabled">
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label" for="moeda_maximo">Máximo</label>
                                        <input type="text" class="form-control" id="moeda_maximo" placeholder="valor máximo" [(ngModel)]="addMoeda.maximo" (input)="formatarMoedaBRL($event, 'maximo')" [ngModelOptions]="{standalone: true}" [disabled]="fieldsDisabled">
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label" for="moeda_multiplo">Múltiplo</label>
                                        <input type="text" class="form-control" id="moeda_multiplo" placeholder="múltiplo" [(ngModel)]="addMoeda.multiplo" [ngModelOptions]="{standalone: true}" [disabled]="fieldsDisabled">
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label" for="moeda_spread">Spread %</label>
                                        <input type="text" class="form-control" id="moeda_spread" placeholder="spread" [(ngModel)]="addMoeda.spread" (input)="formatAsPercent($event, 'spread')" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && currentUser.modo_noturno">
                                    <div class="col-md-6">
                                        <label class="form-label" for="moeda_spread_noturno">Spread Noturno %</label>
                                        <input type="text" class="form-control" id="moeda_spread_noturno" placeholder="spread noturno" [(ngModel)]="addMoeda.spread_noturno" (input)="formatAsPercent($event, 'spread_noturno')" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label" for="moeda_spread_intermediario">Spread Intermediário %</label>
                                        <input type="text" class="form-control" id="moeda_spread_intermediario" placeholder="spread Intermediário" [(ngModel)]="addMoeda.spread_intermediario" (input)="formatAsPercent($event, 'spread_intermediario')" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="closeAction()">Fechar</button>
                    <span>
                        <img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="saveAction(addMoeda)">{{
                            buttonText }}</button>
                    </span>
                </div>
            </div>
        </div>
    </ng-template>

    <div class="modal centerModal fade" id="modalAddMoeda" tabindex="-1" role="dialog">
        <ng-container *ngTemplateOutlet="moedaModalTemplate; context: {
        modalTitle: 'Adicionar Moeda', 
        addMoeda: addMoeda, 
        saveAction: postMoeda, 
        closeAction: fecharModalAddMoeda,
        buttonText: 'Adicionar'
    }"></ng-container>
    </div>

    <div class="modal centerModal fade" id="modalEditarMoeda" tabindex="-1" role="dialog">
        <ng-container *ngTemplateOutlet="moedaModalTemplate; context: {
        modalTitle: 'Editar Moeda', 
        addMoeda: addMoeda, 
        saveAction: putMoeda, 
        closeAction: fecharModalEditarMoeda,
        buttonText: 'Editar'
    }"></ng-container>
    </div>

    <div class="modal centerModal fade" id="modalAddTransacaoManual" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content modal-sm">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Adicionar Transação</h5>
                    <button type="button" class="btn-close" (click)="fecharModalAddTransacaoManual()">
                        <span aria-hidden="true"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="white_card card_height_100">
                        <div class="white_card_body">
                            <form>
                                <div class="row mb-3">
                                    <div class="col-md-12">
                                        <label class="form-label" for="transacao_valor">Valor</label>
                                        <input type="text" class="form-control" id="transacao_valor" placeholder="0,00" [(ngModel)]="addTransacao.valor" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <!-- <div class="col-md-6">
                                    <div class="form-check " style="margin-top: 35px;">
                                        <input class="form-check-input" type="checkbox"
                                            id="transacao_sensibilizar_credito"
                                            [(ngModel)]="addTransacao.transacao_sensibilizar_credito"
                                            [ngModelOptions]="{standalone: true}">
                                        <label class="form-label form-check-label" for="transacao_sensibilizar_credito">
                                            Sensibilizar Crédito
                                        </label>
                                    </div>
                                </div> -->
                                    <div class="col-md-6">
                                        <div class="form-check " style="margin-top: 35px;">
                                            <input class="form-check-input" type="radio" name="transacao_tipo" id="transacao_saque" value="saque" [(ngModel)]="addTransacao.transacao_tipo" [ngModelOptions]="{standalone: true}">
                                            <label class="form-label form-check-label" for="transacao_saque">
                                                Saque
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-check " style="margin-top: 35px;">
                                            <input class="form-check-input" type="radio" name="transacao_tipo" id="transacao_deposito" value="deposito" [(ngModel)]="addTransacao.transacao_tipo" [ngModelOptions]="{standalone: true}">
                                            <label class="form-label form-check-label" for="transacao_deposito">
                                                Depósito
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="fecharModalAddTransacaoManual()">Fechar</button>
                    <span>
                        <img *ngIf="loading"
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="postModalAddTransacaoManual(addTransacao)">Adicionar Transacao</button>
                    </span>
                </div>
            </div>
        </div>
    </div>