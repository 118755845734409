<div class="footer_part padding-footer-login">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="footer_iner text-center">
                        <div class="text-center p-4">
                          2023 © Web Cotação - v {{version}}
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>