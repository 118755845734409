<section class="main_content dashboard_part large_header_bg padding-login">

    <div class="main_content_iner ">
        <div class="container-fluid p-0">
            <div class="row justify-content-center">

                <div class="col-lg-12">
                    <div class="white_box mb_30" style="padding: 56px 103px;">
                        <div class="row ">
                            <div class="col-lg-6">

                                <div class="modal-content cs_modal">
                                    <div class="modal-header center-text" [ngStyle]="{'background-color': ambienteDev.corPrincipal}">
                                        <div class="header-login">
                                            <a class="large_logo"><img class="logo-expanded" [src]="ambienteDev.logo" alt=""></a>
                                            &nbsp;
                                            <h5 class="modal-title text_white">Web Cotação</h5>
                                        </div>
                                    </div>
                                    <div class="modal-body">
                                        <form (keyup.enter)="login()">
                                            <div class="">
                                                <input type="text" class="form-control" placeholder="Login" [(ngModel)]="client.usuario" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <div class="">
                                                <input type="password" class="form-control" placeholder="Senha" [(ngModel)]="client.senha" [ngModelOptions]="{standalone: true}">
                                            </div>
                                            <img *ngIf="loading"
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                            <a *ngIf="!loading" href="#" (click)="login()" class="btn_1 full_width text-center">Acessar </a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="footer_part padding-footer-login">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer_iner text-center">
                        <p>2023 © Web - cotação </p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>