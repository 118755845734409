<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Ordens</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Ordens</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card_body">
                    <div class="white_card mb_30 card_height_100">
                        <div class="white_card_header ">
                            <div class="box_header m-0">
                                <div class="main-title">
                                    <h3 class="m-0">Ordens</h3>
                                </div>
                                <button *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'" type="button" [disabled]="ordem_ids.length ==0" class="btn btn-primary mb-3 btn-add" (click)="modalCriarContrato()" title="Criar Contrato">
                                    Criar contrato
                                </button>
                            </div>
                        </div>
                        <div class="white_card_body pt-0">
                            <div class="QA_section">
                                <div class="row form-pesquisar">
                                    <div class="col-2 ">
                                        <label class="form-label" for="initialDate" style="white-space: nowrap;">Data inicial</label>
                                        <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-2">
                                        <label class="form-label" for="finalDate" style="white-space: nowrap;">Data final</label>
                                        <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-2 space-480p">
                                        <label class="form-label" for="inputState">Status</label>
                                        <select id="inputState" class="form-select" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                            <option selected="" value="0">Todos</option>
                                            <option value="pendente">Pendente</option>
                                            <option value="aprovado">Aprovado</option>
                                            <option value="rejeitado">Rejeitado</option>
                                            <option value="saldo_insuficiente">Saldo insuficiente</option>
                                            <option value="erro">Erro</option>
                                        </select>
                                    </div>
                                    <div class="col-2 space space-480p">
                                        <label class="form-label" for="inputNomeMoeda">Moedas</label>
                                        <select id="inputNomeMoeda" class="form-select" [(ngModel)]="nomeMoeda" [ngModelOptions]="{standalone: true}">
                                            <option selected="" value="0">Todas</option>
                                            <option *ngFor="let moeda of listItem.moedas_menu" [value]="moeda">{{moeda}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" class="col-2 space space-480p">
                                        <label class="form-label" for="nomeCliente" style="white-space: nowrap;">Nome do cliente</label>
                                        <input class="form-control" id="nomeCliente" type="text" placeholder="Nome do cliente" [(ngModel)]="nomeCliente">
                                    </div>
                                    <div class="col-2 space space-480p">
                                        <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary ">Buscar</button>
                                    </div>
                                </div>
                                <div *ngIf="currentUser && (currentUser.tipo === 'clienteGestor' || currentUser.tipo === 'cliente' || currentUser.tipo === 'subCliente')" style="margin-top:1rem;"></div>
                                <div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'" class="row">
                                    <div class="mb-3 form-check select-all-check">
                                        <input type="checkbox" class="form-check-input" id="selectAll" [(ngModel)]="allSelected" (change)="selectAllChecks(allSelected,$event)">
                                        <label class="form-label form-check-label" for="selectAll">
                                            Selecionar todos
                                        </label>
                                    </div>
                                </div>
                                <div class="QA_table mb_30 transaction-table order-history-table">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col"></th>
                                                    <th scope="col">Data</th>
                                                    <th scope="col">Val</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col">Status Topázio</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Moeda</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col">PreCoin</th>
                                                    <th scope="col">Cliente</th>
                                                    <th scope="col">Compra (R$)</th>
                                                    <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Venda (R$)</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col">Total (R$)</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="col">IOF (R$)</th>
                                                    <th scope="col">Pagar (R$)</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" scope="col">Receber (R$)</th>
                                                    <th *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" scope="col">Lucro (R$)</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!loading">
                                                <tr *ngFor="let item of listItem.rows">
                                                    <td class="max-width" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')" scope="row"><input type="checkbox" (change)="changeStatus(item,$event)" [checked]="item.checked" /></td>
                                                    <td class="max-width">{{item.data_registro?global_.formateDateAndTime(item.data_registro):'-'}}</td>
                                                    <td class="max-width align-to-r">{{item.validade}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')"><span class="badge" [ngClass]="item.status_topazio=='aprovado' ? 'bg-success' : 'bg-danger'">{{item.status_topazio}}</span></td>
                                                    <td class="max-width align-to-r"><span class="badge" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='aprovado','bg-warning': item.status=='pendente', 'bg-info': item.status=='saldo_insuficiente', 'bg-rose': item.status=='rejeitado' }">{{item.status}}</span></td>
                                                    <td class="max-width align-to-r">{{item.cotacao? item.cotacao.moeda : ''}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">{{global_.formaterCurrency(item.cotacao.cotacao | currency:'BRL':true:'1.4-4')}}</td>
                                                    <td class="max-width align-to-r">{{item.cotacao? item.cotacao.cliente_nome : ''}}</td>
                                                    <td class="max-width align-to-r">{{global_.formaterCurrency(item.cotacao.cotacao_compra | currency:'BRL':true:'1.2-4')}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="item.cotacao.cotacao_venda !== 0; else zeroValue"> {{global_.formaterCurrency(item.cotacao.cotacao_venda | currency:'BRL':true:'1.2-4') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                    <td class="max-width align-to-r">{{formatarNumeroQtd(item.cotacao.quantidade)}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">{{global_.formaterCurrency(item.cotacao.valor_total | currency:'BRL':true:'1.2-2')}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin')">{{global_.formaterCurrency(item.cotacao.iof | currency:'BRL':true:'1.2-2')}}</td>
                                                    <td class="max-width align-to-r">{{global_.formaterCurrency(item.cotacao.pagar | currency:'BRL':true:'1.2-2')}}</td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="item.cotacao.receber !== 0; else zeroValue"> {{ global_.formaterCurrency(item.cotacao.receber | currency:'BRL':true:'1.2-2') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                    <td class="max-width align-to-r" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')"> <ng-container *ngIf="item.cotacao.lucro !== 0; else zeroValue"> {{ global_.formaterCurrency(item.cotacao.lucro | currency:'BRL':true:'1.2-2') }} </ng-container> <ng-template #zeroValue>-</ng-template> </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="loading">
                                                <div class="loading-table">
                                                    <img
                                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </tbody>
                                            <tfoot *ngIf="currentUser && (currentUser.tipo === 'usuarioAdmin') && listItem.totais.total_qtd !== 0 && !loading">
                                                <th class="max-width" scope="row">Totais</th>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.valor_medio_compra | currency:'BRL':true:'1.2-4')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.valor_medio_venda | currency:'BRL':true:'1.2-4')}}</td>
                                                <td class="max-width align-to-r">{{ formatarNumeroQtd(listItem.totais.total_qtd) }}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_total | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_iof | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_pagar | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_receber | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_lucro | currency:'BRL':true:'1.2-2')}}</td>
                                            </tfoot>
                                            <tfoot *ngIf="currentUser && (currentUser.tipo === 'clienteGestor' || currentUser.tipo === 'cliente') && listItem.totais.total_qtd !== 0 && !loading">
                                                <th class="max-width" scope="row">Totais</th>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.valor_medio_compra | currency:'BRL':true:'1.2-4')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.valor_medio_venda | currency:'BRL':true:'1.2-4')}}</td>
                                                <td class="max-width align-to-r">{{ formatarNumeroQtd(listItem.totais.total_qtd) }}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_pagar | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_receber | currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_lucro | currency:'BRL':true:'1.2-2')}}</td>
                                            </tfoot>
                                            <tfoot *ngIf="currentUser && (currentUser.tipo === 'subCliente') && listItem.totais.total_qtd !== 0 && !loading">
                                                <th class="max-width" scope="row">Totais</th>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width"></td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.valor_medio_compra | currency:'BRL':true:'1.2-4')}}</td>
                                                <td class="max-width align-to-r">{{ formatarNumeroQtd(listItem.totais.total_qtd) }}</td>
                                                <td class="max-width align-to-r">{{global_.formaterCurrency(listItem.totais.total_pagar | currency:'BRL':true:'1.2-2')}}</td>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-body pag-custom">
                                            <div class="col" *ngIf="total_pages.length > 0">
                                                <div class="navigations-rows-shows">
                                                    <h2>Mostrando {{mostrando}} até {{mostrando2}} de {{paginas}} registros </h2>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <nav aria-label="Page navigation">
                                                    <ul class="pagination" id="pagination_"></ul>
                                                </nav>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal centerModal fade" id="cadastroContrato" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Criar contrato </h5>
                <button type="button" class="btn-close" (click)="fecharModalCriarContrato()"> <span aria-hidden="true"></span> </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_body">
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon1"><i class="ti-text"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Nome" aria-label="Nome" [(ngModel)]="contrato.nome" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">
                        </div>
                        <div class="input-group mb-3">
                            <h5>Cotações selecionadas</h5>
                        </div>
                        <div class="QA_section">
                            <div class="QA_table mb-0 transaction-table">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Data</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Moeda</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Cotação (R$)</th>
                                                <th scope="col">Quantidade</th>
                                                <th scope="col">Validade</th>
                                                <th scope="col">Total (R$)</th>
                                                <th scope="col">IOF (R$)</th>
                                                <th scope="col">Pagar (R$)</th>
                                                <th scope="col" style="text-align: right;">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of ordens">
                                                <td>{{item.data_registro?global_.formateDateAndTime(item.data_registro):'-'}}</td>
                                                <td><span class="badge" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='aprovado','bg-warning': item.status=='pendente', 'bg-info': item.status=='saldo_insuficiente', 'bg-rose': item.status=='rejeitado' }">{{item.status}}</span></td>
                                                <td>{{item.cotacao? item.cotacao.moeda : ''}}</td>
                                                <td>{{item.cotacao? item.cotacao.cliente_nome : ''}}</td>
                                                <td>{{global_.formaterCurrency(item.cotacao.cotacao_dolar | currency:'BRL':true:'1.2-4') }}</td>
                                                <td>{{global_.formaterCurrency(item.cotacao.quantidade | currency:'BRL':true:'1.2-2')}}</td>
                                                <td>{{item.validade}}</td>
                                                <td>{{global_.formaterCurrency(item.cotacao.valor_total | currency:'BRL':true:'1.2-2')}}</td>
                                                <td>{{global_.formaterCurrency(item.cotacao.iof | currency:'BRL':true:'1.2-2')}}</td>
                                                <td>{{global_.formaterCurrency(item.cotacao.pagar | currency:'BRL':true:'1.2-2')}}</td>
                                                <td style="text-align: right;"><button type="button" class="btn btn-danger" (click)="deletarDadoCotacao(item)" title="Deletar contrato"><i class="ti-trash"></i></button></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="5"></td>
                                                <td>{{global_.formaterCurrency(somaQuantidade | currency:'BRL':true:'1.2-2')}}</td>
                                                <td></td>
                                                <td>{{global_.formaterCurrency(somaTotal | currency:'BRL':true:'1.2-2')}}</td>
                                                <td>{{global_.formaterCurrency(somaIof | currency:'BRL':true:'1.2-2')}}</td>
                                                <td>{{global_.formaterCurrency(somaPagar | currency:'BRL':true:'1.2-2')}}</td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div class="form-group" *ngIf="ordens == null || ordens.length == 0" style="text-align: center;">
                                        <label class="form-label">Sem ordens para exibir</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loadingSave" class="btn btn-secondary" (click)="fecharModalCriarContrato()">Fechar</button>
                <span>
                    <img *ngIf="loadingSave"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loadingSave" type="button" [disabled]="ordens.length == 0" class="btn btn-primary" (click)="saveContrato()"> Salvar contrato </button>
                </span>
            </div>
        </div>
    </div>
</div>