// src\app\screens\client-details\client-details.component.ts
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
declare var $: any;
import * as moment from "moment";
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.css']
})
export class ClientsDetailsComponent implements OnInit, OnDestroy {
  loading: any = false;
  total_pages: any = [];
  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  client: any = {};
  clientId: any;
  abaSelected: any = 1;
  moedaSelecionada: any = {};
  confirmarSenha: any = "";
  seepassword: any = false;
  seepasswordCnf: any = false;
  finalDate: any = moment().format('YYYY-MM-DD');
  initialDate: any = moment().format('YYYY-MM-DD');
  initialDate_: any = moment().format('DD/MM/YYYY');
  finalDate_: any = moment().format('DD/MM/YYYY');
  addMoeda: any = {};
  addTransacao: any = {};
  transacoes: any = {};
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;
  avisoIntervaloDatas: boolean = false;
  carteiraId: any;
  currentUser: any = {};
  fieldsDisabled: boolean = false;
  private routeSub: Subscription | undefined;
  isAddingMoeda: boolean = false;

  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      console.log('currentUser res:', res);
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.clientId = params['uuid'];
      if (this.clientId) {
        this.abaSelected = 1;
        this.getClient(this.clientId);
        this.getTransacoes();
      }
    });

    setTimeout(() => {
      $('#initialDate').datepicker({ language: 'pt' });
      $('#finalDate').datepicker({ language: 'pt' });
    }, 1000);
  }

  ngOnDestroy(): void {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  expandedSubClients: { [id: string]: boolean } = {};
  selectedRows: { [id: string]: number[] } = { cliente: [], subcliente: [] };
  // toggleExpansion(subClient: any, index: number, type: string) {
  //   const key = `${type}${subClient.id}`;
  //   this.expandedSubClients[key] = !this.expandedSubClients[key];

  //   const selectedIndex = this.selectedRows[type].indexOf(index);
  //   if (selectedIndex === -1) {
  //     this.selectedRows[type].push(index); 
  //   } else {
  //     this.selectedRows[type].splice(selectedIndex, 1); // Remove da seleção
  //   }

  //   if (type === 'cliente' && this.expandedSubClients[key]) {
  //     this.abaSelected = 1; 
  //     this.router.navigate(['/client', subClient.id]).then(() => {
  //       window.location.reload();
  //     });
  //   }
  // }

  toggleExpansion(subClient: any, index: number, type: string) {
    const key = `${type}${subClient.id}`;
    this.expandedSubClients[key] = !this.expandedSubClients[key];
    
    const selectedIndex = this.selectedRows[type].indexOf(index);
    if (selectedIndex === -1) {
      this.selectedRows[type].push(index);
    } else {
      this.selectedRows[type].splice(selectedIndex, 1);
    }
  }

  isSelected(index: number, type: string): boolean {
    return this.selectedRows[type].indexOf(index) !== -1;
  }

  formaterCurrency(value: any) {
    return value;
  }

  formaterSpread(value: any, decimal: number) {
    return value + '%';
  }

  getClient = (id: any) => {
    this.loading = true;
    this.apiService.Get(`cliente/${id}`).then((res: any) => {
      console.log('getClient res:', res);
      this.loading = false;
      this.client = res;
      this.client.cliente_nome_ = res.nome;
      if (this.client.cliente_pai) {
        this.client.possiveis_moedas = this.client.cliente_pai.moedas;
      }
      setTimeout(() => {
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })

        $(function () {
          $('[data-toggle="popover"]').popover()
        })
      }, 1000);
    }).catch((err) => {
      this.tratarErro(err);
      this.reloadCurrentRoute();
    });
  }

  getTipoDisplay(tipo: string): string {
    switch (tipo) {
      case 'clienteGestor':
        return 'gestor';
      case 'cliente':
        return 'cliente';
      case 'subCliente':
        return 'trader';
      default:
        return tipo;
    }
  }

  getTransacoes(page: number = 1, limit: number = 10, id: any = this.clientId) {
    this.loading = true;
    let url = `carteira/transacoes?page=${page}&limit=${limit}&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&cliente_id=${id}`;

    this.apiService.Get(url).then((res: any) => {
      this.transacoes = res;
      this.carteiraId = res.carteira_id;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit, id);
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.tratarErro(err);
      this.reloadCurrentRoute();
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  toggleModoNoturno(event: any) {
    this.client.modo_noturno = event.target.checked;
  }

  toggleOperarComSaldo(event: any) {
    this.client.operar_com_credito = event.target.checked;
  }

  saveClient() {
    if (!this.client.nome) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher nome`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }
    if (!this.client.chave) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher chave`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }
    if (!this.client.documento) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher documento`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }
    if (this.client.senha) {
      if (!this.confirmarSenha) {
        var objAlert = {
          title: `Erro`,
          msg: `Favor preencher confirmar senha`,
          tipo: 2
        }
        this.global.showAlert(objAlert);
        return;
      }

      if (this.confirmarSenha != this.client.senha) {
        var objAlert = {
          title: `Erro`,
          msg: `Senhas não condizem`,
          tipo: 2
        }
        this.global.showAlert(objAlert);
        return;
      }
    }

    console.log('this. this.client.operar_com_credito:', this.client);
    var obj = {
      "chave": this.client.chave,
      "credito": parseFloat(this.client.credito),
      "credito_utilizado":parseFloat(this.client.credito_utilizado),
      "nome": this.client.nome,
      "senha": this.client.senha,
      "documento": this.client.documento,
      "operar_com_credito": !this.client.operar_com_credito,
      "modo_noturno": this.client.modo_noturno
    }
console.log('this.client:', this.client);
console.log('obj:', obj);
    if (!this.client.senha) {
      delete obj.senha;
    }
    if (this.currentUser.tipo !== 'usuarioAdmin' && !this.currentUser.modo_noturno){
      delete obj.modo_noturno;
    }

    if (obj.credito < 0) {
      var objAlert = {
        title: `Erro`,
        msg: `Crédito não deve ser um número negativo`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }

    if (obj.credito_utilizado < 0) {
      var objAlert = {
        title: `Erro`,
        msg: `Crédito utilizado não deve ser um número negativo`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }
    console.log('obj:', obj);

    this.putClient(obj);
  }

  putClient = (obj: any) => {
    this.loading = true;
    this.apiService.Put(`cliente/${this.client.id}`, obj).then((res: any) => {
      this.getClient(this.clientId);
      this.loading = false;
      this.confirmarSenha = "";
      this.reloadCurrentRoute();
    }).catch((err) => {
      this.tratarErro(err);
      this.reloadCurrentRoute();
    });
  }

  modalAddMoeda(moeda = null) {
    this.isAddingMoeda = true;
    console.log('modalAddMoeda moeda:', moeda);
    if (moeda) {
      var obj = Object.assign({}, moeda);
      this.addMoeda = obj;
    } else {
      this.addMoeda = {};
    }
    $("#modalAddMoeda").toggleClass("show");
    $("#modalAddMoeda").css("display", "block");
  }

  fecharModalAddMoeda() {
    $("#modalAddMoeda").toggleClass("show");
    $("#modalAddMoeda").css("display", "none");
  }

  modalEditarMoeda(moeda: any) {
    this.isAddingMoeda = false; 
    console.log('modalEditarMoeda2 moeda:', moeda);
    this.addMoeda.nome = moeda.nome;
    this.addMoeda.id = moeda.id;
    this.addMoeda.compra = moeda.compra;
    this.addMoeda.cotacao_por_volume = moeda.cotacao_por_volume;
    this.addMoeda.minimo = this.formatarComoMoeda(moeda.minimo);
    this.addMoeda.maximo = this.formatarComoMoeda(moeda.maximo);
    this.addMoeda.multiplo = moeda.multiplo;
    this.addMoeda.spread = moeda.spread ? this.formatarComoMoeda(moeda.spread) : 0;
    this.addMoeda.spread_noturno = moeda.spread_noturno ? this.formatarComoMoeda(moeda.spread_noturno) : 0;
    this.addMoeda.spread_intermediario = moeda.spread_intermediario ? this.formatarComoMoeda(moeda.spread_intermediario) : 0;
    this.fieldsDisabled = this.currentUser && (this.currentUser.tipo === 'clienteGestor' || this.currentUser.tipo === 'cliente');
    $("#modalEditarMoeda").toggleClass("show");
    $("#modalEditarMoeda").css("display", "block");
  }

  fecharModalEditarMoeda() {
    $("#modalEditarMoeda").toggleClass("show");
    $("#modalEditarMoeda").css("display", "none");
  }

  postMoeda = () => {
    if (this.addMoeda.nome == null) {
      this.global.exibirMensagemErro("Favor preencher o nome");
      return;
    }

    if (this.addMoeda.minimo == null) {
      this.global.exibirMensagemErro("Favor preencher o mínimo");
      return;
    }

    if (this.addMoeda.maximo == null) {
      this.global.exibirMensagemErro("Favor preencher o máximo");
      return;
    }

    if (this.addMoeda.multiplo == null) {
      this.global.exibirMensagemErro("Favor preencher o múltiplo");
      return;
    }

    if (this.addMoeda.spread == null) {
      this.global.exibirMensagemErro("Favor preencher o spread");
      return;
    }

    var objeto: any = {};

    if (this.currentUser && this.currentUser.tipo === 'usuarioAdmin' && this.currentUser.modo_noturno) {
      console.log('this.addMoeda:', this.addMoeda);
      if (this.addMoeda.spread_noturno == null) {
        this.global.exibirMensagemErro("Favor preencher o spread noturno");
        return;
      }

      if (this.addMoeda.spread_intermediario == null) {
        this.global.exibirMensagemErro("Favor preencher o spread intermediário");
        return;
      }

      objeto = {
        "cliente_id": this.clientId,
        "nome": this.addMoeda.nome,
        "compra": this.addMoeda.compra,
        "cotacao_por_volume": this.addMoeda.cotacao_por_volume,
        "minimo": this.addMoeda.minimo ? this.converterMoedaStringToFloat(this.addMoeda.minimo) : 0,
        "maximo": this.addMoeda.maximo ? this.converterMoedaStringToFloat(this.addMoeda.maximo) : 0,
        "multiplo": this.addMoeda.multiplo ? parseInt(this.addMoeda.multiplo) : 0,
        "spread": this.addMoeda.spread ? this.converterMoedaStringToFloat(this.addMoeda.spread) : 0,
        "spread_noturno": this.addMoeda.spread_noturno ? this.converterMoedaStringToFloat(this.addMoeda.spread_noturno) : 0,
        "spread_intermediario": this.addMoeda.spread_intermediario ? this.converterMoedaStringToFloat(this.addMoeda.spread_intermediario) : 0,
      };

      if (objeto.spread_noturno <= 0) {
        this.global.exibirMensagemErro("O spread noturno deve ser maior que zero");
        return;
      }

      if (objeto.spread_intermediario <= 0) {
        this.global.exibirMensagemErro("O spread intermediário deve ser maior que zero");
        return;
      }
    } else {
      objeto = {
        "cliente_id": this.clientId,
        "nome": this.addMoeda.nome,
        "compra": this.addMoeda.compra,
        "cotacao_por_volume": this.addMoeda.cotacao_por_volume,
        "minimo": this.addMoeda.minimo ? this.converterMoedaStringToFloat(this.addMoeda.minimo) : 0,
        "maximo": this.addMoeda.maximo ? this.converterMoedaStringToFloat(this.addMoeda.maximo) : 0,
        "multiplo": this.addMoeda.multiplo ? parseInt(this.addMoeda.multiplo) : 0,
        "spread": this.addMoeda.spread ? this.converterMoedaStringToFloat(this.addMoeda.spread) : 0,
      };

    }

    if (objeto.minimo <= 0) {
      this.global.exibirMensagemErro("O mínimo deve ser maior que zero");
      return;
    }

    if (objeto.maximo <= 0) {
      this.global.exibirMensagemErro("O máximo deve ser maior que zero");
      return;
    }

    if (objeto.maximo < objeto.minimo) {
      this.global.exibirMensagemErro("O máximo deve ser maior ou igual ao valor mínimo");
      return;
    }

    if (objeto.multiplo <= 0) {
      this.global.exibirMensagemErro("O múltiplo deve ser maior que zero");
      return;
    }

    if (objeto.spread <= 0) {
      this.global.exibirMensagemErro("O spread deve ser maior que zero");
      return;
    }

    if (this.addMoeda.nome_add) {
      objeto.nome = this.addMoeda.nome_add;
    }

    this.loading = true;
    this.apiService.Post(`moeda`, objeto).then((res: any) => {
      this.global.exibirMensagemSucesso("Moeda adicionada com sucesso!");
      this.fecharModalAddMoeda();
      this.atualizarListaMoedas();
      this.loading = false;
    }).catch((err) => {
      // this.tratarErro(err);
      // this.reloadCurrentRoute();
      this.tratarErro(err);
      this.atualizarListaMoedas();
      this.abaSelected = 2;
    });
  }

  putMoeda = (dadosAtualizados: any) => {
    if (dadosAtualizados.nome == null) {
      this.global.exibirMensagemErro("Favor preencher o nome");
      return;
    }

    if (dadosAtualizados.minimo == null) {
      this.global.exibirMensagemErro("Favor preencher o mínimo");
      return;
    }

    if (dadosAtualizados.maximo == null) {
      this.global.exibirMensagemErro("Favor preencher o máximo");
      return;
    }

    if (dadosAtualizados.multiplo == null) {
      this.global.exibirMensagemErro("Favor preencher o múltiplo");
      return;
    }

    if (dadosAtualizados.spread == null) {
      this.global.exibirMensagemErro("Favor preencher o spread");
      return;
    }

    var dadosAtualizados_ = { ...dadosAtualizados };

    dadosAtualizados_.minimo = this.converterMoedaStringToFloat(dadosAtualizados_.minimo);
    dadosAtualizados_.maximo = this.converterMoedaStringToFloat(dadosAtualizados_.maximo);
    dadosAtualizados_.spread = this.converterMoedaStringToFloat(dadosAtualizados_.spread);
    dadosAtualizados_.multiplo = parseInt(dadosAtualizados_.multiplo);

    if (this.currentUser && this.currentUser.tipo === 'usuarioAdmin' && this.currentUser.modo_noturno) {
      console.log('dadosAtualizados:', dadosAtualizados);
      if (dadosAtualizados.spread_noturno == null) {
        this.global.exibirMensagemErro("Favor preencher o spread noturno");
        return;
      }
      if (dadosAtualizados.spread_intermediario == null) {
        this.global.exibirMensagemErro("Favor preencher o spread intermediário");
        return;
      }

      dadosAtualizados_.spread_noturno = this.converterMoedaStringToFloat(dadosAtualizados_.spread_noturno);
      dadosAtualizados_.spread_intermediario = this.converterMoedaStringToFloat(dadosAtualizados_.spread_intermediario);

      if (dadosAtualizados_.spread_noturno <= 0) {
        this.global.exibirMensagemErro("O spread noturno deve ser maior que zero");
        return;
      }
      if (dadosAtualizados_.spread_intermediario <= 0) {
        this.global.exibirMensagemErro("O spread intermediario deve ser maior que zero");
        return;
      }
    }

    if (dadosAtualizados_.minimo <= 0) {
      this.global.exibirMensagemErro("O mínimo deve ser maior que zero");
      return;
    }

    if (dadosAtualizados_.maximo <= 0) {
      this.global.exibirMensagemErro("O máximo deve ser maior que zero");
      return;
    }

    if (dadosAtualizados_.maximo < dadosAtualizados_.minimo) {
      this.global.exibirMensagemErro("O máximo deve ser maior ou igual ao valor mínimo");
      return;
    }

    if (dadosAtualizados_.multiplo <= 0) {
      this.global.exibirMensagemErro("O múltiplo deve ser maior que zero");
      return;
    }

    if (dadosAtualizados_.spread <= 0) {
      this.global.exibirMensagemErro("O spread deve ser maior que zero");
      return;
    }

    this.apiService.Put(`moeda/${dadosAtualizados.id}`, dadosAtualizados_).then((res: any) => {
      this.global.exibirMensagemSucesso("Moeda editada com sucesso!");
      this.fecharModalEditarMoeda();
      this.atualizarListaMoedas();
      this.loading = false;
    }).catch((err) => {
      this.tratarErro(err);
      this.atualizarListaMoedas();
      // this.reloadCurrentRoute();
      // this.loading = false;
      this.abaSelected = 2;
    });
  }

  atualizarListaMoedas() {
    this.carregarMoedas();
    this.abaSelected = 2;
  }

  carregarMoedas() {
    this.getClient(this.clientId);
  }

  tratarErro(err: any) {
    this.loading = false;
    if (err.status == 401 || err.error.status == 401) {
      this.authService.logoutUser()
    } else {
      this.global.exibirMensagemErro(err.error.message);
    }
  }

  viewCarteira(client: any) {
    this.router.navigate(['/carteira', client.carteiras[0].id], {
      queryParams: { clientId: client.id, clientNome: client.nome }
    });
  }

  formatarMoedaBRL(event: any, campo: string) {
    let valor = event.target.value;

    if (/^\d{1,3}(\.\d{3})*,\d{2}$/.test(valor) || /^\d+,\d{2}$/.test(valor)) {
      this.addMoeda[campo] = valor;
      return; // Se estiver formatado já, não faz nada
    }

    // Trata valores decimais menores que 1
    if (!isNaN(valor) && parseFloat(valor) < 1) {
      valor = "0" + valor;
    }

    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/(\d)(\d{2})$/, "$1,$2");
    valor = valor.replace(/(?=(\d{3})+(\D))\B/g, ".");

    this.addMoeda[campo] = valor;
    event.target.value = valor;
  }

  formatAsPercent(e: any, inputName: string) {
    let input = e.target.value;
    input = input.replace(/[^0-9.]/g, '');

    if (input.length >= 4 && !input.includes('.')) {
      input = input.slice(0, 3) + '.' + input.slice(3);
    }

    if (input.includes('.')) {
      let parts = input.split('.');
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
      }
      input = parts[0] + '.' + parts[1];
    }

    e.target.value = input;
    this.addMoeda[inputName] = input;
    e.target.setSelectionRange(input.length, input.length);
  }

  formatAsPercent2(event: any) {
    let value = event.target.value;
    let valueCleaned = value.replace(/[^\d]/g, '');

    if (valueCleaned.length <= 2) {
      value = valueCleaned;
    } else {
      let intPart = valueCleaned.slice(0, -2);
      let decimalPart = valueCleaned.slice(-2);
      value = `${intPart}.${decimalPart}`;
    }

    // Atualiza o valor no modelo
    this.addMoeda.spread = parseFloat(value).toFixed(2);

    // Mantém o cursor na última posição
    event.target.value = this.addMoeda.spread;
    event.target.setSelectionRange(event.target.value.length, event.target.value.length);
  }

  formatarComoMoeda(valor: number | string): string {
    if (typeof valor === 'number') {
      // Converter para string e tratar a parte decimal
      valor = valor.toFixed(2);
    }

    // Separa parte inteira e decimal
    let partes = valor.split('.');
    let parteInteira = partes[0];
    let parteDecimal = partes.length > 1 ? partes[1] : '00';

    // Se a parte decimal for menor que 2 caracteres
    if (parteDecimal.length === 1) {
      parteDecimal += '0';
    }

    // Adiciona separadores de milhar
    parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Junta parte inteira e decimal com vírgula
    return parteInteira + ',' + parteDecimal;
  }

  converterMoedaStringToFloat(valorMoeda: any) {
    if (typeof valorMoeda !== 'string') {
      valorMoeda = String(valorMoeda);
    }
    let valorFormatado = valorMoeda.replace(/\.(?=\d{3}(\D|$))/g, "");
    valorFormatado = valorFormatado.replace(/\,/g, ".");
    return parseFloat(valorFormatado);
  }

  initPagination(totalPages: number, page: number, limit: number, id?: any) {
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((this.ultimaPagina !== page)) {
              this.ultimaPagina = page;
              this.avisoIntervaloDatas = false;
              that.getTransacoes(page, limit, id);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  modalAddTransacaoManual(transacao = null) {
    if (transacao) {
      var obj = Object.assign({}, transacao);
      this.addTransacao = obj;
    } else {
      this.addTransacao = {};
    }
    $("#modalAddTransacaoManual").toggleClass("show");
    $("#modalAddTransacaoManual").css("display", "block");
  }

  fecharModalAddTransacaoManual() {
    $("#modalAddTransacaoManual").toggleClass("show");
    $("#modalAddTransacaoManual").css("display", "none");
  }

  postModalAddTransacaoManual(addTransacao: any) {
    if (this.addTransacao.valor == null || this.addTransacao.valor == "") {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher o valor`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }

    if (this.addTransacao.transacao_tipo == null || this.addTransacao.transacao_tipo == "") {
      var objAlert = {
        title: `Erro`,
        msg: `Favor escolher o tipo de transação: saque ou depósito`,
        tipo: 2
      }
      this.global.showAlert(objAlert);
      return;
    }

    var objeto = {
      "valor": this.addTransacao.valor,
      // "sensibilizar_credito": this.addTransacao.transacao_sensibilizar_credito,
      "sensibilizar_credito": false,
      "acao": this.addTransacao.transacao_tipo,
    }

    this.loading = true;
    this.apiService.Post(`carteira/${this.carteiraId}`, objeto).then((res: any) => {
      this.loading = false;
      this.fecharModalAddTransacaoManual();
      this.atualizarListaCarteira()
    }).catch((err) => {
      this.tratarErro(err);
      this.reloadCurrentRoute();
    });
  }

  atualizarListaCarteira() {
    this.carregarTransacoes();
    this.abaSelected = 3;
  }

  carregarTransacoes() {
    this.getTransacoes();
  }

  pesquisarTransacoes(page: number = 1) {
    if ($("#initialDate").val() && $("#finalDate").val()) {
      var dataInicial = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');
      var dataFinal = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');
      this.initialDate = dataInicial;
      this.finalDate = dataFinal;
      this.getTransacoes();
    }
  }

  deletarMoeda(moeda: any) {
    this.loading = true;
    this.apiService.Delete(`moeda/${moeda.id}`).then((res: any) => {
      this.loading = false;
      this.atualizarListaMoedas();
    }).catch((err) => {
      this.tratarErro(err);
      this.reloadCurrentRoute();
    });
  }
  onMoedaChange() {
    const moedaSelecionada = this.client.possiveis_moedas.find((moeda: any) => moeda.id === this.addMoeda.nome);
    if (moedaSelecionada) {
      this.addMoeda.nome_add = moedaSelecionada.nome;
      this.addMoeda.cotacao_por_volume = moedaSelecionada.cotacao_por_volume;
      this.addMoeda.compra = moedaSelecionada.compra;
      this.addMoeda.minimo = moedaSelecionada.minimo;
      this.addMoeda.maximo = moedaSelecionada.maximo;
      this.addMoeda.multiplo = moedaSelecionada.multiplo;
      this.fieldsDisabled = this.currentUser && (this.currentUser.tipo === 'clienteGestor' || this.currentUser.tipo === 'cliente');
    }
  }
}
