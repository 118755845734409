import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
import * as moment from "moment";
declare var $: any;

@Component({
  selector: 'app-night-orders',
  templateUrl: './night-orders.component.html',
  styleUrls: ['./night-orders.component.css']
})
export class NightOrdersComponent implements OnInit {
  currentUser: any = {};
  loading: any = false;
  total_pages: any = [];
  total_pages_aux: any = [];
  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');
  listItem: any = [];
  initialDate_: string = moment().format('DD/MM/YYYY');
  finalDate_: string = moment().format('DD/MM/YYYY');
  initialDate: string = moment().format('YYYY-MM-DD');
  finalDate: string = moment().format('YYYY-MM-DD');
  ordemInfo: any = {};
  ordem_ids: any = [];
  status: any = "aprovado";
  nomeMoeda: any = "0";
  global_: any;
  ordens: any = [];
  allSelected: any = false;
  somaTotal: any = 0;
  somaIof: any = 0;
  somaPagar: any = 0;
  somaQuantidade: any = 0;

  nomeCliente: string = "";

  ultimosParametros: any = {};
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;


  constructor(private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global,) {
    this.global_ = global;
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('#initialDate').datepicker({ language: 'pt' });
      $('#finalDate').datepicker({ language: 'pt' });
    }, 1000);
    this.getOrders();
    // $("#modalEditarOrdem").toggleClass("show");
    // $("#modalEditarOrdem").css("display", "block");
  }

  pesquisar(page: number = 1) {
    if ($("#initialDate").val() && $("#finalDate").val()) {
      var dataInicial = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');
      var dataFinal = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');
      this.initialDate = dataInicial;
      this.finalDate = dataFinal;
      this.resetPagination();
      this.getOrders(page);
    }
  }

  getOrders(page: number = 1, limit: number = 50) {
    this.loading = true;
    // this.initialDate = "2021-01-01";
    let url = `ordem?page=${page}&limit=${limit}&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&tipo=noturno`;
    if (this.nomeCliente) {
      url += `&nome_cliente=${this.nomeCliente}`
    }

    if (this.status != "0") {
      url += `&status=${this.status}`;
    }

    if (this.nomeMoeda != "0") {
      url += `&nome_moeda=${this.nomeMoeda}`;
    }

    this.apiService.Get(url).then((res: any) => {
      this.loading = false;
      this.listItem = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit);
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  detalharOrdem(ordem: any) {
    this.ordemInfo = { ...ordem };
    $("#modalEditarOrdem").toggleClass("show");
    $("#modalEditarOrdem").css("display", "block");
  }

  fecharModalEditarOrdem() {
    $("#modalEditarOrdem").toggleClass("show");
    $("#modalEditarOrdem").css("display", "none");
  }

  putModalEditarOrdem() {
    this.loading = true;
    this.apiService.Put(`ordem/${this.ordemInfo.id}`, this.ordemInfo).then((res: any) => {
      this.loading = false;
      this.fecharModalEditarOrdem();
      this.reloadCurrentRoute();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  openLink(hash: string) {
    window.open(`https://tronscan.org/#/transaction/${hash}`, '_blank');
  }
  
  initPagination(totalPages: number, page: number, limit: number, id?: any) {
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((this.ultimaPagina !== page)) {
              this.ultimaPagina = page;
              that.getOrders(page, limit);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  formatarNumeroQtd(numero: number, locale: string = 'pt-BR'): string {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(numero);
  }
}