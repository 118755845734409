export const environment = {
  production: true,
  urlApi: "https://dev-api-admin-cotacao.revocapital.com.br/api/",
  // urlApi: "http://localhost:5321/api/",
  logo: "./assets/img/logo.png",
  icon: "./assets/img/mini_logo.png",
  corPrincipal: "#0f1a34",
  corSecundaria: "#fff",
  dev: true,
  version:"0.77"
};