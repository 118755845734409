<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Perfil</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Perfil</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card_body">
                    <div class="white_card mb_30 card_height_100">
                        <div class="white_card_header ">
                            <div class="box_header m-0">
                                <div class="main-title"> <h3 class="m-0">Dados do Perfil</h3> </div>
                            </div>
                        </div>
                        <div class="white_card_body pt-0">
                            <div class="QA_section">
                                <div class="row">
                                    <div class="row align-items-center">
                                        <!-- <div class="col-auto">
                                            <label class="form-label" for="inputState">Data inicial</label>
                                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label" for="inputState">Data final</label>
                                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label" for="inputState">Status</label>
                                            <select id="inputState" class="form-select" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                                                <option selected="" value="0">Todos</option>
                                                <option value="pendente">Pendente</option>
                                                <option value="aprovado">Aprovado</option>
                                                <option value="rejeitado">Rejeitado</option>
                                                <option value="saldo_insuficiente">Saldo insuficiente</option>
                                                <option value="erro">Erro</option>
                                            </select>
                                        </div>
                                        <div *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" class="col-auto">
                                            <label class="form-label" for="nomeCliente">Nome do cliente</label>
                                            <input class="form-control" id="nomeCliente" type="text" placeholder="Nome do cliente" [(ngModel)]="nomeCliente">
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary ">Buscar</button>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="white_card card_height_100 mb_30">
                                        <div class="white_card_body">
                                            <div class="card-body" *ngIf="!loading">
                                                <form style="padding: 0 80px;">
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label class="form-label" for="nome">Nome</label>
                                                            <input type="text" class="form-control" id="nome" [(ngModel)]="client.nome" [ngModelOptions]="{standalone: true}" placeholder="Nome" disabled>
                                                        </div>
                                                        <div class="col">
                                                            <label class="form-label" for="chave">Chave</label>
                                                            <input type="text" class="form-control" id="chave" [(ngModel)]="client.chave" [ngModelOptions]="{standalone: true}" placeholder="chave" disabled>
                                                        </div>
                                                        <div class="col">
                                                            <label class="form-label" for="documento">Documento</label>
                                                            <input type="text" class="form-control" id="documento" [(ngModel)]="client.documento" [ngModelOptions]="{standalone: true}" placeholder="Documento" disabled>
                                                        </div>
                                                        <!-- <div class="col">
                                                            <div class="form-check " style="margin-top: 35px;">
                                                                <input class="form-check-input" type="checkbox" id="ativo" [(ngModel)]="client.ativo" [ngModelOptions]="{standalone: true}">
                                                                <label class="form-label form-check-label" for="ativo">
                                                                    Ativo
                                                                </label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label class="form-label" for="cliente_credito">Crédito</label>
                                                            <input type="text" class="form-control" id="cliente_credito" style="text-align: left !important;" [(ngModel)]="client.credito" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}" placeholder="" disabled>
                                                        </div>
                                                        <div class="col">
                                                            <label class="form-label" for="cliente_credito_utilizado">Crédito
                                                                utilizado</label>
                                                            <input type="text" class="form-control" id="cliente_credito_utilizado" style="text-align: left !important;" [(ngModel)]="client.credito_utilizado" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}" placeholder="" disabled>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col">
                                                            <label class="form-label" for="cliente_nome">Senha</label>
                                                            <div class="input-group mb-3">
                                                                <input [type]="seepassword? 'text' : 'password'" class="form-control" placeholder="Senha" aria-label="Senha" [(ngModel)]="client.senha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                                                                <div class="input-group-text btn-eye" (click)="seepassword = !seepassword">
                                                                    <span class="" id="basic-addon2">
                                                                        <i class="ti-eye"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <label class="form-label" for="cliente_nome">Confirmar Senha</label>
                                                            <div class="input-group mb-3">
                                                                <input [type]="seepasswordCnf? 'text' : 'password'" class="form-control" placeholder="Confirmar Senha" aria-label="Senha" [(ngModel)]="confirmarSenha" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
                                                                <div class="input-group-text btn-eye" (click)="seepasswordCnf = !seepasswordCnf">
                                                                    <span class="" id="basic-addon2">
                                                                        <i class="ti-eye"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="center-btn">
                                                        <button type="submit" class="btn btn-primary" (click)="saveClient()">Alterar dados</button>
                                                    </div>
                                                </form>
                                            </div>
                                            <img *ngIf="loading"
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

