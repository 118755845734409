import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import * as moment from "moment";
declare var ApexCharts: any;
import { Global } from 'src/app/global';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
import { interval, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  currentUser: any = {};
  finalDate: any = moment().format('YYYY-MM-DD');
  initialDate: any = moment().format('YYYY-MM-DD');
  finalDate_: any = moment().format('DD/MM/YYYY');
  initialDate_: any = moment().format('DD/MM/YYYY');
  loading: any = false;
  dashboard: any = {};
  global_: any;
  isFormEnabled: boolean = true;
  isFormContagemEnabled: boolean = false;
  moedasDigitais: any = {}
  comprarClienteSelecionado: any = "0";
  comprarMoedaDigital: any = "0";
  comprarCom: any = "0";
  comprarQuantidade: number = 0;
  comprarMoedaCotada: any = {};
  cotarMoedaComprar: any = {};
  tempoRestante: number = 10;
  private intervalo: any;
  creditoDisponivel: any = 0;
  creditoUtilizado: any = 0;
  clienteSelecionado: any = null;
  flgModoNoturno: boolean = false;
  mostrarMoedaDigital: boolean = false;
  private subscriptions: Subscription[] = [];

  private tentativasDeCotacao: number = 0;
  private maximoTentativas: number = 10;  

  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
  ) {
    this.verificarHorario();
    this.global_ = global;
    this.cotarMoedaComprar.validade = null;
    this.cotarMoedaComprar.comprar_com = 'BRL';
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.pesquisar();
      setTimeout(() => {
        $('#initialDate').datepicker({ language: 'pt' });
        $('#finalDate').datepicker({ language: 'pt' });
      }, 1000);
    }, 1000);

    const horarioSubscription = interval(60000)  // Verifica a cada minuto
      .pipe(startWith(0))
      .subscribe(() => {
        this.verificarHorario();
      });

    this.subscriptions.push(horarioSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.intervalo) {
      clearInterval(this.intervalo);
    }
  }

  loadCharts = async () => {
    var options;
    var chart;

    if ($('#monitor_2').length > 0) {
      options = {
        series: [{
          data: [24, 62, 42, 84, 63, 25, 65, 50]
        }],
        chart: {
          type: "bar",
          width: 35,
          height: 20,
          sparkline: {
            enabled: !0
          }
        },
        colors: ["#2C83B6"],
        tooltip: {
          fixed: {
            enabled: !1
          },
          x: {
            show: !1
          },
          y: {
            title: {
              formatter: function (e: any) {
                return ""
              }
            }
          },
          marker: {
            show: 0
          }
        }
      };
      (chart = new ApexCharts(document.querySelector("#monitor_2"), options)).render();
    }
    if ($('#monitor_3').length > 0) {
      options = {
        series: [{
          data: [24, 62, 42, 84, 63, 25, 65, 50]
        }],
        chart: {
          type: "bar",
          width: 35,
          height: 20,
          sparkline: {
            enabled: !0
          }
        },
        colors: ["#F46A44"],
        tooltip: {
          fixed: {
            enabled: !1
          },
          x: {
            show: !1
          },
          y: {
            title: {
              formatter: function (e: any) {
                return ""
              }
            }
          },
          marker: {
            show: 0
          }
        }
      };
      (chart = new ApexCharts(document.querySelector("#monitor_3"), options)).render();
    }
    if ($('#monitor_5').length > 0) {
      options = {
        series: [{
          data: [24, 62, 42, 84, 63, 25, 65, 50]
        }],
        chart: {
          type: "bar",
          width: 35,
          height: 20,
          sparkline: {
            enabled: !0
          }
        },
        colors: ["#15ed36"],
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function (e: any) {
                return ""
              }
            }
          },
          marker: {
            show: 0
          }
        }
      };
      (chart = new ApexCharts(document.querySelector("#monitor_5"), options)).render();
    }
  }

  pesquisar() {
    if ($("#initialDate").val() && $("#finalDate").val()) {
      var dTi = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
      var dTF = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
      this.initialDate = dTi;
      this.finalDate = dTF;
      this.getDashboard();
    }
  }

  getDashboard() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    var url = `dashboard?data_inicio=${this.initialDate}&data_fim=${this.finalDate}`;
    this.apiService.Get(url).then((res: any) => {
      this.dashboard = res;
      this.loading = false;

      const selectedClienteId = localStorage.getItem('selectedClienteId');
      if (selectedClienteId) {
        this.setClienteSelecionado(selectedClienteId);
        localStorage.removeItem('selectedClienteId');
      } else {
        this.setClienteSelecionado();
      }
      this.loadCharts();
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  setClienteSelecionado(idClienteSelecionar: any = 0) {
    if (this.dashboard.lista_clientes && this.dashboard.lista_clientes.length === 1) { // tela do trader com apenas um cliente (ele mesmo)
      this.cotarMoedaComprar.cliente_id = this.dashboard.lista_clientes[0].id;
      this.carregarMoedasDigitais();
      this.clienteSelecionado = this.dashboard.lista_clientes.find((cliente: { id: any; }) => cliente.id === this.cotarMoedaComprar.cliente_id);
      this.verificarHorario();
      this.mostrarMoedaDigital = true;
    }
    if (idClienteSelecionar != 0) {
      this.cotarMoedaComprar.cliente_id = idClienteSelecionar;
      this.carregarMoedasDigitais();
      this.clienteSelecionado = this.dashboard.lista_clientes.find((cliente: { id: any; }) => cliente.id === this.cotarMoedaComprar.cliente_id);
      this.verificarHorario();
    }
  }

  onClienteChange() {
    // this.atualizarEstadoFormulario(false);
    if (this.cotarMoedaComprar.cliente_id !== "0") {
      this.carregarMoedasDigitais();
      this.clienteSelecionado = this.dashboard.lista_clientes.find((cliente: { id: any; }) => cliente.id === this.cotarMoedaComprar.cliente_id);
      this.verificarHorario();
      this.mostrarMoedaDigital = true;
    } else {
      this.moedasDigitais = [];
      this.cotarMoedaComprar.moeda_id = null;
      this.clienteSelecionado = null;
      // this.atualizarEstadoFormulario(false);
      this.mostrarMoedaDigital = false;
    }
  }

  carregarMoedasDigitais() {
    const clienteSelecionado = this.dashboard.lista_clientes.find((cliente: { id: any; }) => cliente.id === this.cotarMoedaComprar.cliente_id);
    if (clienteSelecionado) {
      let moedas = [...clienteSelecionado.moedas];
      const usdtIndex = moedas.findIndex(m => m.nome === 'USDT');

      if (usdtIndex > -1) {
        const [usdtMoeda] = moedas.splice(usdtIndex, 1);
        this.moedasDigitais = [usdtMoeda, ...moedas];
      } else {
        this.moedasDigitais = moedas;
      }

      if (this.moedasDigitais && this.moedasDigitais.length > 0) {
        this.cotarMoedaComprar.moeda_id = this.moedasDigitais[0].id;
      } else {
        this.cotarMoedaComprar.moeda_id = null;
      }
    }
  }

  onlyNumberKey(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  cotarPreco() {
    let valor = this.cotarMoedaComprar.quantidade;
    if ((this.cotarMoedaComprar.cliente_id == "0") || (this.cotarMoedaComprar.cliente_id == null)) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor informar o cliente`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (this.cotarMoedaComprar.validade == null) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor informar a validade para cotar moeda`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!('quantidade' in this.cotarMoedaComprar)) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor informar a quantidade para cotar moeda`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    var obj_ = {
      "cliente_id": this.cotarMoedaComprar.cliente_id,
      "moeda_id": this.cotarMoedaComprar.moeda_id,
      "quantidade": +parseInt(valor.replace(/\./g, ''), 10),
      "validade": this.cotarMoedaComprar.validade
    }
    this.postCotarMoeda(obj_);
  }

  postCotarMoeda = (obj: any) => {
    this.loading = true;
    this.apiService.Post(`cotar-moeda`, obj).then((res: any) => {
      this.loading = false;
      this.comprarMoedaCotada = res;
      $("#cotarPrecoTela2").addClass("show");
      $("#cotarPrecoTela2").css("display", "block");
      $("#cotarPrecoTela1").removeClass("show");
      $("#cotarPrecoTela1").css("display", "none");
      this.iniciarContagem();
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  reloadCurrentRoute(idClienteSelecionar: any = 0) {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  confirmarCompra() {
    this.isFormContagemEnabled = false
    this.loading = true;
    if (this.intervalo) {
      clearInterval(this.intervalo);
    }
    this.tentativasDeCotacao = 0;  // Zera a contagem de tentativas ao confirmar compra
    var msgAguarde = {
      title: `Aguarde`,
      msg: `Aguardando a confirmação da compra!`,
      tipo: 4,
      loading: true
    }
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: msgAguarde
    });
    var obj_ = {
      "cliente_id": this.comprarMoedaCotada.CotarMoedaDados.cliente_id,
      "cotacao_id": this.comprarMoedaCotada.ID,
      "data_liquidacao_interna": this.comprarMoedaCotada.Validade,
      "data_liquidacao_externa": this.comprarMoedaCotada.Validade,
      "total_da_api": this.comprarMoedaCotada.Total,
      "quantidade": this.comprarMoedaCotada.CotarMoedaDados.quantidade
    }
    this.postConfirmarCompra(obj_, dialogRef);
  }

  postConfirmarCompra = (obj: any, dialogRefAnterior: any) => {
    this.apiService.Post(`ordem`, obj).then((res: any) => {
      this.loading = false;
      localStorage.setItem('selectedClienteId', obj.cliente_id);
      dialogRefAnterior.close();
      var objAlert = {
        title: `Sucesso`,
        msg: "confirmado com sucesso!",
        tipo: 1
      }
      const dialogRef = this.dialog.open(ModalAlertComponent, {
        width: 'auto',
        data: objAlert
      });
      dialogRef.afterClosed().subscribe(result => {
        this.reloadCurrentRoute(obj.cliente_id);
      });
    }).catch((err) => {
      this.loading = false;
      dialogRefAnterior.close();
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        const dialogRef = this.dialog.open(ModalAlertComponent, {
          width: 'auto',
          data: objAlert
        });
        dialogRef.afterClosed().subscribe(result => {
          this.isFormContagemEnabled = false;
          this.postCotarMoeda(this.comprarMoedaCotada.CotarMoedaDados);
          this.tempoRestante = 10;
        });
      }
    });
  }

  // iniciarContagem() {
  //   if (this.intervalo) {
  //     clearInterval(this.intervalo);
  //   }
  //   this.intervalo = setInterval(() => {
  //     if (this.tempoRestante > 0) {
  //       this.isFormContagemEnabled = true;
  //       this.tempoRestante--;
  //     } else {
  //       this.isFormContagemEnabled = false;
  //       this.postCotarMoeda(this.comprarMoedaCotada.CotarMoedaDados);
  //       this.tempoRestante = 10;
  //     }
  //   }, 1000);
  // }


  iniciarContagem() {
    if (this.intervalo) {
      clearInterval(this.intervalo);
    }
    this.intervalo = setInterval(() => {
      if (this.tempoRestante > 0) {
        this.isFormContagemEnabled = true;
        this.tempoRestante--;
      } else {
        this.isFormContagemEnabled = false;
        if (this.tentativasDeCotacao < this.maximoTentativas) {
          this.postCotarMoeda(this.comprarMoedaCotada.CotarMoedaDados);
          this.tempoRestante = 10;
          this.tentativasDeCotacao++;  // Incrementando a contagem de tentativas
        } else {
          this.retornarCotarMoeda();  // Interrompe o ciclo após atingir o máximo de tentativas
        }
      }
    }, 1000);
  }

  retornarCotarMoeda() {
    if (this.intervalo) {
      clearInterval(this.intervalo);
      this.isFormContagemEnabled = false;
      this.tempoRestante = 10;
      this.tentativasDeCotacao = 0;  // Zera a contagem de tentativas ao retornar manualmente
    }

    $("#cotarPrecoTela2").removeClass("show");
    $("#cotarPrecoTela2").css("display", "none");
    $("#cotarPrecoTela1").addClass("show");
    $("#cotarPrecoTela1").css("display", "block");

  }

  getProgresso() {
    return ((this.tempoRestante - 1) / 10) * 100;
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  formatarNumeroQtd(event: any) {
    let valor = event.target.value;
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

    this.cotarMoedaComprar.quantidade = valor;
    event.target.value = valor;
  }

  verificarHorario() {
    const now = new Date();
    const utcHora = now.getUTCHours();
    const utcMinutos = now.getUTCMinutes();
    const brasilHora = utcHora - 3;

    const horaCorrigida = brasilHora < 0 ? brasilHora + 24 : brasilHora;

    const inicioPermitido = { hora: 9, minutos: 5 };
    const fimPermitido = { hora: 16, minutos: 35 };

    let permitirCotacao = false;
    if (horaCorrigida > inicioPermitido.hora || (horaCorrigida === inicioPermitido.hora && utcMinutos >= inicioPermitido.minutos)) {
      if (horaCorrigida < fimPermitido.hora || (horaCorrigida === fimPermitido.hora && utcMinutos <= fimPermitido.minutos)) {
        permitirCotacao = true;
      }
    }
    // this.atualizarEstadoFormulario(permitirCotacao);
  }

  // atualizarEstadoFormulario(permitirCotacaoHorario: boolean) {
  //   if (this.moedasDigitais && this.moedasDigitais.length > 0) {
  //     if (permitirCotacaoHorario && this.cotarMoedaComprar.cliente_id && this.cotarMoedaComprar.cliente_id !== "0" && !this.isFormContagemEnabled) {
  //       this.isFormEnabled = true;
  //     } else {
  //       this.isFormEnabled = false;
  //       if (this.currentUser.modo_noturno) {
  //         this.getModoNoturno();
  //         if ((this.isClienteIdValid(this.cotarMoedaComprar.cliente_id) && !this.isFormContagemEnabled)) {
  //           this.isFormEnabled = true;
  //         } else {
  //           this.isFormEnabled = false;
  //         }
  //       }
  //     }
  //   }
  // }

  isClienteIdValid(clienteId: any): boolean {
    return clienteId && clienteId !== "0";
  }

  getModoNoturno() {
    this.loading = true;
    var url = `modo-noturno`;
    this.apiService.Get(url).then((res: any) => {
      this.flgModoNoturno = res.flg_ativar_modo_noturno === "1" ? true : false;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

}
