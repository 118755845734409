import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
import { MatDialog } from '@angular/material';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isAlertOpen: boolean = false;
  preventLogin: boolean = false;
  client: any = {};
  loading: any = false;
  locationJson: any = {};
  locationSelected: any = 'Português';
  ambienteDev:any = environment;
  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog

  ) {

  }

  ngOnInit(): void {
  }

  login() {
    if (this.isAlertOpen || this.preventLogin) {
      return;
  }
    if (this.loading) {
      return;
    }
    if (!this.client.usuario) {
      this.isAlertOpen = true;
      var objAlert = {
        title: `Erro`,
        msg: 'Favor preencher o login',
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!this.client.senha) {
      this.isAlertOpen = true;
      var objAlert = {
        title: `Erro`,
        msg: 'Favor preencher a senha',
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    this.authUser(this.client);
  }

  authUser(user: any) {
    this.loading = true;
    this.apiService.PostPublic('login', user).then((res: any) => {
      if (this.router['rawUrlTree'].queryParams.returnUrl != null) {
        this.authService.loginUser(res['token'], this.router['rawUrlTree'].queryParams.returnUrl);
      } else {
        this.authService.loginUser(res['token']);
      }
    }).catch((err) => {
      var objAlert = {
        title: `Erro`,
        msg: err.error.message,
        tipo: 2
      }
      this.showAlert(objAlert);
      this.loading = false;
    });
  }
  showAlert(item: any) {
    this.isAlertOpen = true;

    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.keydownEvents().subscribe(event => {
      if (event.key === "Enter") {
        this.isAlertOpen = false;
        event.stopPropagation();
        dialogRef.close();
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isAlertOpen = false;
      this.preventLogin = true;
      setTimeout(() => this.preventLogin = false, 100); // Impede login por um curto período
  });
  }
}
