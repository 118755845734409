import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
declare var $: any;
import * as moment from "moment";
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

  loading: any = false;
  loadingSave: any = false;
  total_pages: any = [];
  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  listItem: any = [];
  nomeContrato: any = "";
  finalDate: any = moment().format('YYYY-MM-DD');
  initialDate: any = moment().format('YYYY-MM-DD');
  finalDate_: any = moment().format('DD/MM/YYYY');
  initialDate_: any = moment().format('DD/MM/YYYY');
  contrato: any = {};
  contrato_: any = {};
  ordem_ids: any = [];
  _global: any;
  contractType: any = "1";
  statusContrato: any = "0";
  banksList: any = [];
  somaTotal: any = 0;
  somaIof: any = 0;
  somaPagar: any = 0;
  somaQuantidade: any = 0;

  avisoIntervaloDatas: boolean = false;
  ultimosParametros: any = {}; //últimos parâmetros de pesquisa
  ultimaTotalPages: number = 0; // último total de páginas (muda numa nova pesquisa)
  ultimaPagina: number = 1;

  qtdTotalOrdens: number = 0;
  valorTotalOrdens: number = 0;
  iofTotalOrdens: number = 0;
  pagarTotalOrdens: number = 0;

  constructor(private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    public global: Global) {
    this._global = global;
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('#initialDate').datepicker({ language: 'pt' });
      $('#finalDate').datepicker({ language: 'pt' });
    }, 1000);
    // this.getContracts();
    this.getContracts();
  }

  formatarNumeroQtd(numero: number, locale: string = 'pt-BR'): string {
    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 0, 
        maximumFractionDigits: 0, 
    }).format(numero);
  }

  pesquisar() {
    if ($("#initialDate").val() && $("#finalDate").val()) {
      var dataInicial = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');
      var dataFinal = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD');

      const novosParametros = { initialDate: dataInicial, finalDate: dataFinal };
      if (JSON.stringify(this.ultimosParametros) !== JSON.stringify(novosParametros)) {
        this.ultimosParametros = novosParametros;
        this.initialDate = dataInicial;
        this.finalDate = dataFinal;
        this.avisoIntervaloDatas = false;
        this.resetPagination();
        this.getContracts();
      } else {
        this.avisoIntervaloDatas = true;
      }
    }
  }

  getContracts(page: number = 1, limit: number = 25) {
    this.loading = true;
    let url = `contrato?page=${page}&limit=${limit}&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&nome=${this.nomeContrato}`;

    if (this.statusContrato != "0") {
      url += `&status=${this.statusContrato}`;
    }

    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.listItem = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit);
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });

  }

  initPagination(totalPages: number, page: number, limit: number, id?: any) {
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((this.ultimaPagina !== page)) {
              this.ultimaPagina = page;
              this.avisoIntervaloDatas = false;
              that.getContracts(page, limit);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  verDetalhes2 = (item: any) => {
    this.contrato = Object.assign({}, item);
    this.contrato_ = Object.assign({}, item);
    $("#detalhesContrato").toggleClass("show");
    $("#detalhesContrato").css("display", "block");
    this.contrato.banco_externo_id = 0;
    this.qtdTotalOrdens = this.contrato.total_ordens.qtd_total_ordens;
    this.valorTotalOrdens = this.contrato.total_ordens.valor_total_ordens;
    this.iofTotalOrdens = this.contrato.total_ordens.iof_total_ordens;
    this.pagarTotalOrdens = this.contrato.total_ordens.pagar_total_ordens;
    this.getBancos();
  }


  fecharModalDetalhes = () => {
    this.contrato = {};
    $("#detalhesContrato").toggleClass("show");
    $("#detalhesContrato").css("display", "none");
  }

  saveContrato = () => {
    if (!this.contrato.nome) {
      var objAlert = {
        title: `Erro`,
        msg: "Favor preencher nome",
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    var obj = {
      "banco_externo_id": this.contrato.banco_externo_id != 0 && this.contrato.banco_externo_id != "0" ? this.contrato.banco_externo_id : "",
      "nome": this.contrato.nome,
      "ordem_ids": this.contrato.ordens.map((item: any) => {
        return item.id
      }),
      "status": this.contrato.status
    }
    this.loadingSave = true;
    this.apiService.Put(`contrato/${this.contrato.id}`, obj).then((res: any) => {
      this.getContracts();
      var objAlert = {
        title: `Sucesso`,
        msg: "Sucesso ao salvar contrato",
        tipo: 1
      }
      this.showAlert(objAlert);
      this.loadingSave = false;
    }).catch((err) => {
      this.loadingSave = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  deletarDado(d: any) {
    var x;
    var r = confirm("Deseja remover este contrato?");
    if (r == true) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.apiService.Delete(`contrato/${d.id}`).then((res) => {
        this.loading = false;
        this.getContracts();
      }).catch((err) => {
        this.loading = false;
      })
    }
  }

  deletarDadoCotacao(d: any) {
    var x;
    var r = confirm("Deseja remover este dado?");
    if (r == true) {
      if (this.contrato.ordens.length > 0) {
        var filter = this.contrato.ordens.filter((x: any) => x.id == d.id);
        if (filter.length > 0) {
          for (let index = 0; index < this.contrato.ordens.length; index++) {
            const element = this.contrato.ordens[index];
            if (element.id == d.id) {
              this.contrato.ordens.splice(index, 1);
            }
          }
        }
      }
    }
  }

  modalExportar = (item: any) => {
    this.contrato = Object.assign({}, item);
    $("#exportarContrato").toggleClass("show");
    $("#exportarContrato").css("display", "block");
  }

  fecharModalExportar = () => {
    this.contrato = {};
    $("#exportarContrato").toggleClass("show");
    $("#exportarContrato").css("display", "none");
  }

  exportContract = () => {
    var url = "";
    if (this.contractType == "1") {
      url = `contrato/${this.contrato.id}/exportar/padrao`;
    } else {
      url = `contrato/${this.contrato.id}/exportar/topazio`;
    }
    let that = this;
    var req = new XMLHttpRequest();
    req.open("GET", `${this.global.apiUrl}${url}`, true);
    req.setRequestHeader('Authorization', `Bearer ${this.authService.authToken.value}`);
    req.responseType = "blob";
    this.loadingSave = true;
    req.onload = function (event) {
      var blob = req.response;
      that.loadingSave = false;
      if (req.status == 400) {
        var objAlert = {
          title: `Erro`,
          msg: "Nenhum dado encontrado",
          tipo: 2
        }
        that.showAlert(objAlert);
      } else {
        var link: any = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${uuidv4()}.xlsx`;
        link.click();
      }
    };

    req.send();
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  getBancos() {
    var url = `banco-externo?page=1&limit=100`;
    this.apiService.Get(url).then((res: any) => {
      this.banksList = res;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  // getContracts(page: number = 1, limit: number = 25) {
  //   this.loading = true;
  //   var url = `contrato?page=${page}&limit=${limit}&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&nome=${this.nomeContrato}`;
  //   if (this.status != "0") {
  //     url += `&status=${this.status}`;
  //   }
  //   this.apiService.Get(url).then((res: any) => {
  //     this.total_pages = [];
  //     this.listItem = res;

  //     for (let index = 0; index < res.total_pages; index++) {
  //       const element = index;
  //       this.total_pages.push(index + 1);
  //     }

  //     if (res.rows != null && res.rows.length > 0) {
  //       this.paginas = res.total_rows;
  //       this.mostrando = 1;
  //       this.mostrando2 = res.rows.length;

  //     }
  //     var primeiraChamada = true;
  //     try {
  //       $('#pagination_').twbsPagination('destroy');
  //       let that = this;
  //       $('#pagination_').twbsPagination({
  //         totalPages: res.total_pages,
  //         visiblePages: 7,
  //         first: "Primeira",
  //         prev: "Anterior",
  //         next: "Próxima",
  //         last: "Última",
  //         onPageClick: function (event = null, page = 0) {
  //           if (!primeiraChamada) {
  //             that.getContractsPage(page);
  //           } else {
  //             primeiraChamada = false;
  //           }
  //         }
  //       });
  //     } catch (error) {

  //     }
  //     this.loading = false;

  //     this.listItem.rows.forEach((item: any) => {
  //       let total = 0;
  //       for (const entry of item.ordens) {
  //         total += entry.cotacao.valor_total;
  //       }
  //       item.somaTotal = total;

  //       let iof = 0;
  //       for (const entry of item.ordens) {
  //         iof += entry.cotacao.iof;
  //       }
  //       item.somaIof = iof;


  //       let pagar = 0;
  //       for (const entry of item.ordens) {
  //         pagar += entry.cotacao.pagar;
  //       }
  //       item.somaPagar = pagar;


  //       let quantidade = 0;
  //       for (const entry of item.ordens) {
  //         quantidade += entry.cotacao.quantidade;
  //       }
  //       item.somaQuantidade = quantidade;
  //     })
  //     setTimeout(() => {
  //       $(function () {
  //         $('[data-toggle="tooltip"]').tooltip()
  //       })

  //       $(function () {
  //         $('[data-toggle="popover"]').popover()
  //       })
  //     }, 1000);
  //   }).catch((err) => {
  //     this.loading = false;
  //     if (err.status == 401 || err.error.status == 401) {
  //       this.authService.logoutUser()
  //     }
  //   });
  // }

  // getContractsPage(page = 1) {
  //   this.loading = true;
  //   var url = `contrato?page=${page}&limit=25&data_inicio=${this.initialDate}&data_fim=${this.finalDate}&nome=${this.nomeContrato}`;
  //   if (this.status != "0") {
  //     url += `&status=${this.status}`;
  //   }
  //   this.apiService.Get(url).then((res: any) => {
  //     this.total_pages = [];
  //     this.listItem = res;

  //     for (let index = 0; index < res.total_pages; index++) {
  //       const element = index;
  //       this.total_pages.push(index + 1);
  //     }
  //     if (res.rows != null && res.rows.length > 0) {
  //       if (page == 1) {
  //         this.paginas = res.total_rows;
  //         this.mostrando = 1;
  //         this.mostrando2 = res.rows.length;
  //       } else
  //         if (page > 1) {
  //           if (this.total_pages.length == page) {
  //             this.paginas = res.total_rows;
  //             this.mostrando = res.total_rows - res.rows.length + 1
  //             this.mostrando2 = res.total_rows;
  //           } else {
  //             this.paginas = res.total_rows;
  //             this.mostrando = (res.rows.length * page) - res.rows.length + 1;
  //             this.mostrando2 = (res.rows.length * page);
  //           }
  //         }
  //     }

  //     this.listItem.rows.forEach((item: any) => {
  //       let total = 0;
  //       for (const entry of item.ordens) {
  //         total += entry.cotacao.valor_total;
  //       }
  //       item.somaTotal = total;

  //       let iof = 0;
  //       for (const entry of item.ordens) {
  //         iof += entry.cotacao.iof;
  //       }
  //       item.somaIof = iof;


  //       let pagar = 0;
  //       for (const entry of item.ordens) {
  //         pagar += entry.cotacao.pagar;
  //       }
  //       item.somaPagar = pagar;


  //       let quantidade = 0;
  //       for (const entry of item.ordens) {
  //         quantidade += entry.cotacao.quantidade;
  //       }
  //       item.somaQuantidade = quantidade;
  //     })
  //     this.loading = false;
  //   }).catch((err) => {
  //     this.loading = false;
  //     if (err.status == 401 || err.error.status == 401) {
  //       this.authService.logoutUser()
  //     }
  //   });
  // }

  // verDetalhes = (item: any) => {
  //   this.contrato = Object.assign({}, item);
  //   this.contrato_ = Object.assign({}, item);
  //   $("#detalhesContrato").toggleClass("show");
  //   $("#detalhesContrato").css("display", "block");
  //   this.contrato.banco_externo_id = 0;

  //   let total = 0;
  //   for (const entry of this.contrato.ordens) {
  //     total += entry.cotacao.valor_total;
  //   }
  //   this.somaTotal = total;

  //   let iof = 0;
  //   for (const entry of this.contrato.ordens) {
  //     iof += entry.cotacao.iof;
  //   }
  //   this.somaIof = iof;


  //   let pagar = 0;
  //   for (const entry of this.contrato.ordens) {
  //     pagar += entry.cotacao.pagar;
  //   }
  //   this.somaPagar = pagar;


  //   let quantidade = 0;
  //   for (const entry of this.contrato.ordens) {
  //     quantidade += entry.cotacao.quantidade;
  //   }
  //   this.somaQuantidade = quantidade;
  //   this.getBancos();
  // }


  // pesquisar() {

  //   if ($("#initialDate").val() && $("#finalDate").val()) {
  //     var dTi = moment(`${$("#initialDate").val().split(" ")[0].split("/")[2]}-${$("#initialDate").val().split(" ")[0].split("/")[1]}-${$("#initialDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
  //     var dTF = moment(`${$("#finalDate").val().split(" ")[0].split("/")[2]}-${$("#finalDate").val().split(" ")[0].split("/")[1]}-${$("#finalDate").val().split(" ")[0].split("/")[0]}`).format('YYYY-MM-DD')
  //     this.initialDate = dTi;
  //     this.finalDate = dTF;
  //     this.getContracts(1);
  //   }
  // }

}
