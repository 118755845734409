<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Grupos</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Grupos</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_body">
                        <div class="QA_section">
                            <div class="QA_table mb_30">
                                <div class="inline-flex-itens ">
                                    <div class="row align-items-center inline-inputs-center">
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="initialDate">Data inicial</label>
                                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="finalDate">Data final</label>
                                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto">
                                            <label class="form-label" for="nomeGrupo">Nome do grupo</label>
                                            <input class="form-control" id="nomeGrupo" type="text" placeholder="Nome do grupo" [(ngModel)]="nomeGrupo">
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary">Buscar</button>
                                        </div>
                                    </div>
                                    <div class="desktop-view">
                                        <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAddGrupo()" title="Adicionar grupo">
                                            Adicionar grupo
                                        </button>
                                    </div>
                                </div>
                                <div class="mobile-view">
                                    <button type="button" class="btn btn-primary mb-3 btn-add" (click)="modalAddGrupo()" title="Adicionar grupo">
                                        <i class="ti-plus"></i>
                                    </button>
                                </div>
                                <table class="table lms_table_active3 ">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Descrição</th>
                                            <th scope="col" style="text-align: right;">Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!loading">
                                        <tr *ngFor="let item of listItem.rows">
                                            <td>{{item.id}}</td>
                                            <td>{{ item.data_registro?
                                                _global.formateDateAndTime(item.data_registro) : '-'}}</td>
                                            <td>{{item.nome}}</td>
                                            <td>{{item.descricao}}</td>
                                            <td style="text-align: right;">
                                                <img *ngIf="loading"
                                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                &nbsp;
                                                <button *ngIf="!loading" type="button" title="Visualizar Grupo" class="btn btn-primary " (click)="modalEditarGrupo(item)">
                                                    <i class="ti-pencil-alt2"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="loading">
                                        <div class="loading-table">
                                            <img
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </div>
                                    </tbody>

                                </table>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="card">
                                    <div class="card-body pag-custom">
                                        <div class="col" *ngIf="total_pages.length > 0">
                                            <div class="navigations-rows-shows">
                                                <h2>Mostrando {{mostrando}} até
                                                    {{mostrando2}}
                                                    de {{paginas}} registros
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <nav aria-label="Page navigation">
                                                <ul class="pagination" id="pagination_"></ul>
                                            </nav>
                                        </div>
                                        <div class="col">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #grupoModalTemplate let-modalTitle="modalTitle" let-addGrupo="addGrupo" let-saveAction="saveAction" let-closeAction="closeAction" let-buttonText="buttonText">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <h5 class="modal-title">{{ modalTitle }}</h5>
                <button type="button" class="btn-close" (click)="closeAction()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="modalTitle === 'Editar Grupo'">ID: {{addGrupo.id}}</div>
                <div class="inline-flex-itens" style="margin-top: 1rem;">
                    <input class="form-control input-novo-grupo-nome" type="text" [(ngModel)]="addGrupo.nome" placeholder="Nome do grupo">
                </div>
                <div class="inline-flex-itens" style="margin-top: 1rem;">
                    <input class="form-control input-novo-grupo-nome" type="text" [(ngModel)]="addGrupo.descricao" placeholder="Descrição">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loading" class="btn btn-secondary" (click)="closeAction()">Fechar</button>
                <span>
                    <img *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loading" type="button" class="btn btn-primary" (click)="saveAction(addGrupo)">{{ buttonText }}</button>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<div class="modal centerModal fade" id="modalAddGrupo" tabindex="-1" role="dialog">
    <ng-container *ngTemplateOutlet="grupoModalTemplate; context: {
        modalTitle: 'Adicionar Grupo', 
        addGrupo: addGrupo, 
        saveAction: postGrupo, 
        closeAction: fecharModalAddGrupo,
        buttonText: 'Adicionar'
    }"></ng-container>
</div>

<div class="modal centerModal fade" id="modalEditarGrupo" tabindex="-1" role="dialog">
    <ng-container *ngTemplateOutlet="grupoModalTemplate; context: {
        modalTitle: 'Editar Grupo', 
        addGrupo: addGrupo, 
        saveAction: putGrupo, 
        closeAction: fecharModalEditarGrupo,
        buttonText: 'Editar'
    }"></ng-container>
</div>