import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import * as moment from "moment";
import { Global } from 'src/app/global';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
import { Location } from '@angular/common';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {


  loading: any = false;
  // UsersList: any = [];
  usersList: any = [];
  total_pages: any = [];
  total_pages_aux: any = [];

  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');
  users: any = {};
  user: any = {};

  identificador: any = "";
  initialDate_: string = moment().format('DD/MM/YYYY');
  finalDate_: string = moment().format('DD/MM/YYYY');
  initialDate: string = moment().format('YYYY-MM-DD');
  finalDate: string = moment().format('YYYY-MM-DD');
  ultimaTotalPages: number = 0;
  ultimaPagina: number = 1;

  client: any;
  confirmarSenha: any;
  seepasswordCnf: any = false;
  seepassword: any = false;
  currentUser: any = {};
  
  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
    private location: Location
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      if (res) {
        this.client = res;
        this.currentUser = res;
      }
    });
  }

  ngOnInit(): void {
    this.getUsers();
  }

  formaterCurrency(value: any) {
    var valor_ = value / 100;
    return value;
  }

  pesquisar() {
    this.getUsers(1);
  }

  // getUsers(page = 1) {
  //   this.loading = true;
  //   var url = `usuario-admin?page=${page}`;
  //   if (this.identificador) {
  //     url = url += `&identificador=${this.identificador}`;
  //   }
  //   this.apiService.Get(url).then((res: any) => {
  //     this.total_pages = [];
  //     this.UsersList = res;
  //     for (let index = 0; index < res.total_pages; index++) {
  //       const element = index;
  //       this.total_pages.push(index + 1);
  //     }

  //     if (res.rows.length > 0) {
  //       this.paginas = res.total_rows;
  //       this.mostrando = 1;
  //       this.mostrando2 = res.rows.length;

  //     }
  //     var primeiraChamada = true;
  //     try {
  //       $('#pagination_').twbsPagination('destroy');
  //       let that = this;
  //       $('#pagination_').twbsPagination({
  //         totalPages: res.total_pages,
  //         visiblePages: 7,
  //         first: "Primeira",
  //         prev: "Anterior",
  //         next: "Próxima",
  //         last: "Última",
  //         onPageClick: function (event = null, page = 0) {
  //           if (!primeiraChamada) {
  //             that.getUsersPage(page);
  //           } else {
  //             primeiraChamada = false;
  //           }
  //         }
  //       });
  //     } catch (error) {

  //     }
  //     this.loading = false;
  //     setTimeout(() => {
  //       $(function () {
  //         $('[data-toggle="tooltip"]').tooltip()
  //       })

  //       $(function () {
  //         $('[data-toggle="popover"]').popover()
  //       })
  //     }, 1000);
  //   }).catch((err) => {
  //     this.loading = false;
  //     if (err.status == 401 || err.error.status == 401) {
  //       this.authService.logoutUser()
  //     } else {
  //       var objAlert = {
  //         title: `Erro`,
  //         msg: err.error.message,
  //         tipo: 2
  //       }
  //       this.showAlert(objAlert);
  //     }
  //   });
  // }

  // getUsersPage(page = 1) {
  //   this.loading = true;
  //   var url = `usuario-admin?page=${page}`;
  //   if (this.identificador) {
  //     url = url += `&identificador=${this.identificador}`;
  //   }
  //   this.apiService.Get(url).then((res: any) => {
  //     this.total_pages = [];
  //     this.UsersList = res;
  //     for (let index = 0; index < res.total_pages; index++) {
  //       const element = index;
  //       this.total_pages.push(index + 1);
  //     }
  //     if (res.rows.length > 0) {
  //       if (page == 1) {
  //         this.paginas = res.total_rows;
  //         this.mostrando = 1;
  //         this.mostrando2 = res.rows.length;
  //       } else
  //         if (page > 1) {
  //           if (this.total_pages.length == page) {
  //             this.paginas = res.total_rows;
  //             this.mostrando = res.total_rows - res.rows.length + 1
  //             this.mostrando2 = res.total_rows;
  //           } else {
  //             this.paginas = res.total_rows;
  //             this.mostrando = (res.rows.length * page) - res.rows.length + 1;
  //             this.mostrando2 = (res.rows.length * page);
  //           }
  //         }
  //     }
  //     this.loading = false;
  //   }).catch((err) => {
  //     this.loading = false;
  //     if (err.status == 401 || err.error.status == 401) {
  //       this.authService.logoutUser()
  //     } else {
  //       var objAlert = {
  //         title: `Erro`,
  //         msg: err.error.message,
  //         tipo: 2
  //       }
  //       this.showAlert(objAlert);
  //     }
  //   });
  // }

  getUsers(page: number = 1, limit: number = 10, id: any = '') {
    this.loading = true;
    let url = `usuario-admin?page=${page}&limit=${limit}`;

    if (this.identificador) {
      url = url += `&identificador=${this.identificador}`;
    }
    // &data_inicio=${this.initialDate}&data_fim=${this.finalDate}

    this.apiService.Get(url).then((res: any) => {
      this.usersList = res;
      this.total_pages = Array.from({ length: res.total_pages }, (_, i) => i + 1);
      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = (page - 1) * limit + 1;
        this.mostrando2 = this.mostrando + res.rows.length - 1;
      }
      this.initPagination(res.total_pages, page, limit, id);
      this.loading = false;
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
      }, 1000);
    }).catch((err) => {
      this.handleError(err);
    });
  }

  initPagination(totalPages: number, page: number, limit: number, id?: any) {
    if (this.ultimaTotalPages !== totalPages) {
      this.ultimaTotalPages = totalPages;
      let that = this;
      try {
        $('#pagination_').twbsPagination('destroy');
        $('#pagination_').twbsPagination({
          totalPages: totalPages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: (event: any, page: number) => {
            if ((this.ultimaPagina !== page)) {
              this.ultimaPagina = page;
              that.getUsers(page, limit, id);
            }
          }
        });
      } catch (error) {

      }
    }
  }

  resetPagination() {
    $('#pagination_').twbsPagination('destroy');
    this.ultimaTotalPages = 0;
    this.ultimaPagina = 1;
  }

  verDetalhesuser = (user: any) => {
    this.getUser(user.id);
  }

  modalAdicionarUsuario = (user: any = {}) => {
    this.loading = false;
    this.user = user;
    this.confirmarSenha = null;
    $("#cadastrousere").toggleClass("show");
    $("#cadastrousere").css("display", "block");
  }

  fecharmodalAdicionarUsuario = () => {
    $("#cadastrousere").toggleClass("show");
    $("#cadastrousere").css("display", "none");
  }

  validateEmail(mail: any) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  saveUsuario = () => {
    if (!this.user.nome) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher nome`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (!this.user.email) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher email`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (!this.validateEmail(this.user.email)) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher email válido`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!this.user.senha) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher senha`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (!this.confirmarSenha) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher confirmar senha`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (this.confirmarSenha != this.user.senha) {
      var objAlert = {
        title: `Erro`,
        msg: `Senhas não condizem`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }

    if (this.user.id) {
      this.putUser(this.user);
    } else {
      this.postuser(this.user);
    }
  }

  postuser = (obj: any) => {
    this.loading = true;
    this.apiService.Post(`usuario-admin`, obj).then((res: any) => {
      this.fecharmodalAdicionarUsuario();
      this.getUsers();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getUser = (id: any) => {
    // this.loading = true;
    this.apiService.Get(`usuario-admin/${id}`).then((res: any) => {
      this.modalAdicionarUsuario(res);
      // this.loading = false;
    }).catch((err) => {
      this.handleError(err);
    });
  }


  putUser = (obj: any) => {
    this.loading = true;
    this.apiService.Put(`usuario-admin/${obj.id}`, obj).then((res: any) => {
      this.fecharmodalAdicionarUsuario();
      this.getUsers();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  deletarDado(d: any) {
    var x;
    var r = confirm("Deseja remover este usuário?");
    if (r == true) {
      this.deletarUsuario(d)
    }
  }

  deletarUsuario(d: any) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.apiService.Delete(`usuario-admin/${d.id}`).then((res) => {
      this.loading = false;
      this.getUsers();
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    })
  }

  handleError(err: any) {
    this.loading = false;
    var objAlert = {
      title: `Erro`,
      msg: err.error.message || 'Ocorreu um erro inesperado!',
      tipo: 2
    }
    if (err.status === 401 || err.error.status == 401) {
      this.authService.logoutUser();
      this.router.navigate(['/']);
      this.showAlert(objAlert);
    } else {
      this.router.navigate(['/']);
      // this.location.back()
      this.showAlert(objAlert);
    }
  }


  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }


}
