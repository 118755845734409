import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatDialog } from '@angular/material';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-night-mode',
  templateUrl: './night-mode.component.html',
  styleUrls: ['./night-mode.component.css']
})
export class NightModeComponent implements OnInit {
  currentUser: any = {};
  flgAtivarModoNoturno: boolean = false;
  flgAtivarCotacaoFixa: boolean = false;
  valorCotacaoDolarFixaNoturno: any;
  valorCotacaoDolarDinamicaNoturno: any;
  valorSpreadNoturno: any;

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.getDadosModoNoturno();
  }

  getDadosModoNoturno() {
    this.apiService.Get("modo-noturno").then((res: any) => {
      this.flgAtivarModoNoturno = res.flg_ativar_modo_noturno === "1";
      this.flgAtivarCotacaoFixa = res.flg_ativar_cotacao_fixa === "1";
      this.valorCotacaoDolarFixaNoturno = res.valor_cotacao_dolar_fixa;
      this.valorSpreadNoturno = res.valor_spread_noturno;
      this.valorCotacaoDolarDinamicaNoturno = res.valor_cotacao_dolar_dinamica;
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  toggleNightMode(event: any) {
    this.flgAtivarModoNoturno = event.target.checked;
  }

  toggleFlgAtivarCotacaoFixa(event: any) {
    this.flgAtivarCotacaoFixa = event.target.checked;
  }

  onSubmit() {
    var obj = {
      "flg_ativar_modo_noturno": this.flgAtivarModoNoturno ? "1" : "0",
      "flg_ativar_cotacao_fixa": this.flgAtivarCotacaoFixa ? "1" : "0",
      "valor_cotacao_dolar_fixa": this.valorCotacaoDolarFixaNoturno,
      "valor_spread_noturno": this.valorSpreadNoturno
    }

    this.apiService.Put("modo-noturno", obj).then((res: any) => {
      var objAlert = {
        title: `Sucesso`,
        msg: "Atualizado com sucesso!",
        tipo: 1
      }
      this.showAlert(objAlert);
      this.reloadCurrentRoute();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  formatarNumero(event: any, decimais: number) {
    let valor = event.target.value;

    valor = valor.replace(/[^\d.]/g, '');

    if ((valor.length >= 2) && !valor.includes('.')) {
      valor = valor.slice(0, 1) + '.' + valor.slice(1);
    }

    event.target.value = valor

    if (valor.includes('.')) {
      const partes = valor.split('.');
      let inteiro = partes[0];
      let decimal = partes.length > 1 ? partes[1].substring(0, decimais) : '';
      event.target.value = `${inteiro}.${decimal}`
    }

  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
