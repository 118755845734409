<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin' && !currentUser.modo_noturno">
    <div class="main_content_iner overly_inner">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                        <div class="page_title_left d-flex align-items-center">
                            <h3 class="mb-0">Global</h3>
                        </div>
                        <div class="page_title_right">
                            <ol class="breadcrumb page_bradcam mb-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                                <li class="breadcrumb-item active">Global</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_header">
                            <div class="box_header m-0">
                                <form>
                                    <div class="row mb-3">
                                        <div class="col-md-6" style="align-content: end;">
                                          <div class="form-check">
                                            <label class="form-label form-check-label" for="flgAtivarTopazio">Ativar Topazio</label>
                                            <input 
                                              class="form-check-input" 
                                              type="checkbox" 
                                              id="flgAtivarTopazio" 
                                              (change)="toggleAtivarTopazio($event)" 
                                              [(ngModel)]="flgAtivarTopazio" 
                                              name="flgAtivarTopazio">
                                          </div>
                                        </div>
                                      </div>
                                    <div class="row mb-3">
                                        <div class="col-md-6">
                                            <label class="form-label" for="valorSpread">Spread</label>
                                            <input class="form-control" id="valorSpread" name="valorSpread" type="text" placeholder="0.00" (input)="formatarNumero($event, 2)" [(ngModel)]="valorSpread">
                                          </div>
                                        <div class="col-md-2" style="align-content: end;">
                                            <button type="button" (click)="putSpread()" class="btn btn-primary mb-2 btnEnviar" style="margin-bottom: 0 !important;">Enviar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>