import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Global } from 'src/app/global';
declare var $: any;
import * as moment from "moment";
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {
  currentUser: any = {};
  loading: any = false;
  loadingClient: any = false;
  clientsList: any = [];
  total_pages: any = [];
  total_pages_aux: any = [];

  paginas: any = 0;
  mostrando: any = 0;
  mostrando2: any = 0;
  $pagination = $('#pagination_');
  cliente: any = {};
  client: any = {};
  identificador: any = "";
  confirmarSenha: any;
  seepasswordCnf: any = false;
  seepassword: any = false;

  gruposCriados: any = {};
  novoGrupoNome = '';
  grupoEscolhido: string = "";
  mostrarCampoNovoGrupo = false;
  flgSaldo: any = false;

  constructor(
    private router: Router,
    public apiService: ApiService,
    private authService: AuthService,
    public global: Global,
    public dialog: MatDialog,
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.getClients();
    // this.getGruposCriados();
  }

  formaterCurrency(value: any) {
    return value;
  }

  pesquisar() {
    this.getClients(1);
  }

  getClients(page = 1) {
    this.loading = true;
    var url = `cliente?page=${page}`;
    if (this.identificador) {
      url = url += `&identificador=${this.identificador}`;
    }
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.clientsList = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }

      if (res.rows.length > 0) {
        this.paginas = res.total_rows;
        this.mostrando = 1;
        this.mostrando2 = res.rows.length;

      }
      var primeiraChamada = true;
      try {
        $('#pagination_').twbsPagination('destroy');
        let that = this;
        $('#pagination_').twbsPagination({
          totalPages: res.total_pages,
          visiblePages: 7,
          first: "Primeira",
          prev: "Anterior",
          next: "Próxima",
          last: "Última",
          onPageClick: function (event = null, page = 0) {
            if (!primeiraChamada) {
              that.getClientsPage(page);
            } else {
              primeiraChamada = false;
            }
          }
        });
      } catch (error) {

      }
      this.loading = false;
      setTimeout(() => {
        $(function () {
          $('[data-toggle="tooltip"]').tooltip()
        })

        $(function () {
          $('[data-toggle="popover"]').popover()
        })
      }, 1000);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getClientsPage(page = 1) {
    this.loading = true;
    var url = `cliente?page=${page}`;
    if (this.identificador) {
      url = url += `&identificador=${this.identificador}`;
    }
    this.apiService.Get(url).then((res: any) => {
      this.total_pages = [];
      this.clientsList = res;
      for (let index = 0; index < res.total_pages; index++) {
        const element = index;
        this.total_pages.push(index + 1);
      }
      if (res.rows.length > 0) {
        if (page == 1) {
          this.paginas = res.total_rows;
          this.mostrando = 1;
          this.mostrando2 = res.rows.length;
        } else
          if (page > 1) {
            if (this.total_pages.length == page) {
              this.paginas = res.total_rows;
              this.mostrando = res.total_rows - res.rows.length + 1
              this.mostrando2 = res.total_rows;
            } else {
              this.paginas = res.total_rows;
              this.mostrando = (res.rows.length * page) - res.rows.length + 1;
              this.mostrando2 = (res.rows.length * page);
            }
          }
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  verDetalhesClient = (client: any) => {
    this.getClient(client.id);
  }

  modalAdicionarCliente = (cliente: any = {}) => {
    this.loading = false;
    this.client = cliente;
    this.confirmarSenha = null;
    $("#cadastroCliente").toggleClass("show");
    $("#cadastroCliente").css("display", "block");
  }

  fecharModalAdicionarCliente = () => {
    $("#cadastroCliente").toggleClass("show");
    $("#cadastroCliente").css("display", "none");
  }

  // toggleOperarComCredito(event: any) {
  //   this.client.operar_com_credito = event.target.checked;
  // }

  toggleOperarComSaldo(event: any) {
    console.log("evento this.client.operar_com_credito :", this.client.operar_com_credito);
    this.client.operar_com_credito = event.target.checked;
    console.log("this.client.operar_com_credito:", this.client.operar_com_credito);
    this.flgSaldo = this.client.operar_com_credito;
  }

  saveClient = () => {
    if (!this.client.nome) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher nome`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!this.client.chave) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher chave`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!this.client.senha) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher senha`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (!this.confirmarSenha) {
      var objAlert = {
        title: `Erro`,
        msg: `Favor preencher confirmar senha`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    if (this.confirmarSenha != this.client.senha) {
      var objAlert = {
        title: `Erro`,
        msg: `Senhas não condizem`,
        tipo: 2
      }
      this.showAlert(objAlert);
      return;
    }
    console.log("this.flgSaldo:", this.flgSaldo);
    console.log("this.client.operar_com_credito:", this.client.operar_com_credito);
    console.log("this.currentUser.tipo:", this.currentUser.tipo);
    console.log("this.currentUser.operar_com_credito:", this.currentUser.operar_com_credito);
    this.client.operar_com_credito = !this.flgSaldo;
    if (this.currentUser.tipo === 'cliente') {
      this.client.operar_com_credito = this.currentUser.operar_com_credito;
    }

    if (this.client.id) {
      this.putClient(this.client);
    } else {
      this.postClient(this.client);
    }
  }

  postClient = (obj: any) => {
    this.loading = true;
    this.apiService.Post(`cliente`, obj).then((res: any) => {
      this.fecharModalAdicionarCliente();
      this.getClients();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  getClient = (id: any) => {
    this.loadingClient = true;
    this.apiService.Get(`cliente/${id}`).then((res: any) => {
      this.modalAdicionarCliente(res);
      this.loadingClient = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  putClient = (obj: any) => {
    this.loading = true;
    this.apiService.Put(`cliente/${obj.id}`, obj).then((res: any) => {
      this.fecharModalAdicionarCliente();
      this.getClients();
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser()
      } else {
        var objAlert = {
          title: `Erro`,
          msg: err.error.message,
          tipo: 2
        }
        this.showAlert(objAlert);
      }
    });
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });

    dialogRef.afterClosed().subscribe((result: any) => {

    });
  }

}
