import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MatDialog } from '@angular/material';
import { ModalAlertComponent } from 'src/app/components/modal-alert/modal-alert.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-operation-day',
  templateUrl: './operation-day.component.html',
  styleUrls: ['./operation-day.component.css']
})
export class OperationDayComponent implements OnInit {
  currentUser: any = {};
  valorDiaD0: any;
  valorDiaD1: any;
  valorDiaD2: any;

  constructor(
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.authService.currentUser.subscribe((res: any) => {
      this.currentUser = res;
    });
  }

  ngOnInit(): void {
    this.getDadosModoNoturno();
  }

  getDadosModoNoturno() {
    this.apiService.Get("manager-operation-day").then((res: any) => {
      this.valorDiaD0 = res.op_dia_d0;
      this.valorDiaD1 = res.op_dia_d1;
      this.valorDiaD2 = res.op_dia_d2;
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  onSubmit() {
    var obj = {
      "op_dia_d0": this.valorDiaD0,
      "op_dia_d1": this.valorDiaD1,
      "op_dia_d2": this.valorDiaD2
    }

    this.apiService.Put("manager-operation-day", obj).then((res: any) => {
      var objAlert = {
        title: `Sucesso`,
        msg: "Atualizado com sucesso!",
        tipo: 1
      }
      this.showAlert(objAlert);
      this.reloadCurrentRoute();
    }).catch((err) => {
      if (err.status == 401 || err.error.status == 401) {
        this.authService.logoutUser();
      } else {
        var objAlert = {
          title: `Erro`,

          msg: err.error.message,
          tipo: 2
        };
        this.showAlert(objAlert);
      }
    });
  }

  showAlert(item: any) {
    const dialogRef = this.dialog.open(ModalAlertComponent, {
      width: 'auto',
      data: item
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  somenteNumeros(event: any) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (!/^[0-5]$/.test(value)) {
      input.value = '';
    }
  }

}
