<div *ngIf="currentUser && currentUser.tipo === 'usuarioAdmin'">
    <div class="main_content_iner overly_inner">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                        <div class="page_title_left d-flex align-items-center">
                            <h3 class="mb-0">Configurações Dia Operação</h3>
                        </div>
                        <div class="page_title_right">
                            <ol class="breadcrumb page_bradcam mb-0">
                                <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                                <li class="breadcrumb-item active">Configurações Dia Operação</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_header">
                            <div class="box_header m-0">
                                <form>
                                    <div class="row mb-3">
                                        <div class="col-md-2">
                                            <label class="form-label" for="valorDiaD0">D0</label>
                                            <input class="form-control" id="valorDiaD0" name="valorDiaD0" type="text" placeholder="0" [(ngModel)]="valorDiaD0" (input)="somenteNumeros($event)" pattern="[0-5]">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="form-label" for="valorDiaD1">D1</label>
                                            <input class="form-control" id="valorDiaD1" name="valorDiaD1" type="text" placeholder="0" [(ngModel)]="valorDiaD1" (input)="somenteNumeros($event)" pattern="[0-5]">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="form-label" for="valorDiaD2">D2</label>
                                            <input class="form-control" id="valorDiaD2" name="valorDiaD2" type="text" placeholder="0" [(ngModel)]="valorDiaD2" (input)="somenteNumeros($event)" pattern="[0-5]">
                                        </div>
                                        <div class="col-md-2" style="align-content: end;">
                                            <button type="button" (click)="onSubmit()" class="btn btn-primary mb-2 btnEnviar" style="margin-bottom: 0 !important;">Enviar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>