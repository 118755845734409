<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left">
                        <h3 class="mb-0">Dashboard</h3>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="row hide-mobile">
            <div class="col-lg-6">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="row align-items-center inline-inputs-center">
                        <div class="col-auto">
                            <label class="form-label" for="inputState">Data inicial</label>
                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col-auto">
                            <label class="form-label" for="inputState">Data final</label>
                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                        </div>
                        <div class="col-auto">
                            <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary ">Buscar</button>
                            <img *ngIf="loading" style="margin-top: 30px; margin-left: 10px;"
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="monitor_list_widget">
                        <div class="simgle_monitor_list">
                            <div class="simgle_monitor_count d-flex align-items-center"> <span>Cotações</span>
                                <div id="monitor_2"></div>
                            </div>
                            <h4><span class="counter">{{dashboard.qtd_cotacoes}}</span> </h4>
                        </div>
                        <div class="simgle_monitor_list">
                            <div class="simgle_monitor_count d-flex align-items-center"> <span>Ordens</span>
                                <div id="monitor_3"></div>
                            </div>
                            <h4> <span class="counter">{{dashboard.qtd_ordens}} </span> </h4>
                        </div>
                        <div *ngIf="currentUser && (currentUser.tipo !== 'subCliente')" class="simgle_monitor_list">
                            <div class="simgle_monitor_count d-flex align-items-center"> <span>Lucro Total</span>
                                <div id="monitor_5"></div>
                            </div>
                            <h4> <span class="counter"> {{dashboard.lucro_total | currency:'BRL':true:'1.2-2'}} </span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <div id="cotarPrecoTela1" class="show">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_header pb-0">
                            <div class="box_header m-0 titulo-horario">
                                <p *ngIf="!this.currentUser.modo_noturno" class="msg-smaller">Horário funcionamento 09h05 às 16h30</p>
                                <div class="main-title espaco-abaixo">
                                    <h3 class="m-0">Cotar moeda</h3>
                                </div>
                            </div>
                        </div>
                        <div class="white_card_body">
                            <form>
                                <div class="row mb-3">
                                    <div class="col-md-12 espaco-abaixo" *ngIf="currentUser && currentUser.tipo !== 'subCliente'">
                                        <label class="form-label" for="comprar_cliente_selecionado">Cliente</label>
                                        <select id="comprar_cliente_selecionado" class="form-select" [(ngModel)]="cotarMoedaComprar.cliente_id" (change)="onClienteChange()" [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let cliente of dashboard.lista_clientes" [value]="cliente.id">{{ cliente.nome }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12 espaco-abaixo" *ngIf="cotarMoedaComprar.cliente_id && cotarMoedaComprar.cliente_id !== '0'">
                                        <div *ngIf="!clienteSelecionado.operar_com_credito" class="cliente-info">
                                            <p class="msg-smaller">Limite disponível: {{ clienteSelecionado?.credito | currency:'BRL' }}</p>
                                        </div>
                                        <div *ngIf="clienteSelecionado.operar_com_credito" class="cliente-info">
                                            <p class="msg-smaller">Limite disponível: {{ clienteSelecionado?.carteiras[0].saldo | currency:'BRL' }}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-12 espaco-abaixo" *ngIf="mostrarMoedaDigital">
                                        <label class="form-label" for="comprar_moeda_digital" id="comprar_moeda_digital_label">Moeda Digital</label>
                                        <div class="inline-flex-itens" style="justify-content: flex-start;">
                                          <div *ngFor="let moeda of this.moedasDigitais; let i = index" class="col-md-auto">
                                            <div class="form-check">
                                              <input class="form-check-input" 
                                                     [(ngModel)]="cotarMoedaComprar.moeda_id" 
                                                     [ngModelOptions]="{standalone: true}" 
                                                     type="radio" 
                                                     name="comprar_moeda_digital" 
                                                     [id]="'comprar_moeda_digital_' + moeda.id" 
                                                     [value]="moeda.id" 
                                                     [disabled]="!isFormEnabled">
                                              <label class="form-label" [for]="'comprar_moeda_digital_' + moeda.id">{{ moeda.nome }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    <div class="col-md-12 espaco-abaixo">
                                        <label class="form-label">Validade</label>
                                        <div class="inline-flex-itens">
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" [(ngModel)]="cotarMoedaComprar.validade" [ngModelOptions]="{standalone: true}" type="radio" name="comprar_validade" id="comprar_validade_d0" value="D0" [disabled]="!isFormEnabled">
                                                    <label class="form-label" for="comprar_validade_d0"> D0 </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" [(ngModel)]="cotarMoedaComprar.validade" [ngModelOptions]="{standalone: true}" type="radio" name="comprar_validade" id="comprar_validade_d1" value="D1" [disabled]="!isFormEnabled">
                                                    <label class="form-label" for="comprar_validade_d1">
                                                        D1
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-check">
                                                    <input class="form-check-input" [(ngModel)]="cotarMoedaComprar.validade" [ngModelOptions]="{standalone: true}" type="radio" name="comprar_validade" id="comprar_validade_d2" value="D2" [disabled]="!isFormEnabled">
                                                    <label class="form-label" for="comprar_validade_d2">
                                                        D2
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label" for="comprar_quantidade">Quantidade</label>
                                        <input type="text" class="form-control" id="comprar_quantidade" placeholder="" (keypress)="onlyNumberKey($event)" (input)="formatarNumeroQtd($event)" [disabled]="!isFormEnabled" [(ngModel)]="cotarMoedaComprar.quantidade" [ngModelOptions]="{standalone: true}">
                                    </div>
                                    <div class="col-md-12 alinhar-botao-centro">
                                        <button type="button" (click)="cotarPreco()" style="margin-top: 30px;" class="btn btn-primary" [disabled]="!isFormEnabled">Cotar preço</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div id="cotarPrecoTela2">
                    <div class="white_card card_height_100 mb_30">
                        <div class="white_card_header">
                            <div class="box_header m-0">
                                <div class="main-title">
                                    <h3 class="m-0">Cotar moeda</h3>
                                </div>
                            </div>
                        </div>
                        <div class="white_card_body" style="padding: 0 !important;">
                            <form>
                                <div class="row col-lg-12">
                                    <div class="col-md-3 div-temporizador">
                                        <div class="temporizador">
                                            <svg width="80" height="80" viewBox="0 0 100 100">
                                                <circle cx="50" cy="50" r="40" stroke="gray" stroke-width="4" fill="none" stroke-dasharray="283" [attr.stroke-dashoffset]="283 - (getProgresso() * 2.83)"></circle>
                                                <text x="50" y="50" dominant-baseline="middle" text-anchor="middle" font-size="1em">{{ tempoRestante }}</text>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <div> <label style="font-size: 0.6rem;">por favor confirme o valor</label></div>
                                        <div class="inline-flex-itens">
                                            <label> {{comprarMoedaCotada.Moeda}}/BRL: </label>
                                            <input type="text" class="form-control input-comprar-moeda-cotada" id="comprar_confirma_moeda" [(ngModel)]="comprarMoedaCotada.Quantidade" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ','}" [ngModelOptions]="{standalone: true}" placeholder="" readonly>
                                        </div>
                                        <div class="inline-flex-itens">
                                            <label> Rate: </label>
                                            <input type="text" class="form-control input-comprar-moeda-cotada" id="comprar_confirma_rate" [(ngModel)]="comprarMoedaCotada.Cotacao" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 4}" [ngModelOptions]="{standalone: true}" placeholder="" readonly>
                                        </div>
                                        <div class="inline-flex-itens">
                                            <label> Total: </label>
                                            <input type="text" class="form-control input-comprar-moeda-cotada" id="comprar_confirma_total" [(ngModel)]="comprarMoedaCotada.Total" currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',' }" [ngModelOptions]="{standalone: true}" placeholder="" readonly>
                                        </div>
                                    </div>
                                    <div class="col-md-6 alinhar-botao-centro">
                                        <button type="button" (click)="retornarCotarMoeda()" style="margin-top: 30px;" class="btn btn-primary btn-estilo-desabilitado" [disabled]="!isFormContagemEnabled">Retornar</button>
                                    </div>
                                    <div class="col-md-6 alinhar-botao-centro">
                                        <button type="button" (click)="confirmarCompra()" style="margin-top: 30px;" class="btn btn-primary btn-estilo-desabilitado" [disabled]="!isFormContagemEnabled">Confirmar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col ">
                <div class="white_card mb_30 card_height_100">
                    <div class="white_card_header ">
                        <div class="box_header m-0">
                            <div class="main-title">
                                <h3 class="m-0">Últimas 10 ordens</h3>
                            </div>
                        </div>
                    </div>
                    <div class="white_card_body pt-0">
                        <div class="QA_section">
                            <div class="QA_table mb-0 order-history-table">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Data</th>
                                                <th scope="col">Val</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Moeda</th>
                                                <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Cliente
                                                </th>
                                                <th scope="col">Cotação (R$)</th>
                                                <!-- <th scope="col" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">Venda (R$)</th> -->
                                                <th scope="col">Quantidade</th>
                                                <th scope="col">Pagar (R$)</th>
                                                <!-- <th scope="col">Receber (R$)</th> -->
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="dashboard.ultimas10_ordens">
                                            <tr *ngFor="let item of dashboard.ultimas10_ordens">
                                                <td class="max-width">
                                                    {{item.data_registro?global_.formateDateAndTime(item.data_registro):'-'}}
                                                </td>
                                                <td class="max-width">{{item.validade}} </td>
                                                <td class="max-width"><span class="badge bg-danger" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='aprovado','bg-warning': item.stastatustus=='pendente', 'bg-info': item.status=='saldo_insuficiente', 'bg-rose': item.status=='rejeitado' }">{{item.status}}</span>
                                                </td>
                                                <td class="max-width">{{item.cotacao.moeda}}</td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo !== 'subCliente')">
                                                    {{item.cotacao.cliente_nome}}</td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo === 'subCliente')">
                                                    {{global_.formaterCurrency(item.cotacao.cotacao_compra |
                                                    currency:'BRL':true:'1.2-4') }} </td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo !== 'subCliente' && item.cotacao.receber === 0)">
                                                    {{global_.formaterCurrency(item.cotacao.cotacao_compra |
                                                    currency:'BRL':true:'1.2-4') }} </td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo !== 'subCliente' && item.cotacao.receber !== 0)">
                                                    {{global_.formaterCurrency(item.cotacao.cotacao_venda |
                                                    currency:'BRL':true:'1.2-4') }}</td>
                                                <td class="max-width">{{global_.formaterCurrency(item.cotacao.quantidade
                                                    | currency:'BRL':true:'1.2-2')}} </td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo === 'subCliente')">
                                                    {{global_.formaterCurrency(item.cotacao.pagar |
                                                    currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo !== 'subCliente' && item.cotacao.receber === 0)">
                                                    {{global_.formaterCurrency(item.cotacao.pagar |
                                                    currency:'BRL':true:'1.2-2')}}</td>
                                                <td class="max-width" *ngIf="currentUser && (currentUser.tipo !== 'subCliente' && item.cotacao.receber !== 0)">
                                                    {{global_.formaterCurrency(item.cotacao.receber |
                                                    currency:'BRL':true:'1.2-2')}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                    <p *ngIf="!dashboard.ultimas10_ordens" style="text-align: center; font-weight: 500; margin: 20px;"> <label class="form-label">Nenhum dado para exibir</label> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>