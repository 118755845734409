<div class="modal modal-custom" id="footerCenterBtnsModal" tabindex="-1" role="dialog" aria-labelledby="footerCenterBtnsModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" [ngClass]="{'modal-alert-s': retornoItem?.tipo === 1, 'modal-alert-e': retornoItem?.tipo === 2, 'modal-alert-l': retornoItem?.tipo === 4}">
                <h5 class="modal-title" id="footerCenterBtnsModalLabel">{{retornoItem?.title}}</h5>
                <button *ngIf="!(retornoItem?.tipo === 4)" type="button" class="close" [ngClass]="{'modal-alert-s': retornoItem?.tipo === 1, 'modal-alert-e': retornoItem?.tipo === 2, 'modal-alert-l': retornoItem?.tipo === 4}" aria-label="Close" (click)="closeModal()"> <span aria-hidden="true">&times;</span> </button>
            </div>
            <div class="modal-body">
                <div *ngIf="retornoItem?.tipo !== 4">
                    {{retornoItem?.msg}}
                </div>
                <div *ngIf="retornoItem?.tipo === 4">
                    <p>{{retornoItem?.msg}}</p>
                    <span>
                        <img
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </span>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button *ngIf="!(retornoItem?.tipo === 4)" type="button" class="btn btn-primary" (click)="closeModal()">Ok</button>
            </div>
        </div>
    </div>
</div>
