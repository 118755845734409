<div class="container-fluid g-0">
    <div class="row">
        <div class="col-12">
            <div class="header-painel">
                <div class="div-title">
                    <div class="mobile-view icon-sidebar sidebar_icon" (click)="toogleMenu2()"> <i class="ti-menu"></i> </div>
                    <div class="desktop-view icon-sidebar" (click)="toogleMenu()"> <img src="./assets/img/line_img.png" alt=""> </div> 
                    <div class="title" *ngIf="currentUser && currentUser.tipo"> <h4>Painel {{currentUser.tipo === 'usuarioAdmin' ? 'Administrativo' : (currentUser.tipo === 'clienteGestor' ? 'Gestor' : (currentUser.tipo === 'cliente' ? 'Cliente' : 'Trader'))}}</h4> </div>
                </div>
                <div class="div-profile">
                    <div class="profile-details">
                        <div class="mr_20"><img src="./assets/img/thumb.webp" alt="#"></div>
                        <div class="author-name">
                            <h4 class="f_s_15 f_w_500 mb-0">{{currentUser?.nome}}</h4>
                            <p class="f_s_12 f_w_400">{{currentUser?.email}}</p>
                        </div>
                    </div>
                    <div class="profile-info-inner">
                        <div class="profile-author-name">
                            <p>{{currentUser?.nome}}</p>
                            <h5>{{currentUser?.email}}</h5>
                        </div>
                        <div class="profile-info-details">
                            <a href="#" [routerLink]="['/profile']" *ngIf="currentUser && currentUser.tipo !== 'usuarioAdmin'">Perfil</a>
                            <a href="#" (click)="sair()">Sair</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
