<div class="main_content_iner overly_inner ">
    <div class="container-fluid p-0 ">
        <div class="row">
            <div class="col-12">
                <div class="page_title_box d-flex flex-wrap align-items-center justify-content-between">
                    <div class="page_title_left d-flex align-items-center">
                        <h3 class="mb-0">Contratos</h3>
                    </div>
                    <div class="page_title_right">
                        <ol class="breadcrumb page_bradcam mb-0">
                            <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
                            <li class="breadcrumb-item active">Contratos</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="white_card_body">
                    <div class="white_card mb_30 card_height_100">
                        <div class="white_card_header ">
                            <div class="box_header m-0">

                            </div>
                        </div>
                        <div class="white_card_body pt-0">
                            <div class="QA_section">
                                <div class="row">
                                    <div class="row align-items-center inline-inputs-center">
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="initialDate">Data inicial</label>
                                            <input class="form-control digits" id="initialDate" type="text" placeholder="Data inicial" [(ngModel)]="initialDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="finalDate">Data final</label>
                                            <input class="form-control digits" id="finalDate" type="text" placeholder="Data Final" [(ngModel)]="finalDate_" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="nomeContrato">Nome do contrato</label>
                                            <input class="form-control digits" id="nomeContrato" type="text" placeholder="Nome do contrato" [(ngModel)]="nomeContrato">
                                        </div>
                                        <div class="col-auto col-input-50">
                                            <label class="form-label" for="inputState">Status</label>
                                            <select id="inputState" class="form-select" [(ngModel)]="statusContrato" [ngModelOptions]="{standalone: true}">
                                                <option selected="" value="0">Todos</option>
                                                <option value="pendente">Pendente</option>
                                                <option value="concluido">Concluído</option>
                                            </select>
                                        </div>
                                        <div class="col-auto">
                                            <button type="button" (click)="pesquisar()" style="margin-top: 30px;" class="btn btn-primary mb-2">Buscar</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="QA_table mb_30 transaction-table">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Data</th>
                                                    <th scope="col">TRD</th>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">QTD ordens</th>
                                                    <th scope="col">Quantidade</th>
                                                    <th scope="col">Total (R$)</th>
                                                    <th scope="col">IOF (R$)</th>
                                                    <th scope="col">Pagar (R$)</th>
                                                    <th scope="col" style="text-align: right;">Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!loading">
                                                <tr *ngFor="let item of listItem.rows">
                                                    <td>{{item.data_registro?
                                                        _global.formateDateAndTime(item.data_registro) : '-'}}</td>
                                                    <td>{{item.trd? item.trd : '-'}}</td>
                                                    <td>{{item.nome? item.nome : '-'}}</td>
                                                    <td> <span class="badge" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='concluido', 'bg-warning': item.status=='pendente' }">{{item.status}}</span> </td>
                                                    <td>{{item.ordens? item.ordens.length: 0}}</td>
                                                    <td>{{formatarNumeroQtd(item.total_ordens.qtd_total_ordens)}}</td>
                                                    <td>{{_global.formaterCurrency(item.total_ordens.valor_total_ordens| currency:'BRL':true:'1.2-4') }}</td>
                                                    <td>{{_global.formaterCurrency(item.total_ordens.iof_total_ordens| currency:'BRL':true:'1.2-2') }}</td>
                                                    <td>{{_global.formaterCurrency(item.total_ordens.pagar_total_ordens | currency:'BRL':true:'1.2-2') }}</td>
                                                    <td style="text-align: right;">
                                                        <button type="button" class="btn btn-primary " (click)="verDetalhes2(item)" title="Visualizar detalhes">
                                                            <i class="ti-pencil-alt2"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" class="btn btn-info " (click)="modalExportar(item)" title="Exportar contrato">
                                                            <i class="ti-file"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" class="btn btn-danger " *ngIf="item.status != 'concluido'" (click)="deletarDado(item)" title="Deletar contrato">
                                                            <i class="ti-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="loading">
                                                <div class="loading-table">
                                                    <img
                                                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div class="card">
                                        <div class="card-body pag-custom">
                                            <div class="col" *ngIf="total_pages.length > 0">
                                                <div class="navigations-rows-shows">
                                                    <h2>Mostrando {{mostrando}} até
                                                        {{mostrando2}}
                                                        de {{paginas}} registros
                                                    </h2>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <nav aria-label="Page navigation">
                                                    <ul class="pagination" id="pagination_"></ul>
                                                </nav>
                                            </div>
                                            <div class="col">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal centerModal fade" id="detalhesContrato" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    Visualizar Contrato : {{contrato.nome}}
                </h5>
                <button type="button" class="btn-close" (click)="fecharModalDetalhes()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100 mb_30">
                    <div class="white_card_body">
                        <div class="input-group mb-3">
                            <div class="input-group-text">
                                <span class="" id="basic-addon1"><i class="ti-text"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Nome" aria-label="Nome" [(ngModel)]="contrato.nome" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon1">

                        </div>
                        <div class="input-group mb-3" *ngIf="contrato_.status != 'concluido'">
                            <div class="col-auto">
                                <label class="form-label" for="inputState">Status</label>
                                <select id="inputState" class="form-select" [(ngModel)]="contrato.status" [ngModelOptions]="{standalone: true}">
                                    <option value="pendente">Pendente</option>
                                    <option value="concluido">Concluído</option>
                                </select>
                            </div>
                        </div>
                        <div class="input-group mb-3" *ngIf="contrato_.status != 'concluido'">
                            <div class="col-auto" *ngIf="banksList">
                                <label class="form-label" for="inputState">Banco</label>
                                <select id="inputState" class="form-select" [(ngModel)]="contrato.banco_externo_id" [ngModelOptions]="{standalone: true}">
                                    <option value="0" selected>Selecione... </option>
                                    <option *ngFor="let item of banksList.rows" value="{{item.id}}">
                                        {{item.nickname}} - {{item.receiver_bank_aba}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <h5>Cotações selecionadas</h5>
                        </div>
                        <div class="QA_section">
                            <div class="QA_table mb-0 transaction-table">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Data</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Moeda</th>
                                                <th scope="col">Cliente</th>
                                                <th scope="col">Cotação (R$)</th>
                                                <th scope="col">Quantidade</th>
                                                <th scope="col">Validade</th>
                                                <th scope="col">Total (R$)</th>
                                                <th scope="col">IOF (R$)</th>
                                                <th scope="col">Pagar (R$)</th>
                                                <th scope="col">Ação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of contrato.ordens">
                                                <td>{{ item.data_registro? _global.formateDateAndTime(item.data_registro) : '-' }} </td>
                                                <td> <span class="badge" [ngClass]="{'bg-danger': item.status=='erro' || item.status=='erro_locked', 'bg-success': item.status=='aprovado','bg-warning': item.status=='pendente', 'bg-info': item.status=='saldo_insuficiente', 'bg-rose': item.status=='rejeitado' }">{{item.status}}</span> </td>
                                                <td> {{item.cotacao? item.cotacao.moeda : ''}} </td>
                                                <td> {{item.cotacao? item.cotacao.cliente_nome : ''}} </td>
                                                <td>{{_global.formaterCurrency(item.fxRate| currency:'BRL':true:'1.2-4') }} </td>
                                                <td>{{formatarNumeroQtd(item.cotacao.quantidade)}} </td>
                                                <td>{{item.validade}} </td>
                                                <td>{{_global.formaterCurrency(item.cotacao.valor_total | currency:'BRL':true:'1.2-2')}} </td>
                                                <td>{{_global.formaterCurrency(item.cotacao.iof | currency:'BRL':true:'1.2-2')}} </td>
                                                <td>{{_global.formaterCurrency(item.cotacao.pagar | currency:'BRL':true:'1.2-2')}} </td>
                                                <td>
                                                    <button type="button" class="btn btn-danger mb-3" (click)="deletarDadoCotacao(item)" title="Deletar contrato">
                                                        <i class="ti-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="5"></td>
                                                <td> {{formatarNumeroQtd(qtdTotalOrdens)}} </td>
                                                <td></td>
                                                <td> {{_global.formaterCurrency(valorTotalOrdens| currency:'BRL':true:'1.2-2')}} </td>
                                                <td> {{_global.formaterCurrency(iofTotalOrdens| currency:'BRL':true:'1.2-2')}} </td>
                                                <td> {{_global.formaterCurrency(pagarTotalOrdens| currency:'BRL':true:'1.2-2')}} </td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div class="form-group" *ngIf="contrato.ordens == null || contrato.ordens.length == 0" style="text-align: center;">
                                        <label class="form-label">Sem ordens para exibir</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loadingSave" class="btn btn-secondary" (click)="fecharModalDetalhes()">Fechar</button>
                <span *ngIf="contrato_.status != 'concluido'">
                    <img *ngIf="loadingSave"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    <button *ngIf="!loadingSave" type="button" class="btn btn-primary" (click)="saveContrato()">
                        Editar contrato
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>



<div class="modal centerModal fade" id="exportarContrato" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    Exportar contrato: {{contrato.nome}}
                </h5>
                <button type="button" class="btn-close" (click)="fecharModalExportar()">
                    <span aria-hidden="true"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="white_card card_height_100 mb_30">
                    <div class="center-div">
                        <div class="col-auto">
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="radio" name="contractType" id="contractType1" [(ngModel)]="contractType" value="1" [ngModelOptions]="{standalone: true}">
                                <label class="form-label form-check-label" for="autoSizingCheck">
                                    Padrão
                                </label>
                            </div>
                        </div>
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <div class="col-auto">
                            <div class="form-check mb-2">
                                <input class="form-check-input" type="radio" name="contractType" id="contractType2" [(ngModel)]="contractType" value="2" [ngModelOptions]="{standalone: true}">
                                <label class="form-label form-check-label" for="autoSizingCheck">
                                    Topazio
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="center-div">
                        <span>
                            <img *ngIf="loadingSave"
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            <button *ngIf="!loadingSave" type="button" class="btn btn-primary" (click)="exportContract()">
                                Exportar contrato
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!loadingSave" class="btn btn-secondary" (click)="fecharModalExportar()">Fechar</button>
            </div>
        </div>
    </div>
</div>